/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllowedChanges } from '../models/AllowedChanges';
import type { Available } from '../models/Available';
import type { ChangeJobPosition } from '../models/ChangeJobPosition';
import type { ChangeWorkTime } from '../models/ChangeWorkTime';
import type { CurrentParentalLeaveInfo } from '../models/CurrentParentalLeaveInfo';
import type { LeaveOrVolunteer } from '../models/LeaveOrVolunteer';
import type { MainEmployment } from '../models/MainEmployment';
import type { ReportParentalLeaveWithBenefits } from '../models/ReportParentalLeaveWithBenefits';
import type { Retired } from '../models/Retired';
import { request as __request } from '../core/request';

export class PaymentGroupService {

    /**
     * @returns MainEmployment Success
     * @throws ApiError
     */
    public static async getCurrentWorkInformation(): Promise<MainEmployment> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/subscription/working`,
            errors: {
                400: `Bad Request`,
                422: `Client Error`,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async setToWorking(
requestBody?: MainEmployment,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/subscription/working`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                422: `Client Error`,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async changeJobPosition(
requestBody?: ChangeJobPosition,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/subscription/working/position`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                422: `Client Error`,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async changeWorkTime(
requestBody?: ChangeWorkTime,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/subscription/working/hours`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async setToFreelanceIndipendent(
requestBody?: MainEmployment,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/subscription/working/freelance`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns LeaveOrVolunteer Success
     * @throws ApiError
     */
    public static async getCurrentLeaveInformation(): Promise<LeaveOrVolunteer> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/subscription/leave`,
            errors: {
                400: `Bad Request`,
                422: `Client Error`,
            },
        });
        return result.body;
    }

    /**
     * @returns CurrentParentalLeaveInfo Success
     * @throws ApiError
     */
    public static async getCurrentParentalLeaveInformation(): Promise<CurrentParentalLeaveInfo> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/subscription/parentalLeave`,
            errors: {
                400: `Bad Request`,
                422: `Client Error`,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static async setToParentalLeave(
requestBody?: ReportParentalLeaveWithBenefits,
): Promise<boolean> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/subscription/parentalleave`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                422: `Client Error`,
            },
        });
        return result.body;
    }

    /**
     * @returns Retired Success
     * @throws ApiError
     */
    public static async getCurrentRetirementInformation(): Promise<Retired> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/subscription/retirement`,
            errors: {
                400: `Bad Request`,
                422: `Client Error`,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static async setToRetired(
requestBody?: Retired,
): Promise<boolean> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/subscription/retirement`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                422: `Client Error`,
            },
        });
        return result.body;
    }

    /**
     * @returns Available Success
     * @throws ApiError
     */
    public static async getCurrentAvailabilityInformation(): Promise<Available> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/subscription/leaveoravailable`,
            errors: {
                400: `Bad Request`,
                422: `Client Error`,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static async setToLeaveOrAvailable(
requestBody?: Available,
): Promise<boolean> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/subscription/leaveoravailable`,
            body: requestBody,
            errors: {
                422: `Client Error`,
            },
        });
        return result.body;
    }

    /**
     * @returns AllowedChanges Success
     * @throws ApiError
     */
    public static async getAllowedChanges(): Promise<AllowedChanges> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/subscription/allowed_changes`,
        });
        return result.body;
    }

}