/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect } from "react";
import style from "./member-card.module.scss";
import * as api from "../../../api/services/AccountService";
import { format } from "date-fns";
import { AccountService, ApiError, MemberData } from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";
import { Link } from "../../../models/umbracoElement";
import LabelValue from "../../_GenericElements/LabelValue/label-value";
import globalStyle from "../../../global.scss";
import { DanishDateFns } from "../../../helpers/DanishDateFns";
import { da } from "date-fns/locale";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";

export interface IMemberCardProps {
    // data: string;
}

export const MemberCard = (props: IMemberCardProps) => {
    // const umbracoSettings: MemberCardData = JSON.parse<MemberCardData>(props.data);

    const [loading, setLoading] = React.useState(true);
    const [memberdata, setMemberdata] = React.useState<MemberData>();
    const [printed, setPrinted] = React.useState<Date>();

    const GetData = async () => {
        let data = await AccountService.getMemberData();
        setMemberdata(data);
        setPrinted(new Date());
        setLoading(false);
    };    

    React.useEffect(() => {
        void GetData();
    }, []);

    const onPrintBtnClick = () => {
        window.print();
    };

    return loading ?
        <Loader /> :
        <div className={style.membercardcanvas}>
            <div className={style.membercard}>
                <div className={style.header}>
                    <h2>Medlemskort</h2>
                    <div className={style.dmLogo}></div>
                </div>
                <div className={style.canvas}>
                    <div className={style.memberLogo} title={"Medlems logo"}></div>
                    <div className={style.memberData}>
                        <h2>{memberdata?.fullName}</h2>
                        <div className={style.row}>
                            <span className={style.label}>Medlemsnr:</span>
                            <span className={style.text}>{memberdata?.memberId}</span>
                        </div>
                        <div className={style.row}>
                            <span className={style.label}>Printed:</span>
                            <span className={style.text}>{printed ? DanishDateFns.format(printed, "dd. MMMM yyyy") : ""}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.buttonRow}>
                <StandardButton showArrow={true} onClick={onPrintBtnClick}>Print medlemskort</StandardButton>
            </div>
        </div>;
};
