import { DMUser } from "../../api/models/DMUser";
import { isMobile } from "../../constants/common-constants";
import { HeaderLogo } from "./HeaderLogo";
import { Logout } from "./Logout";
import { MegaMenu } from "./MegaMenu";
import { MobileMenu } from "./MobileMenu";
import { ToHomePage } from "./ToHomePage";

export interface MenuProps {
    org: DMUser.organisation | null
    includeMenu: boolean;
    data: string;
}
export const Menu = (props: MenuProps) => {
    // device detection
    if (isMobile()){
        return <div>
            <MobileMenu organization={props.org} data = {props.data}></MobileMenu>
        </div>;
        
    } else {
        return <div>
            <div className="dm-service-menu">
                <Logout  data=""></Logout>
                <ToHomePage organization={props.org}></ToHomePage>
            </div>
            <HeaderLogo src="/MitDMLogo.png"></HeaderLogo>
            <MegaMenu data = {props.data} ></MegaMenu>
        </div>;
    }

};