/* eslint-disable */
import React, { useRef } from "react";
import { GeckoConfiguration, GeckoService } from "../../api";
import Loader from "../_GenericElements/Loader/loader";
import { GeckoCalenderElement } from "../../models/umbracoElement";
import { consultingBooking } from "../../constants/common-constants";

export interface IGeckoCalender {
    data: string;
}

export const GeckoCalender = (props: IGeckoCalender) => {

    const contentRef = useRef<HTMLDivElement>(null);
    const umbracoSettings: GeckoCalenderElement = JSON.parse(props.data ?? "{}");
    const [errorMsg, setErrorMsg] = React.useState<string>(""); // can be moved to provider when we've got unified error component
    const [calenderHtml, setCalenderHtml] = React.useState<string>("");
    const [state, setState] = React.useState<string>("");
    const [config, setConfig] = React.useState<GeckoConfiguration>();

    const getConfig = async () => {
        try {
            const calenderConfig: GeckoConfiguration = await GeckoService.getGeckoConfiguration(umbracoSettings.calenderType === consultingBooking);
            setConfig(calenderConfig);
            setCalenderHtml(`https://app.geckobooking.dk/site/iframe.js?icCode=${calenderConfig.code!}&token=${calenderConfig.token!}&firmaId=${calenderConfig.customerGroupId}`);
            setErrorMsg("");
        } catch (e) {
            setErrorMsg("Der er sket en fejl. Kalendar kan ikke vises.");
            console.error(e);
        } finally {
        }
    };

    React.useEffect(() => {
        (async () => { await getConfig(); })();
    }, []);

    React.useEffect(() => {
        if (calenderHtml) {
            let script: any = document.querySelector(`script[src="${calenderHtml}"]`);
            let div: any = document.querySelector(`div[id="gecko_${config!.code!}"]`);
            const handleScript = (e: any) => {
                setState(e.type === "load" ? "ready" : "error");
            };
            if (!div) {
                div = document.createElement("div");
            }
            div.id = `gecko_${config!.code!}`;
            contentRef?.current?.appendChild(div);
            if (!script) {
                script = document.createElement("script");
                script.type = "application/javascript";
                script.src = calenderHtml;
                script.async = true;
                document.body.appendChild(script);
                script.addEventListener("load", handleScript);
                script.addEventListener("error", handleScript);
            }

            script.addEventListener("load", handleScript);
            script.addEventListener("error", handleScript);

            return () => {
                script.removeEventListener("load", handleScript);
                script.removeEventListener("error", handleScript);
            };

        }
    }, [calenderHtml]);


    return (
        <div ref={contentRef}>
            {state === "load" && <Loader />}
            {state === "ready" && <div></div>}
            {errorMsg && <div>{errorMsg}</div>}
        </div>)
};