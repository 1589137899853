
import { HeaderLogo } from "./HeaderLogo";
import style from "./MobileMenu.module.scss";
import { AppBar, Box, Link, LinkProps, Menu, MenuItem, Paper, Stack, Toolbar, Typography } from "@mui/material";
import { BreadCrumbElement, NavigationElement } from "../../models/umbracoElement";
import { DMTheme } from "../../helpers/DMTheme";
import React, { useState } from "react";
import { ToHomePage } from "./ToHomePage";
import { Logout } from "./Logout";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";

import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { DMUser } from "../../api/models/DMUser";
export interface MobileMenuProps {
    data: string;
    organization: DMUser.organisation | null
}

export interface DropdownProps {
    element: BreadCrumbElement
    index: number
}



const RemoveLinkStyling = { color: DMTheme.palette.info.contrastText, textDecoration: "none" };

export const UnstyledLink: React.FC<LinkProps> = (props) => {
    return <Link {...props} sx={RemoveLinkStyling}>
        {props.children}
    </Link>;
};


const textElement = (element: BreadCrumbElement, index: number) => {
    return <ListItemButton key={`menuItem-${index}`}>
        <ListItemText primary={<Typography
            variant="navigation"
            color={DMTheme.palette.info.contrastText}
            component="div"><UnstyledLink style={{ width: "300px", height: "100%" }} href={element.Url}>{element.Title}</UnstyledLink></Typography>} />
    </ListItemButton>;

};



const DropDownMenuMobile = (props: DropdownProps) => {

    const [subMenuOpen, setSubMenuOpen] = React.useState<boolean>(false);
    const { element, index } = props;

    return <div>
        <ListItemButton onClick={() => { setSubMenuOpen(!subMenuOpen); }}>
            <ListItemText primary={<Typography
                variant="navigation"
                color={DMTheme.palette.info.contrastText}
                component="div"><UnstyledLink style={{ width: "300px", height: "100%" }} href={element.Url}>{element.Title}</UnstyledLink></Typography>} />
            {subMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {props.element.Children.map((c, i) =>
                    <ListItemButton sx={{ pl: 4, marginLeft: "10px" }} key={`dropdownMenuItem-${i}`}>
                        <ListItemText primary={<Typography
                            variant="navigation"
                            color={DMTheme.palette.info.contrastText}
                            component="div"><UnstyledLink style={{ width: "300px", height: "100%" }} href={c.Url}>{c.Title}</UnstyledLink> </Typography>} />
                    </ListItemButton>)}
            </List>
        </Collapse>
    </div>;

};
export const MobileMenu = (props: MobileMenuProps) => {
    const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
    const menuItems: BreadCrumbElement[] = JSON.parse(props.data ?? "[]");
    return <div>
        <Box sx={{ flexGrow: 1, bgcolor: "#fff" }}>
            <AppBar sx={{ bgcolor: "#fff" }} position="static">
                <Toolbar style={{height: "80px"}}>
                    <HeaderLogo src="/MitDMLogo.png"></HeaderLogo>

                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>

                    </Typography> 
                    <button className={style.mobileButton} onClick={() => setMenuOpen(!menuOpen)}>{menuOpen ? <CloseIcon sx = {{fontSize: 40 }}/> : <MenuIcon sx = {{fontSize: 40 }} /> }</button> 
                </Toolbar>
            </AppBar>
        </Box>

        {menuOpen ? <div style={{ width: "100%" }}>
            <Stack direction="row" alignItems="center" justifyContent="center">
                <Logout data=""></Logout>
                <ToHomePage organization={props.organization}></ToHomePage>
            </Stack>
            <List sx={{ width: "100%", bgcolor: "#fff" }} component="nav">
                {menuItems.map((m, i) => {
                    return m.Children.length > 0 ?
                        <DropDownMenuMobile key={`menuItem-${i}`} element={m} index={i}></DropDownMenuMobile> :
                        textElement(m, i);
                })}
            </List>
        </div> : <div></div>}
    </div>;

};