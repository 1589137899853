import { useState } from "react";
import { RepresentativeElement } from "../../../models/umbracoElement";
import { IncidentList } from "../../Incidents/incident-list";
import { RepresentativeDetail } from "./Details/representative-detail";
import { SubmissionDetail } from "./Details/submission-detail";
import { TerminatedDetail } from "./Details/terminated-detail";
enum RepresentativeOverviewType {
    Representative = "aktiv",
    Submission = "anmeldelse",
    Terminated = "afsluttet"
}

type RepresentativeOverviewProps = {
    type: RepresentativeOverviewType
    id: string,
    contactid: string,
    data: string
}

export const RepresentativeOverview = (props: RepresentativeOverviewProps) => {
    const [umbracoSettings] = useState<RepresentativeElement>(JSON.parse(props.data || "{}"));
    const goBack = () => {
        if (umbracoSettings.backToLink?.url) {
            window.location.href = umbracoSettings.backToLink.url;
        }
    };
    switch (props.type) {
        case RepresentativeOverviewType.Representative:
            return <RepresentativeDetail goBack={umbracoSettings?.backToLink?.url ? goBack : undefined} contactid={props.contactid} id={props.id}/> ;
        case RepresentativeOverviewType.Submission:
            return <SubmissionDetail id={props.id} goBack={umbracoSettings?.backToLink?.url ? goBack : undefined} />;
        case RepresentativeOverviewType.Terminated:
            return <TerminatedDetail id={props.id} goBack={umbracoSettings?.backToLink?.url ? goBack : undefined} />;
        default:
            return null;
    }
   
};
