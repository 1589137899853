/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeckoConfiguration } from '../models/GeckoConfiguration';
import { request as __request } from '../core/request';

export class GeckoService {

    /**
     * @param isConsulting 
     * @returns GeckoConfiguration Success
     * @throws ApiError
     */
    public static async getGeckoConfiguration(
isConsulting: boolean = false,
): Promise<GeckoConfiguration> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/gecko`,
            query: {
                'isConsulting': isConsulting,
            },
        });
        return result.body;
    }

}