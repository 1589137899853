/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { RepresentativeService, Representative } from "../../../../api";
import LabelValue from "../../../_GenericElements/LabelValue/label-value";
import { hideOrganizationType } from "../../../../constants/common-constants";
import styles from "../representative-overview.module.scss";
import Loader from "../../../_GenericElements/Loader/loader";
import { MessageDiv, MessageType } from "../../../_GenericElements/MessageDiv/MessageDiv";
import { StandardButton } from "../../../_GenericElements/StandardButton/StandardButton";
type TerminatedDetailProps = {
    id: string
    goBack?:()=>void
}

export const RepresentativeEditFormLabels: Record<keyof RepresentativeEditFormValues, string> = {
    "phone": "Telefonnummer",
    "mobile": "Mobilnummer",
    "emailForarea": "Email ansættelsessted – HR eller leder",
    "email": "Arbejdsemail",
    "coverageArea": "Dækningsområde",
    "endDate": "Slut dato",
};
type RepresentativeEditFormValues = NonNullable<Pick<Representative, "phone" | "mobile" | "email" | "emailForarea" | "coverageArea"> & { /* job?:CrmEntity,*/endDate?: Date | null }>


export const TerminatedDetail = (props: TerminatedDetailProps) => {
    const [data, setData] = useState<Representative>();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const res = await RepresentativeService.getTerminatedRepresentative(props.id);
                if (!res) {setErrorMessage("Der findes ikke nogen data.");}
                setData(res);
            } catch (err) {
                setErrorMessage("Der er sket en fejl. Data kunne ikke hentes.");
                console.error(err);

            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <>{loading && <Loader />}
            {data &&
                <div> <h1>{data.type || ""}</h1>
                    <LabelValue trClassName={styles.labeltr} label="Hverv" value={data.type || ""} />
                    {data.vipTap? <LabelValue trClassName={styles.labeltr} label="VIP/TAP" value={data.vipTap == "VIPogTAP" ? "VIP og TAP" : data.vipTap} />: <></>}
                    <LabelValue trClassName={styles.labeltr} label="Dækningsområde" value={data.coverageArea || ""} />
                    {<LabelValue trClassName={styles.labeltr} label="Netværk" value={data.networks?.map(x => x.name).join(", ") || ""} />}
                    <LabelValue trClassName={styles.labeltr} label="Samtykke" value={data.consent == true ? "Ja" : "Nej"} />
                    <LabelValue trClassName={styles.labeltr} label="Tidligere TR" value={data.previousRepresentative || ""} />
                    <LabelValue trClassName={styles.labeltr} label="Start dato" value={data.startDate ? format(new Date(data.startDate), "dd/MM/yyyy") : ""} />
                    <LabelValue trClassName={styles.labeltr} label="Slut dato" value={data.endDate ? format(new Date(data.endDate), "dd/MM/yyyy") : ""} />
                    <LabelValue trClassName={styles.labeltr} label="Anmeldelsesområde" value={data.areasText} />
                    {data.type !== hideOrganizationType &&
                        <LabelValue trClassName={styles.labeltr} label="AC organisationer" value={data.acOrganizationsText} />
                    }
                    <LabelValue trClassName={styles.labeltr} label="Telefon" value={data.phone || ""} />
                    <LabelValue trClassName={styles.labeltr} label="Mobilnummer" value={data.mobile || ""} />
                    <LabelValue trClassName={styles.labeltr} label="Email" value={data.email || ""} />
                    <LabelValue trClassName={styles.labeltr} label="Email ansættelsessted" value={data.emailForarea || ""} />
                    <LabelValue trClassName={styles.labeltr} label="Samtykke" value={data.consent == true ? "Ja" : "Nej"} />  
                    <MessageDiv type={MessageType.error} msg={errorMessage} />
                    {props.goBack && <StandardButton showArrow={false} onClick={props.goBack} >
                            Tilbage
                    </StandardButton>}

                </div>
            }</>
    );

};