import { EndMembership } from "../../../api";
import * as yup from "yup";
import { Schema } from "yup";

export type EndMembershipSchemaType = Omit<EndMembership, "date"> & {date:Date}
// eslint-disable-next-line max-len
const uuidRegex = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/;

export const CRMEntitySchema = yup.object({
    id: yup.string()
        .defined("Venligst udfyld grunden til din udmeldelse")
        .matches(uuidRegex),
    primaryName: yup.string().defined(),
});

export const EndMembershipFormSchema: Schema<EndMembershipSchemaType> = yup.object({
    reason: CRMEntitySchema,
    date: yup.date()
        .defined()
        .required(),
});