import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { CTACardElement } from "../../models/umbracoElement";
import { ColoredRightArrowIcon } from "../_GenericElements/Icons/Icons";
import { isMobile } from "../../helpers/MobileHelper";
import { ContactsService } from "../../api";
import { useEffect, useState } from "react";

interface CTACardProps extends IconButtonProps {
  data: string;
  disabled?: boolean;
}

export function CTACard(props: CTACardProps) {
  const elm: CTACardElement = JSON.parse(props.data);

  const redirect = () => {
    if (elm.callToActionLink?.target == "_blank") {
      window.open(
        elm.callToActionLink.url,
        elm.callToActionLink?.target
      );
    } else {
      window.location.href = elm.callToActionLink.url;
    }

  };

  let cardActionArea = <CardActionArea onClick={redirect}>
    <CardHeader
      title={<>{elm.title} {ColoredRightArrowIcon("#222")}</>}
    />
  </CardActionArea>;
  if (props.disabled) {
    cardActionArea = <CardActionArea disabled={props.disabled} disableRipple={props.disabled} disableTouchRipple={props.disabled}>
      <CardHeader
        title={<>{elm.title}</>}
      />
    </CardActionArea>;
  }
  return (
    <Card sx={{ marginBottom: isMobile ? "20px" : 0, maxWidth: isMobile ? "760px" : "345px" }}>
      {elm.image ? <CardMedia
        component="img"
        height={elm.image.umbracoHeight}
        image={elm.image._url}
        alt={elm.image.name}
      /> : <div></div>}

      {cardActionArea}
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {elm.body}
        </Typography>
      </CardContent>

    </Card>
  );
}