import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import { SwitchProps, fieldToSwitch, Switch} from "formik-mui";
import { DMTheme } from "../../../helpers/DMTheme";
// /From: https://mui.com/components/switches/
export const IOSSwitch = styled((props: SwitchProps) => (
    <Stack direction="row" spacing={1} alignItems="center">
        <Typography>Nej</Typography>
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple
            {...props} />
        <Typography>Ja</Typography>
    </Stack>

))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: DMTheme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                DMTheme.palette.mode === "light" ?
                    DMTheme.palette.grey[100] :
                    DMTheme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: DMTheme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: DMTheme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: DMTheme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));