/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MemberData } from '../models/MemberData';
import { request as __request } from '../core/request';

export class AccountService {

    /**
     * @returns MemberData Success
     * @throws ApiError
     */
    public static async getMemberData(): Promise<MemberData> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/accounts/me`,
        });
        return result.body;
    }

}