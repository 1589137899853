import * as yup from "yup";
import { Schema } from "yup";
import { ContactUpdate } from "../../../api";

// eslint-disable-next-line max-len
const uuidRegex = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/;

export const CRMEntitySchema = yup.object()
    .shape({
        id: yup.string().matches(uuidRegex),
        primaryName: yup.string(),
    })
    .defined();

const baseSchema={ firstName: yup.string()
    .required("Mangler fornavn")
    .min(1),
middleName: yup.string()
    .min(1),
lastName: yup.string()
    .required("Mangler efternavn")
    .min(1),
email: yup.string()
    .email("Invalid email")
    .required("Email er obligatorisk"),
mobilePhone: yup.string(),
primaryPhone: yup.string()
    .required("Telefonnummer er obligatorisk"),
addressLine1: yup.string()
    .required("Mangler adresse")
    .min(1),
addressLine2: yup.string(),
postalCode: yup.string()
    .required("Mangler postnummer")
    .min(1),
city: yup.string()
    .required("Mangler by")
    .min(1),
country:  yup.object({
    id: yup.string()
        .defined("Land skal udfyldes.")
        .required("Land skal udfyldes."),
    primaryName:yup.string().defined("Land skal udfyldes.")
        .required("Land skal udfyldes."),
}).defined("Land skal udfyldes.")
    .required("Land skal udfyldes."),
language: yup.mixed<ContactUpdate.language>().oneOf(Object.values(ContactUpdate.language))};

const danishSchema={...baseSchema, 
    postalCode: yup.string()
        .required("Mangler postnummer")    
        .min(1)
        .matches(/\b\d{4}\b/, "Ugyldigt postnummer")};    
export const getMasterDataUpdateSchema= (isDanish:boolean ): Schema<ContactUpdate>=> yup.object(isDanish?danishSchema:baseSchema);