import { Button } from "@mui/material";
import { TopButtonStyling } from "./Logout";
import { DMColors } from "../../helpers/DMTheme";
import { isMobile } from "../../constants/common-constants";
import style from "./MobileMenu.module.scss";
import { DMUser } from "../../api";

export interface ToHomePageProps {
    organization: DMUser.organisation | null
}

interface OrganizationHomePageConfig {
    url: string;
    name: string;
}
const OrganizationHomePageMapping : Record<DMUser.organisation,OrganizationHomePageConfig> = {
    DM: {url:"https://dm.dk",name:"DM.dk"},
    JA: {url:"https://www.ja.dk/",name:"JA.dk"},
    DSL: {url:"https://www.dslfagforening.dk/",name:"DSL"},
};

export const ToHomePage = (props: ToHomePageProps) => {
    // Maybe this background color should be a "dm color"/theme color
    const homePageMapping = OrganizationHomePageMapping[props.organization ?? DMUser.organisation.DM];
    const styleProp = { ...TopButtonStyling, backgroundColor: DMColors.dm_pink, width: isMobile() ? "50%" : 154 };
    return <Button className={style.topButton}
        href={homePageMapping.url}
        sx={styleProp}>
        {homePageMapping.name}
    </Button>;
};