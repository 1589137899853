import React, { useEffect, useState } from "react";
import globalStyles from "../../../global.scss";
import { ApiError, ContactsService, CurrentParentalLeaveInfo, MembershipService, ParentalLeave, PaymentGroupService, ReportParentalLeaveWithBenefits } from "../../../api";
import { Form, Formik } from "formik";
import formStyles from "../../_GenericElements/_GenericFormElements/form.module.scss";

import { ParentalLeaveFormValues, parentalLeaveSchema } from "./ParentalLeaveSchema";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import { ApiErrorBody } from "../../../models/apiError";
import { ParentalLeaveFormElement } from "../../../models/umbracoElement";
import { Stack } from "@mui/material";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import { ConfirmMessageBox } from "../ConfirmMessageBox/ConfirmMessageBox";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { profilPageLink } from "../../../constants/common-constants";
import { formatDateDDMMYY } from "../../../helpers/Utils";

export type IChangeToParentalLeaveFormProps = {
    data: string
}

export type IChangeToParentalLeaveFormState = {

}

function ParentalFormLabels(elementConfig:ParentalLeaveFormElement) : Record<keyof ParentalLeaveFormValues, string> {
    return {
        "benefitStart": elementConfig.dagpengeStartLabel || "Dagpenge starter",
        "benefitEnd": elementConfig.dagpengeSlutLabel || "Dagpenge slutter",
        "parentalLeaveEnd": elementConfig.barselSlutLabel || "Barsel slutter",
        "parentalLeaveStart": elementConfig.barselStartLabel || "Barsel starter",
        "isPreviousKIMember": "",
    };
}

export const ChangeToParentalLeaveForm = (props: IChangeToParentalLeaveFormProps) => {
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [readonly, setReadOnly] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [agreementStartDate, setAgreementStartDate] = useState<Date>();
    let parsedData: ParentalLeaveFormElement = JSON.parse(props.data);

    const [defaultValues, setDefaultValues] = useState<ParentalLeaveFormValues>({
        parentalLeaveStart: null,
        parentalLeaveEnd: null,
        benefitStart: null,
        benefitEnd: null,
        isPreviousKIMember: false,
    });
    const [KIText, setKIText] = useState("Medlemskabsændringer før d. 1/1-2024, skal ske ved at kontakte Medlemsservice på tlf. 38156600 eller via mail service@dm.dk.");
    const [isLoading, setIsLoading] = useState(false);

    const changePaymentGroup = async (formData: ParentalLeaveFormValues) => {
        let stringifiedFormData: ReportParentalLeaveWithBenefits = {
            ...formData,
            parentalLeave : {
                parentalLeaveStart: formData.parentalLeaveStart?.toISOString(),
                parentalLeaveEnd: formData.parentalLeaveEnd?.toISOString(),
            },
            benefits: {
                benefitStart: formData.benefitStart?.toISOString(),
                benefitEnd: formData.benefitEnd?.toISOString(),
            },
        };

        try {
            await PaymentGroupService.setToParentalLeave(stringifiedFormData);
            setShowConfirm(true);
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 422) {
                    console.error("Brugeren var ikke i arbejde");
                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg(errorInfo.title);
                }
            }
        }
    };

    const GetCurrentInformation = async () => {
        setIsLoading(true);
        let agreementInformation = await MembershipService.getMembership();
        agreementInformation.startDate && setAgreementStartDate(new Date(agreementInformation.startDate));

        let currentInformation: CurrentParentalLeaveInfo | undefined = undefined;
        try {
            currentInformation = await PaymentGroupService.getCurrentParentalLeaveInformation();

        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 422) {
                    console.log("Brugeren var ikke på barsel");
                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg(errorInfo.title);
                    setReadOnly(true);
                }
            }
        } finally {
            const isPreviousKIMember = await getIsPreviousKIMember();

            let newDefaults: ParentalLeaveFormValues = currentInformation != undefined ?
                {
                    parentalLeaveStart: currentInformation.parentalLeaveStart ? new Date(currentInformation.parentalLeaveStart) : null,
                    parentalLeaveEnd: currentInformation.parentalLeaveEnd ? new Date(currentInformation.parentalLeaveEnd) : null,
                    benefitStart: currentInformation.benefitStart ? new Date(currentInformation.benefitStart) : null,
                    benefitEnd : null,
                    isPreviousKIMember: isPreviousKIMember,
                } : {
                    ...defaultValues,
                    isPreviousKIMember: isPreviousKIMember,
                };
            setDefaultValues(newDefaults);
            setIsLoading(false);
        }
    };

    const getIsPreviousKIMember = async () => {
        try {
            return await ContactsService.getIsPreviousKiMember();
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    const onCloseConfirmMessage=()=>{
        setShowConfirm(false);
        window.location.href=profilPageLink;
    };

    useEffect(() => {
        void GetCurrentInformation();
    }, []);

    const FormElements = new GenericFormElements(ParentalFormLabels(parsedData));
    let form = <Formik
        initialValues={defaultValues}
        validationSchema={parentalLeaveSchema}
        onSubmit={async (values, { setSubmitting }) => {
            await changePaymentGroup(values);
            setSubmitting(false);
        }}
        enableReinitialize={true}
        validate={(values) => {
          type errors = {
            benefitStart: undefined | string;
            benefitEnd: undefined | string;
            parentalLeaveStart: undefined | string;
        };


          const errorList: errors = {
              benefitStart: undefined,
              benefitEnd: undefined,
              parentalLeaveStart: undefined,
          };


          // Have to overwrite the check in schemes since chaining didn't work as i thought in yup.
          if (defaultValues.isPreviousKIMember && values.benefitStart != null && values.benefitStart < new Date(2024, 0, 1, 0, 0 ,0 ,0)) {
              errorList.benefitStart =  KIText;
          }

          if (defaultValues.isPreviousKIMember && values.benefitEnd != null && values.benefitEnd < new Date(2024, 0, 1, 0, 0 ,0 ,0)) {
              errorList.benefitEnd =  KIText;
          }

          // Had to set hours to 0, 0, 0, 0 since it failed the check if date was the same.
          if (values.parentalLeaveStart != null && values.benefitStart != null && values.parentalLeaveStart > values.benefitStart) {
              let parentLeaveStart = values.parentalLeaveStart;
              parentLeaveStart.setHours(0, 0, 0, 0);

              let benefitStart = values.benefitStart;
              benefitStart.setHours(0, 0, 0, 0);

              if (parentLeaveStart > benefitStart) {
                  errorList.benefitStart = "Dagpenge start dato skal være efter barsels start dato";
              }
          }

          if (agreementStartDate && values.parentalLeaveStart && values.parentalLeaveStart < agreementStartDate) {
              let dateFormatted = formatDateDDMMYY(agreementStartDate);
              errorList.parentalLeaveStart = `Du kan registrere ændringer fra den dato, du blev medlem af DM: ${dateFormatted}`;
          }

          // Had to set hours to 0, 0, 0, 0 since it failed the check if date was the same.
          if (values.benefitStart != null && values.benefitEnd != null && values.benefitStart > values.benefitEnd) {
              let benefitStart = values.benefitStart;
              benefitStart.setHours(0, 0, 0, 0);

              let benefitEnd = values.benefitEnd;
              benefitEnd.setHours(0, 0, 0, 0);
              if (benefitStart > benefitEnd) {
                  errorList.benefitEnd = "Slut dato skal være efter start dato";
              }
          }

          return errorList;

        }}
    >
        {({ handleSubmit,
            isSubmitting,
            values,
        }) => (
            <Form className={formStyles.dmForm} onSubmit={handleSubmit} >

                <FormElements.BasicDateInput formValueName="parentalLeaveStart"/>
                <FormElements.BasicDateInput formValueName="parentalLeaveEnd"/>
                <FormElements.BasicDateInput formValueName="benefitStart"/>
                <FormElements.BasicDateInput formValueName="benefitEnd"/>
                <FormElements.SubmitButton type="submit" loading={isSubmitting} disabled={readonly||isSubmitting || isLoading} />
                {values.benefitStart && showConfirm &&
                <ConfirmMessageBox fromDate={values.benefitStart} onClose={onCloseConfirmMessage} />}
                <MessageDiv type={MessageType.error} msg={errorMsg} />

            </Form>
        )
        }
    </Formik>;
    return <Stack direction="column" justifyItems="flex-start" alignItems="grow">
        <h2>{parsedData.text}</h2>
        {parsedData.contentText && SantizedRichHtml(parsedData.contentText)}
        {form}
    </Stack>;


};