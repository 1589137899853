import { useEffect, useMemo, useRef, useState } from "react";
import { educationCreateSchema, educationFormValues, educationFormLabels } from "../UpdateEducation/EducationSchema";
import { ApiError, ContactRead, ContactsService, MainEducationUpdate, MembershipService } from "../../../api";
import { Formik, Form, FormikProps } from "formik";
import formStyles from "../../_GenericElements/_GenericFormElements/form.module.scss";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import { Link } from "@mui/material";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import style from "../../_GenericElements/_GenericFormElements/form.module.scss";
import Loader from "../../_GenericElements/Loader/loader";


export const EducationViewForm = (props: { organisation?: string }) => {
  const defaultFormValues: educationFormValues = {
    id: undefined,
    graduateDate: new Date(),
    expectedGraduateDate: new Date(),
    type: { id: "", primaryName: "" },
    course: { id: "", primaryName: "" },
    placeOfStudy: { id: "", primaryName: "" },
    finishedEducation: false,
    disced: { id: "", primaryName: "" },
    levelOfEducation: MainEducationUpdate.levelOfEducation.BACHELOR,
    educationField: { id: "", primaryName: "" },
    isStudent: undefined,
    isPreviousKIMember: undefined,
    furtherEducation: "",
  };

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [currentEducation, setCurrentEducation] = useState<educationFormValues>(defaultFormValues);
  const [isLoadingCurrentEducation, setIsLoadingCurrentEducation] = useState(true);
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [showFurtherEducation, setShowFurtherEducation] = useState<boolean>(false);
  const formikRef = useRef<FormikProps<educationFormValues>>(null);
  const FormElements = new GenericFormElements<educationFormValues>(educationFormLabels);

  const checkAndDisplayFurtherEducationOption = async () => {
      var agreement = await MembershipService.getMembership();
      // These price groups are studerende, studerende gratis, nyuddannet ledig og nyuddannet i arbejde
      // If member have this membership they shouldn't be able to see further education
      // This check is also in EducationForm
      if(agreement.priceGroup !== "18" &&
         agreement.priceGroup !== "19" &&
         agreement.priceGroup !== "30" &&
         agreement.priceGroup !== "07") {
        setShowFurtherEducation(true);
      }
  }

  const getCurrentEducation = async () => {
    let education: MainEducationUpdate | undefined = undefined;

    try {
      education = await ContactsService.getMainEducation();
    } catch (err) {
      if (err instanceof ApiError) {
        if (err.status == 404) {
          setErrorMsg("Primær uddannelse mangler");
        }
      }
    } finally {
      // This check can be deleted when we get to the date 1/1-2024
      const isPreviousKIMember = await checkIfEducationUpdatesIsAllowed();

      const formval = education?.id != undefined ? {
        ...defaultFormValues,
        id: education.id,
        graduateDate: education.graduateDate ? new Date(education.graduateDate) : new Date(),
        expectedGraduateDate: education.expectedGraduateDate ? new Date(education.expectedGraduateDate) : undefined,
        type: education.type,
        course: education.course,
        placeOfStudy: education.placeOfStudy,
        finishedEducation: education.graduateDate ? new Date(education.graduateDate) <= new Date() : false,
        disced: education.disced,
        levelOfEducation: education.levelOfEducation,
        educationField: education.educationField,
        isStudent: education.isStudent,
        isPreviousKIMember: isPreviousKIMember,
        furtherEducation: education.furtherEducation
      } : { ...defaultFormValues, isStudent: education?.isStudent, isPreviousKIMember: isPreviousKIMember };

      education?.id == undefined && setErrorMsg("Primær uddannelse mangler");

      // checkIfEducationUpdatesIsAllowed(isPreviousKIMember);
      setCurrentEducation(formval);
      setIsLoadingCurrentEducation(false);
    }
  };

  // This function can be deleted as well as the usestate with AllowUpdate, when we have passed the date 1/1-2024
  // Since this is only a temp limitation.
  const checkIfEducationUpdatesIsAllowed = async () => {

    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    let compareDate = new Date(2024, 0, 1, 0, 0, 0, 0);

    // Previous KI member is not allowed to make changes before after 1/1/2024
    if (currentDate < compareDate) {
      const isPreviousKIMember = await ContactsService.getIsPreviousKiMember();
      setAllowUpdate(!isPreviousKIMember);
      return isPreviousKIMember;

    }
    setAllowUpdate(true);
    return false;
  };



  useEffect(() => {
    void getCurrentEducation();
    void checkAndDisplayFurtherEducationOption();
  }, []);

  const navigateToUpdateEducation = () => {
    window.location.href = "/profil-og-medlemskab/min-profil/opdater-uddannelse";
  };

  const educationForm = useMemo(() => {
    return props.organisation && <Formik
      innerRef={formikRef}
      initialValues={currentEducation}
      enableReinitialize={true}
      onSubmit={() => { }}
    >
      {({
        values,
        handleSubmit,
      }) =>

        <Form className={formStyles.dmForm} onSubmit={handleSubmit}>
          {currentEducation.id && <> {currentEducation.isStudent ?
            <FormElements.BasicDateInput disabled={true} formValueName="expectedGraduateDate" /> :
            <FormElements.BasicDateInput disabled={true} formValueName="graduateDate" />
          }

            {values.disced ?
              <>
                {FormElements.EnumDropDown("levelOfEducation", MainEducationUpdate.levelOfEducation, false, false, true)}
                <FormElements.RecordAutoComplete disabled={true} formValueName="educationField" options={[]}
                  loading={isLoadingCurrentEducation} />
              </> :
              <>
                <FormElements.RecordAutoComplete disabled={true} formValueName="type" options={[]}
                  loading={isLoadingCurrentEducation} />
                <FormElements.RecordAutoComplete disabled={true} formValueName="course" options={[]}
                  loading={isLoadingCurrentEducation} />
              </>
            }

            <FormElements.RecordAutoComplete disabled={true} formValueName="placeOfStudy" options={[]}
              loading={isLoadingCurrentEducation} />

            {showFurtherEducation &&
              <>
                <h2>Videreuddannelse</h2>
                <FormElements.BasicTextInput disabled={true} formValueName="furtherEducation"/>
              </>
            }
          </>}

          <StandardButton onClick={() => navigateToUpdateEducation()} disabled={!allowUpdate} className={style.submitBtn}
            showArrow={false}
            {...props}>
            {"Gå til opdatér"}
          </StandardButton>
        </Form>
      }
    </Formik >;
  }, [currentEducation, isLoadingCurrentEducation, showFurtherEducation]);


  return (
    <div>
      <h2 className="flex">Uddannelsesniveau og fagområde</h2>
      {isLoadingCurrentEducation ? <Loader /> : educationForm}
      <MessageDiv type={MessageType.error} msg={errorMsg} />
    </div>
  );
};
