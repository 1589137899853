import style from "./login.module.scss";
import { LoginFormElement } from "../../../models/umbracoElement";
import { IndividualLoginBoxProps } from "./login-box";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { Link } from "@mui/material";
import { DMTheme } from "../../../helpers/DMTheme";
export interface LoginBoxProps {
    umbracoSettings: LoginFormElement;
    method: string;
    goToAzureB2C: (loginmode: string, goToForgotPassword?: boolean) => void
}

const RemoveLinkStyling = { color: DMTheme.palette.info.contrastText, textDecoration: "underline", padding: "0px", alignSelf: "flex-start" };
export const LoginSettings: (settings: LoginBoxProps) => IndividualLoginBoxProps = ({ goToAzureB2C, method, umbracoSettings }) => {

    let title = umbracoSettings.title || "";
    let body = umbracoSettings.signicatLoginDescription ?? "<div/>";
    let button = <><button style={{ marginTop: 0 }} onClick={() => goToAzureB2C(method)} className={`${style.mitIdButton} ${style.signicatButton}`}>
        <div className={style.mitIdLogo} /><div className={style.actionText}>Log ind med MitID</div>
    </button>
    <Link sx={RemoveLinkStyling} onClick={() => goToAzureB2C("member-email")}>{umbracoSettings.toEmailLoginLabel||"Log ind med e-mail"}</Link>
    </>;

    const nonmemberButtonSettings = <> <StandardButton onClick={() => goToAzureB2C(method)}>{umbracoSettings.toEmailLoginLabel || "Log ind med e-mail"}</StandardButton>
        <Link sx={RemoveLinkStyling} onClick={() => goToAzureB2C("nonmember-email", true)}>{umbracoSettings.createAccountTitle || "Opret e-mail login"}</Link>
    </>;

    if (method != "mitid-cpr" && method != "nemid") {
        title = umbracoSettings.emailLoginTitle;
        body = umbracoSettings.emailLoginDescirption ?? "<div/>";
        button = method == "member-email" ? <StandardButton onClick={() => goToAzureB2C(method)}>{umbracoSettings.toEmailLoginLabel || "Log ind med e-mail"}</StandardButton> : nonmemberButtonSettings;
    }

    return {
        title: title,
        body: body,
        children: button,
    };
};
