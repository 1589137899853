import * as yup from "yup";
import { Schema } from "yup";
import { ParentalLeave } from "../../../api/models/ParentalLeave";
import { min, subDays, subWeeks, subYears } from "date-fns";
import { setLocale } from "yup";


export type ParentalLeaveFormValues = Omit<ParentalLeave, "parentalLeaveStart" | "parentalLeaveEnd" | "benefitStart" | "benefitEnd" | "isPreviousKIMember"> & {
    parentalLeaveStart: Date | null,
    parentalLeaveEnd: Date | null,
    benefitStart: Date | null,
    benefitEnd: Date | null,
    isPreviousKIMember: boolean
}

const dateTransform = (curr: string | Date | null) => {
    return curr == "Invalid Date" ? null : curr;
};

export const parentalLeaveSchema = yup.object({
    isPreviousKIMember: yup.bool().defined("isPreviousKIMember"),


    parentalLeaveStart: yup.date()
        .nullable()
        .transform(dateTransform)
        .required("Udfyld en start dato"),
    parentalLeaveEnd: yup.date()
        .nullable()
        .transform(dateTransform)
        .required("Udfyld en slut dato")
        .min(yup.ref("parentalLeaveStart"), "Slut dato skal være efter start dato"),
    benefitStart: yup.date()
        .nullable()
        .transform(dateTransform)
        .required("Udfyld en start dato")
        .min(yup.ref("parentalLeaveStart"), "Dagpenge start dato skal være efter barsels start dato"),
    benefitEnd: yup.date()
        .transform((curr:string|Date|null, orig:string|Date|null) => orig === "Invalid Date" ? null : curr)
        .nullable()
        .required("Udfyld en slut dato")
        .min(yup.ref("benefitStart"), "Slut dato skal være efter start dato"),
});