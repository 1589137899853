import { Button } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { isMobile } from "../../constants/common-constants";
import {DMColors} from "../../helpers/DMTheme";
import style from "./MobileMenu.module.scss";
export interface LogoutProps {
    data: string;
}
const onClickLogout = () => {
    window.location.assign("/?logout=true");
};

export const TopButtonStyling : SxProps<Theme> = {
    padding: "6px 16px",
    fontWeight: 600,
    color: "#000000",
    fontFamily: "AvenirNext",
    fontSize: "14px",
    "&:hover": { borderRadius: "0" },
    borderRadius: 0,
    width: isMobile() ? "50%" : 104, 
    height: 80,
    textTransform:"none",
};

export const Logout = (props: LogoutProps) => {
    // Maybe this background color should be a "dm color"/theme color
    return <Button className={style.logoutButton} onClick={(e) => onClickLogout()} sx={{...TopButtonStyling,backgroundColor: DMColors.dm_green}}>Log af</Button>;
};