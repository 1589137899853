/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { ContactsService, CrmEntity, MetadataService, Sector } from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";
import { SectorInterestElement } from "../../../models/umbracoElement";
import React from "react";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { isMobile } from "../../../helpers/MobileHelper";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import * as style from "./sector-interests-form.module.scss";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import formStyles from "../../_GenericElements/_GenericFormElements/form.module.scss";
import { update } from "immutable";
import { UpdateConfirmMessageBox } from "../UpdateConfirmMessageBox/UpdateConfirmMessageBox";

export interface SectorInterestsFormProps {
    data: string;
}

const defaultFormValues: Sector = {
    sectorRecord: {
        id: "",
        primaryName: "",
        organisation: undefined,
    },
};

const MasterdataSectorLabel: Record<keyof Sector, string> = {
    "sectorRecord": "Interessesektor",
};

export const SectorInterestsForm = (props: SectorInterestsFormProps) => {
    const [umbracoSettings] = useState<SectorInterestElement>(props.data ? JSON.parse(props.data) : {});
    const [isSubmitting, setSubmitting] = useState(false);
    const [sectorInterestId, setSectorInterestId] = useState<string>();
    const [isLoading, setLoading] = useState(true);
    const [defaultValue, setDefaultValue] = useState<Sector>(defaultFormValues);
    const [sectors, setSectors] = useState<(CrmEntity & { disabled: boolean })[]>();
    const [showConfirm, setShowConfirm] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");


    const FormElements = new GenericFormElements<Sector>(MasterdataSectorLabel);

    useEffect(() => {
        void getCurrentData();
    }, []);

    const getCurrentData = async () => {
        setLoading(true);

        const contact = await ContactsService.getContact();

        const sectors = (await ContactsService.getValidInterestSectors()).map(sector =>  ({
            ...sector.sectorRecord,
            disabled: sector.disabled ?? false,
        }));

        contact.sectorInterest && setDefaultValue({ sectorRecord: contact.sectorInterest });
        setSectorInterestId(contact.sectorInterest?.id);
        setSectors(sectors);
        setLoading(false);
    };

    const updateInterestSector = async (value: Sector) => {
        if (value.sectorRecord?.id) {
            try {
                await ContactsService.updateSectorInterest(value);

                // Update sectors so we disable the new selected interest sector
                // so we avoid the need to refresh page
                setSectors((prevSectors) =>
                    prevSectors?.map((sector) => {
                        if (sector.id === sectorInterestId) {
                            return {...sector, disabled: false};
                        }

                        if (sector.id === value.sectorRecord?.id) {
                            return {...sector, disabled: true};
                        }

                        return sector;
                    }
                    )
                );
                setSectorInterestId(value.sectorRecord.id);

                setShowConfirm(true);
            } catch (e) {
                setErrorMsg("Der er sket en uventet fejl og ændringer er ikke gennemført. Prøv venligst igen senere og kontakt os ved gentagen fejl.");
            }
        }
    };

    const sectorInterestForm = (
        <Formik
            initialValues={defaultValue}
            onSubmit={async (values) => {
                setSubmitting(true);
                await updateInterestSector(values);
                setSubmitting(false);
            }}
            enableReinitialize={true}
        >
            {({ errors, values }) =>
                <Form className={formStyles.dmForm} style={{ textAlign: isMobile ? "center" : "inherit" }}>
                    {sectors && FormElements.RecordDropDown(
                        "sectorRecord", sectors, isLoading, false
                    )}
                    {<StandardButton type="submit" loading={isSubmitting} disabled={isSubmitting || values.sectorRecord?.id === sectorInterestId}
                        showArrow={false}>
                        {umbracoSettings.submitButtonLabel || "Opdatér"}
                    </StandardButton>}
                </Form>}
        </Formik>);

    return (
        <><h2>{umbracoSettings.text || ""}</h2>
            {isLoading ?
                <Loader /> : <>
                    {umbracoSettings.contentText && SantizedRichHtml(umbracoSettings.contentText)}
                    {defaultValue && sectorInterestForm}
                    {<> {umbracoSettings.contactInformationLink?.url &&
                            <StandardButton className={style.linkbutton} href={umbracoSettings.contactInformationLink.url}>Ret kontaktoplysninger</StandardButton>}
                    </>}

                    {showConfirm && <UpdateConfirmMessageBox onClose={() => setShowConfirm(false)} title={"Interessesektor ændring"} />}
                    <MessageDiv type={MessageType.error} msg={errorMsg} />
                </>}
        </>
    );
};