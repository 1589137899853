interface headerProps {
    src: string;
}

export const HeaderLogo = (props: headerProps) => {
    return <div className="dm_header_link_logo_container">
        <a href={window.location.origin}>
            <img className="dm_header_logo" src={props.src}></img>
        </a>
    </div>;
};