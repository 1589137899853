/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgreementInformation } from '../models/AgreementInformation';
import type { EndMembership } from '../models/EndMembership';
import type { EndMembershipInfo } from '../models/EndMembershipInfo';
import { request as __request } from '../core/request';

export class MembershipService {

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async endMembership(
requestBody?: EndMembership,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/membership/EndMembership`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
            },
        });
        return result.body;
    }

    /**
     * @returns EndMembershipInfo Success
     * @throws ApiError
     */
    public static async getEndMembershipInfo(): Promise<EndMembershipInfo> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/membership/GetEndMembershipInfo`,
            errors: {
                500: `Server Error`,
            },
        });
        return result.body;
    }

    /**
     * @returns AgreementInformation Success
     * @throws ApiError
     */
    public static async getMembership(): Promise<AgreementInformation> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/membership/GetMembership`,
            errors: {
                500: `Server Error`,
            },
        });
        return result.body;
    }

}