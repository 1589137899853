import { CSSProperties } from "@mui/styled-engine";
import style from "./label-value.module.scss";

interface LabelValueProps {
    label: string;
    value: string|JSX.Element|undefined|null;
    trClassName?:string;
    hideIfEmpty?: boolean;
}

function LabelValue(props: LabelValueProps) {
    if (props.hideIfEmpty && !props.value) {
        return <></>;
    }
    return (
        <tr className={props.trClassName??""}>
            <td className={style.label}>{props.label}</td>
            <td className={style.value}>{props.value}</td>
        </tr>
    );
}

export default LabelValue;