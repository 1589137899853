/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EventQuestion = {
    id: string;
    question: string;
    options?: string | null;
    isRequired?: boolean | null;
    type?: EventQuestion.type | null;
}

export namespace EventQuestion {

    export enum type {
        ENKELTEKST = 'Enkeltekst',
        BOOLESKJANEJ = 'Booleskjanej',
        SP_RGSM_LMEDFLERESVARMULIGHEDER = 'Spørgsmålmedfleresvarmuligheder',
        ENKELTVALG = 'Enkeltvalg',
    }


}