import { CrmEntity, MainEmployment } from "../../../api";
import * as yup from "yup";
import {  Schema } from "yup";
import { CRMEntitySchema, CRMEntitySchemaWithMesssage } from "../../_GenericElements/_GenericFormElements/GenericFormSchema";


export type FreelanceOrIndependentFormValues = NonNullable<Omit<MainEmployment, "startDate"| "relatedSector"> & { startDate: Date, workTime: string, isPreviousKIMember: boolean }>

export const FreelanceSchema: Schema<FreelanceOrIndependentFormValues> = yup.object({
    isPreviousKIMember: yup.bool().defined("isPreviousKIMember"),

    startDate: yup.date().when("isPreviousKIMember", ([isPreviousKIMember], schema) => {
        if (isPreviousKIMember) {
            return schema
                .required("Udfyld en start dato")
                .min(new Date(2024, 0, 1, 0, 0, 0, 0), "Medlemskabsændringer før d. 1/1-2024, skal ske ved at kontakte Medlemsservice på tlf. 38156600 eller via mail service@dm.dk.");
        }

        return schema
            .required("Udfyld en start dato");
    }),

    workTime: yup.string().required("Vælg Fuldtid/deltid"),
    employmentType: CRMEntitySchemaWithMesssage("Vælg en Selvstændig/Freelance"),
    jobPosition: CRMEntitySchemaWithMesssage("Vælg en stilling"),
    hasFullTimeEmployment: yup.bool().defined("Vælg arbejdstid"),
    isFreelancer: yup.bool().defined(),
    placeOfWorkNotinList: yup.bool().defined(),
    placeOfEmploymentName: yup.string().nullable()
        .notRequired(),
    companyAddress: yup.string().nullable()
        .notRequired(),
    cvr: yup.string().nullable()
        .notRequired(),
    workArea: yup
        .object({
            id: yup.string().required("Vælg et arbejdsområde"),
            primaryName: yup.string().required("Vælg et arbejdsområde"),
        })
        .required("Vælg et arbejdsområde"),
});

export const SelfEmployedSchema: Schema<FreelanceOrIndependentFormValues> = yup.object({
    isPreviousKIMember: yup.bool().defined("isPreviousKIMember"),
    startDate: yup.date().when("isPreviousKIMember", ([isPreviousKIMember], schema) => {
        if (isPreviousKIMember) {
            return schema
                .required("Udfyld en start dato")
                .min(new Date(2024, 0, 1, 0, 0, 0, 0), "Medlemskabsændringer før d. 1/1-2024, skal ske ved at kontakte Medlemsservice på tlf. 38156600 eller via mail service@dm.dk.");
        }

        return schema
            .required("Udfyld en start dato");
    }),
    workTime: yup.string().required("Vælg Fuldtid/deltid"),
    newPlaceOfWork: CRMEntitySchema
        .when(["placeOfWorkNotinList"],
            {
                is: (placeOfWorkNotinList: boolean) => !placeOfWorkNotinList,
                then: (schema) => schema
                    .optional(),
                otherwise: (schema) => schema
                    .transform((c: CrmEntity) => c.id && c.primaryName ? c : undefined)
                    .optional(),
            }),
    employmentType: CRMEntitySchemaWithMesssage("Vælg en ansættelsestype"),
    jobPosition: CRMEntitySchemaWithMesssage("Vælg en stilling"),
    hasFullTimeEmployment: yup.bool().defined("Vælg arbejdstid"),
    isFreelancer: yup.bool().defined(),
    placeOfWorkNotinList: yup.bool().defined(),
    placeOfEmploymentName: yup.string().required("Mangler navn på ny arbejdsplads")
        .min(1, "Mangler navn på ny arbejdsplads"),
    companyAddress: yup.string()
        .required("Mangler adresse på ny arbejdsplads")
        .min(5, "Mangler adresse på ny arbejdsplads"),
    cvr: yup.string()
        .required("Mangler cvr nummer på ny arbejdsplads")
        .length(8, "CVR nummer skal være 8 cifre"),
    workArea: yup
        .object({
            id: yup.string().required("Vælg et arbejdsområde"),
            primaryName: yup.string().required("Vælg et arbejdsområde"),
        })
        .required("Vælg et arbejdsområde"),

});