/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { ContactsService, CrmEntity, Sector } from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";
import { MasterDataSectorElement } from "../../../models/umbracoElement";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { isMobile } from "../../../helpers/MobileHelper";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import * as style from "./master-data-sector-form.module.scss";
import { CRMEntitySchemaWithMesssage } from "../../_GenericElements/_GenericFormElements/GenericFormSchema";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import * as yup from "yup";
import { LabelValueTable } from "../../_GenericElements/LabelValueTable/LabelValueTable";
import LabelValue from "../../_GenericElements/LabelValue/label-value";

export interface MasterDataSectorFormProps {
    data: string;
}

const MasterdataSectorLabel: Record<keyof Sector, string> = {
    "sectorRecord": "Stamdatasektor",
};

const MasterdataSectorSchema = yup.object({
    sectorRecord: CRMEntitySchemaWithMesssage("Vælg en sektor"),
});

export const MasterDataSectorForm = (props: MasterDataSectorFormProps) => {
    const [umbracoSettings] = useState<MasterDataSectorElement>(props.data ? JSON.parse(props.data) : {});
    const [isSubmitting, setSubmitting] = useState(false);
    const [primarySector, setPrimarySector] = useState<CrmEntity>();
    const [isLoading, setLoading] = useState(true);
    const [sectorOptions, setSectorOptions] = useState<CrmEntity[]>();
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [isLoadingUpdateAllowed, setIsLoadingUpdateAllowed] = useState<boolean>(true);
    const [allowUpdate, setAllowUpdate] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        void getIfSektorUpdatesIsAllowed();
        void getCurrentData();
    }, []);

    useEffect(() => {
        const dmElement = ref.current?.parentElement?.parentElement;
        // hide this element if member cannot choose anything
        if (dmElement && sectorOptions && sectorOptions.length === 0) {
            dmElement.style.visibility = "hidden";
            dmElement.style.padding = "0";
            dmElement.style.margin = "0";
            dmElement.style.height = "0";
        }
    }, [sectorOptions]);

    const getCurrentData = async () => {
        setLoading(true);
        const sectors = await ContactsService.getSelectableMasterDataSectors();
        const contact = await ContactsService.getContact();

        setPrimarySector(contact.sector);
        if (!sectors || sectors.length === 0) {
            setErrorMsg("Du kan ikke ændre din stamdatasektor");
        } else {
            setSectorOptions(sectors);
        }
        setLoading(false);
    };

    const getIfSektorUpdatesIsAllowed = async () => {
        let updateIsAllowed: boolean = true;
        try {

            // Previous KI member is not allowed to make changes before after 1/1/2024
            if (new Date() < new Date(2024, 1, 1)) {
                let isPreviousKIMember = await ContactsService.getIsPreviousKiMember();
                updateIsAllowed = !isPreviousKIMember;
            }
        } catch (err) {
            console.error(err);
        } finally {
            setAllowUpdate(updateIsAllowed);
            setIsLoadingUpdateAllowed(false);
        }
    };

    const updateMasterdataSector = async (newSector: Sector) => {
        await ContactsService.updateMasterdataSector(newSector);
        await getCurrentData();
    };
    const FormElements = new GenericFormElements<Sector>(MasterdataSectorLabel);

    const form = (
        <Formik
            initialValues={{
                newSector: { id: "", primaryName: "" },
            }}
            validationSchema={MasterdataSectorSchema}
            onSubmit={async (values: Sector) => {
                setSubmitting(true);
                await updateMasterdataSector(values);
                setSubmitting(false);
            }}
            enableReinitialize={true}
        >
            {() =>
                <Form className={style.dmForm} style={{ textAlign: isMobile ? "center" : "inherit" }}>
                    {sectorOptions && FormElements.RecordDropDown("sectorRecord", sectorOptions, isLoading)}
                    {sectorOptions && <StandardButton type="submit" loading={isSubmitting} disabled={isSubmitting || isLoadingUpdateAllowed || !allowUpdate}
                        showArrow={false}>
                        {umbracoSettings.submitButtonLabel || "Opdatér"}
                    </StandardButton>}
                </Form>}
        </Formik>);

    return (
        <div ref={ref} className="hiddenIfEmpty"><h2>{umbracoSettings.text || ""}</h2>
            {isLoading ?
                <Loader /> : <>
                    {umbracoSettings.contentText && SantizedRichHtml(umbracoSettings.contentText)}

                    <LabelValueTable className={style.currentsector}>
                        <LabelValue label={"Nuværende stamdatasektor: "} value={primarySector?.primaryName || "-"} />
                    </LabelValueTable>
                    {!errorMsg && sectorOptions && sectorOptions.length > 0 && form}
                    <div className={style.messagebox}>
                        <MessageDiv msg={errorMsg} type={MessageType.error} />
                    </div>
                </>}
        </div>
    );
};