import ReactDOM from "react-dom";
import { Root } from "./Root";

export const RootedReactElement = (Component: React.ComponentType<any>, properties: string[]) => {
    return class StyledClass extends HTMLElement {
        protected renderRoot: HTMLElement = this;
        protected props: Record<string, any> = {};
        protected hasConnected = false;
        public constructor() {
            super();
        }

        protected getPropName(attrName: string) {
            return attrName.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
        }

        public static get observedAttributes() {
            return properties || [];
        }

        public attributeChangedCallback(name: string, oldValue: any, newValue: any) {
            if (oldValue === newValue) {
                return;
            }
            this.props[this.getPropName(name)] = newValue;

            if (this.hasConnected) {
                this.render();
            }
        }

        protected render() {
            if (this.hasConnected) {
                ReactDOM.render(<Root><Component {...this.props}/></Root>, this.renderRoot);
            } else {
                ReactDOM.render(<Root><Component ></Component></Root>, this.renderRoot);
            }
        }

        connectedCallback() {
            this.hasConnected = true;
            this.render();
        }

        public disconnectedCallback() {
            ReactDOM.unmountComponentAtNode(this.renderRoot as Element);
        }
    };
};