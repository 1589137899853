/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Draft } from '../models/Draft';
import type { Incident } from '../models/Incident';
import type { IncidentCategory } from '../models/IncidentCategory';
import type { IncidentCreate } from '../models/IncidentCreate';
import type { Message } from '../models/Message';
import type { NewMessageCreate } from '../models/NewMessageCreate';
import { request as __request } from '../core/request';

export class IncidentsService {

    /**
     * @param filter 
     * @param ticketNumber 
     * @returns Incident Success
     * @throws ApiError
     */
    public static async getIncidentById(
filter: 'Alle' | 'Medlemssager' | 'TRSager' | 'Strakshenvendelse',
ticketNumber: string,
): Promise<Incident> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/incidents/${filter}/${ticketNumber}`,
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * @param filter 
     * @returns Incident Success
     * @throws ApiError
     */
    public static async getAllActiveIncidents(
filter: 'Alle' | 'Medlemssager' | 'TRSager' | 'Strakshenvendelse',
): Promise<Array<Incident>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/incidents/${filter}/active`,
        });
        return result.body;
    }

    /**
     * @param filter 
     * @returns Incident Success
     * @throws ApiError
     */
    public static async getAllArchivedIncidents(
filter: 'Alle' | 'Medlemssager' | 'TRSager' | 'Strakshenvendelse',
): Promise<Array<Incident>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/incidents/${filter}/archived`,
        });
        return result.body;
    }

    /**
     * @param filter 
     * @param ticketNumber 
     * @returns Message Success
     * @throws ApiError
     */
    public static async getAllMessagesForIncident(
filter: 'Alle' | 'Medlemssager' | 'TRSager' | 'Strakshenvendelse',
ticketNumber: string,
): Promise<Array<Message>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/incidents/${filter}/messages/${ticketNumber}`,
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * @param type 
     * @returns IncidentCategory Success
     * @throws ApiError
     */
    public static async getCategories(
type: 'Alle' | 'Medlemssager' | 'TRSager' | 'Strakshenvendelse',
): Promise<Array<IncidentCategory>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/incidents/categories/${type}`,
        });
        return result.body;
    }

    /**
     * @param type 
     * @param requestBody 
     * @returns Incident Success
     * @throws ApiError
     */
    public static async createIncident(
type: 'Alle' | 'Medlemssager' | 'TRSager' | 'Strakshenvendelse',
requestBody?: IncidentCreate,
): Promise<Incident> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/incidents/create/${type}`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @param type 
     * @param requestBody 
     * @returns Message Success
     * @throws ApiError
     */
    public static async createMessage(
type: 'Alle' | 'Medlemssager' | 'TRSager' | 'Strakshenvendelse',
requestBody?: NewMessageCreate,
): Promise<Message> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/incidents/${type}/messages`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * @param ticketNumber 
     * @param id 
     * @param filter 
     * @returns string Success
     * @throws ApiError
     */
    public static async getAttachment(
ticketNumber: string,
id: string,
filter: string,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/incidents/${filter}/messages/${ticketNumber}/attachments/${id}`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @param draftContent 
     * @param subject 
     * @param incidentLookup 
     * @param category 
     * @returns string Success
     * @throws ApiError
     */
    public static async saveDraftInTable(
draftContent?: string,
subject?: string,
incidentLookup?: string,
category?: string,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/incidents/drafts`,
            query: {
                'draftContent': draftContent,
                'subject': subject,
                'incidentLookup': incidentLookup,
                'category': category,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * @param draftId 
     * @returns string Success
     * @throws ApiError
     */
    public static async deleteDraft(
draftId: string,
): Promise<string> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/v1/incidents/drafts/${draftId}`,
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * @param caseId 
     * @returns Draft Success
     * @throws ApiError
     */
    public static async getDraftMessageForIncident(
caseId: string,
): Promise<Draft> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/incidents/draft/${caseId}`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns Draft Success
     * @throws ApiError
     */
    public static async getDraftMessageNotInIncident(): Promise<Draft> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/incidents/draft`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}