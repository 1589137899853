/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Incident = {
    memberId?: string | null;
    contactName?: string | null;
    id: string;
    title?: string | null;
    lastUpdated?: string | null;
    senderOfLastMsgIsPortalStaff?: boolean;
    lastSenderName?: string | null;
    hasUnreadMessages: boolean;
    status?: Incident.status | null;
    isArchived?: boolean;
    guidId?: string;
}

export namespace Incident {

    export enum status {
        AKTIV = 'Aktiv',
        L_ST = 'Løst',
        ANNULLERET = 'Annulleret',
    }


}