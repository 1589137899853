import React, { useEffect } from "react";

import * as api from "../../../api/services/ContactsService";
import { ContactRead } from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";

import style from "./event-overview.module.scss";
import LabelValue from "../../_GenericElements/LabelValue/label-value";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { profilPageKontaktinformationLink } from "../../../constants/common-constants";


export const EventContactInformation = () => {
    const [contactdata, setContactdata] = React.useState<ContactRead>();

    useEffect(() => {
        void (async () => {
            let contactData = await api.ContactsService.getContact();
            if (contactData) {
                setContactdata(contactData);
            }
        })();

    }, []);

    if (!contactdata) {
        return <Loader />;
    }

    let fields: { label: string, value?: string | null }[] = [
        { label: "Navn", value: contactdata.fullName},
        { label: "Email", value: contactdata.email },
        { label: "Mobil", value: contactdata.mobilePhone },

    ];

    return <div className={style.root}>
        <h2 className={style.header}>
            {"Mine oplysninger"}
        </h2>

        <table className={style.contactdata}>
            <tbody>
                {fields.map((f) => {
                    return <LabelValue key={f.label} label={f.label} value={f.value}></LabelValue>;
                })}
            </tbody>

        </table>
        <div className={`${style.updatebtn}`} >
            <StandardButton href={profilPageKontaktinformationLink}> {"Ændre oplysninger"}</StandardButton>
        </div>
    </div>;
};
