/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { RepresentativeService, RepresentativeSubmission } from "../../../../api";
import LabelValue from "../../../_GenericElements/LabelValue/label-value";
import { hideOrganizationType } from "../../../../constants/common-constants";
import styles from "../representative-overview.module.scss";
import Loader from "../../../_GenericElements/Loader/loader";
import { MessageDiv, MessageType } from "../../../_GenericElements/MessageDiv/MessageDiv";
import { StandardButton } from "../../../_GenericElements/StandardButton/StandardButton";

type SubmissionDetailProps = {
    id: string
    goBack?:()=>void

}

export const SubmissionDetail = (props: SubmissionDetailProps) => {
    const [data, setData] = useState<RepresentativeSubmission>();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const res = await RepresentativeService.getRepresentativeSubmission(props.id);
                if (!res) {setErrorMessage("Der findes ikke nogen data.");}
                setData(res);
            } catch (err) {
                setErrorMessage("Der er sket en fejl. Data kunne ikke hentes.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, []);
    return (
        <>{loading && <Loader />}
            {data && <>
                <h1>Anmeldte hverv</h1>

                <table>
                    <LabelValue trClassName={styles.labeltr} label="Hverv" value={data.type?.name ?? ""} />
                    {data.vipTap? <LabelValue trClassName={styles.labeltr} label="VIP/TAP" value={data.vipTap?.name == "VIPogTAP" ? "VIP og TAP" : data.vipTap?.name} />: <></>}
                    <LabelValue trClassName={styles.labeltr} label="Anmeldelsesområde" value={data.areas?.map(x => x.name).join(", ") ?? ""} />
                    <LabelValue trClassName={styles.labeltr} label="Dækningsområde" value={data.coverageArea ?? ""} />
                    <LabelValue trClassName={styles.labeltr} label="Start dato" value={data.startDate ? format(new Date(data.startDate), "dd/MM/yyyy") : ""} />
                    {data.type?.name !== hideOrganizationType &&
                    <LabelValue trClassName={styles.labeltr} label="Organisationer" value={data.acOrganizations?.map(x => x.name).join(", ") || ""} />}
                    {data.network ? <LabelValue label="Netværk" value={data.network.name} /> : <></>}
                    <LabelValue trClassName={styles.labeltr} label="Tidligere tillidsvalgte" value={data.previousRepresentative || ""} />
                    <LabelValue trClassName={styles.labeltr} label="Arbejdstelefon" value={data.phone || ""} />
                    <LabelValue trClassName={styles.labeltr} label="Mobilnummer" value={data.mobile || ""} />
                    <LabelValue trClassName={styles.labeltr} label="Arbejdsemail" value={data.email || ""} />
                    <LabelValue trClassName={styles.labeltr} label="Email ansættelsessted" value={data.emailForarea || ""} />

                    <LabelValue trClassName={styles.labeltr} label="Samtykke" value={data.consent == true ? "Ja" : "Nej"} />
                </table>
            </>}
            <MessageDiv type={MessageType.error} msg={errorMessage} />
            {props.goBack && <StandardButton showArrow={false} onClick={props.goBack} >
                            Tilbage
            </StandardButton>}

        </ >
    );
};