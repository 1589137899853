/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect } from "react";
import { ContactsService, ApiError, Group } from "../../../api";
import { DanishDateFns } from "../../../helpers/DanishDateFns";
import { isMobile } from "../../../helpers/MobileHelper";
import { PoliticalGroupsElement } from "../../../models/umbracoElement";
import { DMTabelData, DMTableColumn, DMTableWrapper } from "../../_GenericElements/DMTable/table";
import Loader from "../../_GenericElements/Loader/loader";

interface politicalGroupProps {
    data:string
}

export const ShowPoliticalGroups = (props:politicalGroupProps) => {
    let umbracoSettings: PoliticalGroupsElement = JSON.parse(props.data);
    const [data, setdata] = React.useState<Group[]>();
    const [isMember, setisMember]= React.useState<boolean>(true);

    
    
    useEffect(() => {
        void (async () => {
            try {
                let Data : Group[] = await ContactsService.getPoliticalGroups();
                setdata(Data);
            } catch (e) {
                if (e instanceof ApiError) {
                    if (e.status == 500) {
                        console.log("Finder ingen medlemskab");
                        setisMember(false);
                    } 
                }
            }         
        })();
    }, []);
    
    function createData(number: string, rolle: string, udvalg: string, start: string, slut: string): DMTabelData {
        return {
            id: number,
            values: [
                { id: "rolle", value: rolle },
                { id: "udvalg", value: udvalg },
                { id: "start", value: start },
                { id: "slut", value: slut },
            ],
        };
    }

    const columns: DMTableColumn[] = [
        {
            id: "rolle",
            label: "Rolle",
            minWidth: 170,
            mobile: true,
        },
        {
            id: "udvalg",
            label: "Udvalg",
            minWidth: 100,
            mobile: true,
        },
        {
            id: "start",
            label: "Start dato",
            minWidth: 170,
        },
        {
            id: "slut",
            label: "SlutDato",
            minWidth: 50,
        },
    ];
    
    const rows = data ? data.map((e: Group) =>
        createData(e.id ?? "error", e.role ? e.role : "",  
            e.groupName ? e.groupName : "", 
            e?.startDate ? DanishDateFns.format(new Date(e?.startDate), "dd-MM-yyyy") : "", 
            e?.endDate ? DanishDateFns.format(new Date(e?.endDate), "dd-MM-yyyy") : "")) : [];

    return data ? <div>
        <div> 
            <DMTableWrapper
                title={umbracoSettings.overskrift || "Mine udvalg"}
                titleHTag={"h2"}
                columns={columns}
                data={rows}
            />

        </div>
        <div style={{ textAlign: isMobile ? "center" : "inherit" }}>
            <p>{umbracoSettings.udvalgTekst || ""}</p>
        </div>
    </div> : isMember ? <Loader /> : <div></div>;

};