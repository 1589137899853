import Autocomplete, {autocompleteClasses } from "@mui/material/Autocomplete";
import { fieldToAutocomplete, AutocompleteProps } from "formik-mui";
import { ListboxComponent } from "../ListBox/ListBox";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";

export function VirtualizedAutoComplete<T, Multiple extends boolean, DisableClearable extends boolean, FreeSolo extends boolean>(
    props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {

    const StyledPopper = styled(Popper)({
        [`& .${autocompleteClasses.listbox}`]: {
            boxSizing: "border-box",
            "& ul": {
                padding: 0,
                margin: 0,
            },
        },
    });

    return <Autocomplete
        {...fieldToAutocomplete(props)}
        disableListWrap
        ListboxComponent={ListboxComponent}
        PopperComponent={StyledPopper}
    />;
}