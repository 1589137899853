import { useEffect, useState } from "react";
import { ApiError, MetadataService, PaymentGroupService, Retired, AgreementGroup, ContactsService, MembershipService } from "../../../api";
import { Form, Formik } from "formik";
import { RetirementFormValues, RetirementFormSchema } from "./RetirementFormSchema";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import { ApiErrorBody } from "../../../models/apiError";
import { Stack } from "@mui/material";
import { RetirementFormElement } from "../../../models/umbracoElement";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import formStyles from "../../_GenericElements/_GenericFormElements/form.module.scss";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import { ConfirmMessageBox } from "../ConfirmMessageBox/ConfirmMessageBox";
import { profilPageLink } from "../../../constants/common-constants";
import { formatDateDDMMYY } from "../../../helpers/Utils";

export type IRetirementFormProps = {
    data: string;
}
function RetirementFormLabels(elementConfig: RetirementFormElement): Record<keyof RetirementFormValues, string> {
    return {
        "startDate": elementConfig.startDatoLabel || "Start dato",
        "pensionType": elementConfig.pensionsTypeLabel || "Pensions type",
        "endDate": elementConfig.slutDatoLabel || "Slut dato",
        "isPreviousKIMember": "Is previous KI Member",
    };
}

export const RetirementForm = (props: IRetirementFormProps) => {
    let umbracoSettings: RetirementFormElement = JSON.parse(props.data);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [readonly, setReadonly] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [retirementTypes, setRetirementTypes] = useState<AgreementGroup[]>([]);
    const [defaultValues, setDefaultValues] = useState<Partial<RetirementFormValues>>({
        startDate: new Date(),
        pensionType: undefined,
        endDate: new Date(),
        isPreviousKIMember: false,
    });
    const [efterlønTypeName, setEfterlønTypeName] = useState<string>("");
    const [isLoadingRetirementTypes, setIsLoadingRetirementTypes] = useState<boolean>(false);
    const [isLoadingIfPreviousKIMember, setIsLoadingIfPreviousKIMember] = useState<boolean>(false);
    const [agreementStartDate, setAgreementStartDate] = useState<Date>();

    useEffect(() => {
        void GetCurrentInformation();
        void getRetirementTypes();
    }, []);

    useEffect(() => {
        let efterlønDisplayName = retirementTypes.find(x => x.displayName.includes("Efterløn"))?.displayName;

        if (efterlønDisplayName != undefined) {
            setEfterlønTypeName(efterlønDisplayName);
        }
    }, [retirementTypes]);

    const changePaymentGroup = async (formData: RetirementFormValues) => {
        let retiredDTO: Retired = {
            startDate: formData.startDate.toISOString(),
            pensionType: formData.pensionType,
            endDate: formData.pensionType.displayName == efterlønTypeName && formData.endDate != undefined ? formData.endDate.toISOString() : null,
        };

        try {
            await PaymentGroupService.setToRetired(retiredDTO);
            setShowConfirm(true);
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 422) {
                    setErrorMsg("Det ønskede skift er ikke muligt fra din nuværende kontingent gruppe");
                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg(errorInfo.title);
                }
            }
        }
    };

    const getRetirementTypes = async () => {
        try {
            setIsLoadingRetirementTypes(true);
            setRetirementTypes(await MetadataService.getRetirementTypes());
            setIsLoadingRetirementTypes(false);
        } catch (e) {
            console.error(e);
            setErrorMsg("Der er sket en fejl ved at hente pensionstype");
        }
    };

    const GetCurrentInformation = async () => {
        let currentInformation: Retired | undefined = undefined;
        try {
            setIsLoadingIfPreviousKIMember(true);
            let agreementInformation = await MembershipService.getMembership();
            agreementInformation.startDate && setAgreementStartDate(new Date(agreementInformation.startDate));

            currentInformation = await PaymentGroupService.getCurrentRetirementInformation();

        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 422) {
                    console.log("Brugeren var ikke på pension");
                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setReadonly(true);
                    setErrorMsg(errorInfo.title);
                }
            }
        } finally {
            const isPreviousKIMember = await getIsPreviousKIMember();

            let newDefaults: Partial<RetirementFormValues> = currentInformation != undefined ?
                {
                    startDate: currentInformation.startDate ? new Date(currentInformation.startDate) : new Date(),
                    pensionType: currentInformation.pensionType || undefined,
                    isPreviousKIMember: isPreviousKIMember,
                } :
                {
                    ...defaultValues, isPreviousKIMember: isPreviousKIMember,
                };
            setDefaultValues(newDefaults);
            setIsLoadingIfPreviousKIMember(false);
        }
    };

    const getIsPreviousKIMember = async () => {
        try {
            return await ContactsService.getIsPreviousKiMember();
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    const onCloseConfirmMessage = () => {
        setShowConfirm(false);
        window.location.href = profilPageLink;
    };

    const formElements = new GenericFormElements(RetirementFormLabels(umbracoSettings));

    let startDateField = <formElements.BasicDateInput formValueName="startDate" />;
    let pensionTypeDropDown = <formElements.RecordDropDownFullValue
        formValueName="pensionType"
        options={retirementTypes.map((rt => { return { id: rt.priceGroup, primaryName: rt.displayName, ...rt }; }))}
        loading={isLoadingRetirementTypes} />;
    let endDateField = <formElements.BasicDateInput formValueName="endDate"/>;

    let form = <Formik
        initialValues={defaultValues}
        validationSchema={RetirementFormSchema}
        onSubmit={ async (values, { setSubmitting }) => {
            await changePaymentGroup(values as RetirementFormValues);
            setSubmitting(false);
        }}
        enableReinitialize={true}
        validate={(values) => {
          type errors = {
            startDate: undefined | string,
            endDate: undefined | string
          }

          const errorList: errors = {
              startDate: undefined,
              endDate: undefined,
          };

          let anyErrors = false;

          if (values.startDate && values.startDate < new Date(2024, 1, 1)) {
              errorList.startDate = "Start dato for ændringen kan ikke ligge før start datoen for medlemskabbet: 01-01-2024.";
              anyErrors = true;
          }

          if (values.pensionType?.displayName == efterlønTypeName) {
              if (values.endDate != undefined && values.startDate != undefined && values.endDate.getDate() <= values.startDate.getDate()) {
                  errorList.endDate = "Slut datoen skal ligge efter start datoen";
                  anyErrors = true;
              }
          }

          if (agreementStartDate && values.startDate && values.startDate < agreementStartDate) {
              let dateFormatted = formatDateDDMMYY(agreementStartDate);
              errorList.startDate = `Du kan registrere ændringer fra den dato, du blev medlem af DM: ${dateFormatted}`;
              anyErrors = true;
          }

          if (anyErrors) {
              return errorList;
          } else {
              return {};
          }
        }}
    >
        {({ handleSubmit,
            isSubmitting,
            values,
        }) => {
            return (
                <Form className={formStyles.dmForm} onSubmit={handleSubmit} >
                    {startDateField}
                    {pensionTypeDropDown}
                    {values.pensionType?.displayName === efterlønTypeName && endDateField}
                    <formElements.SubmitButton loading={isSubmitting} type="submit" disabled={readonly || isSubmitting || isLoadingIfPreviousKIMember} />
                    {values.startDate && showConfirm && <ConfirmMessageBox fromDate={values.startDate} onClose={onCloseConfirmMessage} />}
                    <MessageDiv type={MessageType.error} msg={errorMsg} />
                </Form>
            );
        }
        }
    </Formik>;

    return <Stack direction="column" justifyItems="flex-start" alignItems="grow">
        <h2>{umbracoSettings.text}</h2>
        {umbracoSettings.contentText && SantizedRichHtml(umbracoSettings.contentText)}
        {form}
    </Stack>;
};

