import { Breadcrumbs, Link } from "@mui/material";
import React, { useMemo, useState } from "react";
import { NavigationElement } from "../../models/umbracoElement";
import { ColoredRightArrowIcon, HomeIcon, RightArrowIcon } from "../_GenericElements/Icons/Icons";
import { DMTheme } from "../../helpers/DMTheme";
import { isMobile } from "../../helpers/MobileHelper";

export interface NavBarProps {
    data: string;
}

export const NavBar = (props: NavBarProps) => {
    let breadcrumbs: JSX.Element = <div />;
    const [navelem] = useState<NavigationElement>(JSON.parse(props.data || "{}"));
    const rightArrow = useMemo(() => ColoredRightArrowIcon(navelem.TextColor || undefined), [navelem]);
    try {
        const navitems = navelem.NavigationItems;
        const color = navelem.TextColor || DMTheme.palette.primary.contrastText;
        breadcrumbs = <Breadcrumbs
            separator={rightArrow}
            aria-label="breadcrumb"
            sx={{ color: color, paddingBottom: isMobile ? "0" : "32px" }}
        >
            <Link sx={{ color: color }} href={window.location.origin}>{HomeIcon}</Link>
            {navitems.map((c, i) => <Link sx={{ color: color, textDecoration: "none" }} key={`breadcrumb-${i}`} href={c.Url}>{c.Title}</Link>)}
        </Breadcrumbs>;
    } catch (exception: unknown) {
        console.error(exception);
    }
    return navelem && <>
        {breadcrumbs}
        <h1 className="dm_navigation_bar_current">{navelem.Name}</h1>
    </>;
};