/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect } from "react";
import * as api from "../../../api/services/AccountService";
import { format } from "date-fns";
import { ContactsService, ApiError, NewsSubcription } from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";
import { Link, NewsSubscriptionsInfoElement } from "../../../models/umbracoElement";
import LabelValue from "../../_GenericElements/LabelValue/label-value";
import globalStyle from "../../../global.scss";
import { DanishDateFns } from "../../../helpers/DanishDateFns";
import { da } from "date-fns/locale";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";

export type ISubscriptionsProps = {
    data: string
}


export const NewsSubscriptionsInfo = (props: ISubscriptionsProps) => {
    const [data, setdata] = React.useState<NewsSubcription>();
    const [isMember, setisMember] = React.useState<boolean>(true);
    let umbracoSettings: NewsSubscriptionsInfoElement = JSON.parse(props.data);

    useEffect(() => {
        void (async () => {
            try {
                let Data = await ContactsService.getNewsSubscriptions();
                setdata(Data);
            } catch (e) {
                if (e instanceof ApiError) {
                    if (e.status == 500) {
                        console.log("Finder ingen medlemskab");
                        setisMember(false);
                    }
                }
            }
        })();

    }, []);

    if (!isMember) {
        return <div></div>;
    }


    const findNewsSubscriptions = (data: NewsSubcription, umbracoSettings: NewsSubscriptionsInfoElement) => {

        let subscriptedToAllNews = 
            data.eventNewsAtDM &&
            data.newsLettersFromDM &&
            //data.sectorNewsletter &&
            //data.serviceMails &&
            data.bioNewsLetter;
        let subscribedToNoneNews = 
            !data.eventNewsAtDM &&
            !data.newsLettersFromDM &&
            //!data.sectorNewsletter &&
            //!data.serviceMails &&
            !data.bioNewsLetter;
        let texts = [];
        if (subscriptedToAllNews) {
            return umbracoSettings.allNews || "Du er tilmeldt alle DM's nyhedsbreve";
        }
        if (subscribedToNoneNews) {
            return umbracoSettings.noneNews || "Du er ikke tilmeldt nogen af DM's nyhedsbreve";
        }

        if (data.newsLettersFromDM) { texts.push(umbracoSettings.newsLettersFromDM || "Nyhedsbreve fra DM"); }
        if (data.eventNewsAtDM) { texts.push(umbracoSettings.eventNewsatDM || "Events fra DM"); }
        //if (data.sectorNewsletter) { texts.push(umbracoSettings.sectorNewsletter || "Sektor breve fra DM"); }
        //if (data.serviceMails) { texts.push(umbracoSettings.serviceMails || "Servicemails fra DM"); }
        if (data.bioNewsLetter) { texts.push(umbracoSettings.receiveBioNewsletter || "Biosøjlen");        }

        return umbracoSettings.subscribeNews ? umbracoSettings.subscribeNews : "Du er tilmeldt: " + texts.join(", ");
    };
    const findPrintedSubscriptions = (data: NewsSubcription, umbracoSettings: NewsSubscriptionsInfoElement) => {
        let subscriptedToAllPrint =
            data.receiveResearchForumDigitally &&
            data.receiveDMAcademyMagazinePrinted &&
            data.receiveDMAcademyMagazinePrintedStud &&
            data.isBioPillar ? data.receiveMomentumPrinted : false;
        let subscriptedToNonePrint =
            !data.receiveResearchForumDigitally &&
            !data.receiveDMAcademyMagazinePrinted &&
            !data.receiveDMAcademyMagazinePrintedStud &&
            data.isBioPillar ? !data.receiveMomentumPrinted : true;
        let texts = [];
        if (subscriptedToAllPrint) {
            return umbracoSettings.allPrinted || "Du modtager alle blade på print";
        }
        if (subscriptedToNonePrint) {
            return umbracoSettings.nonePrinted || "Du modtager ingen blade på print";
        }

        if (data.receiveResearchForumDigitally) { texts.push(umbracoSettings.receiveResearchForumDigitally || "Forskerforum"); }
        if (data.receiveDMAcademyMagazinePrinted) { texts.push(umbracoSettings.receiveDMAcademyMagazinePrinted || "DM Akademikerbladet"); }
        if (data.receiveDMAcademyMagazinePrintedStud) { texts.push(umbracoSettings.receiveDMAcademyMagazinePrintedStud || "DM Akademikerbladet Studerende"); }
        if (data.receiveMomentumPrinted) { texts.push(umbracoSettings.receiveMomentum || "Momentum"); }

        return umbracoSettings.subscribePrinted ? umbracoSettings.subscribePrinted : "Du modtager på print: " + texts.join(", ");
    };

    if (!data) {
        return <Loader />;
    } else {
        return <div>
            <h2>
                Information fra DM
            </h2>
            <p>{findNewsSubscriptions(data, umbracoSettings)} </p>
            <StandardButton showArrow={true} href={umbracoSettings.nyhedsbreveLink._url}>
                {umbracoSettings.gaTilNyhedsbreve || "Gå til nyhedsbreve"}
            </StandardButton>
            <p>{findPrintedSubscriptions(data, umbracoSettings)}</p>
            <StandardButton showArrow={true} href={umbracoSettings.bladLink._url}>
                {umbracoSettings.gaTilBlade || "Gå til blade"}
            </StandardButton>
        </div>;
    }

};