import daLocale from "date-fns/locale/da";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import {DMThemeProvider} from "./ThemeProvider";


export interface RootProps{
    children: React.ReactNode
}
export const Root = (props : RootProps) => {
    return <LocalizationProvider dateAdapter={AdapterDateFns} locale={daLocale}>
        <DMThemeProvider>
            {props.children}
        </DMThemeProvider>
    </LocalizationProvider>;
};