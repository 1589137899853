
import * as yup from "yup";
import {  Schema } from "yup";

export type ChangeWorkTimeFormModel= {workTime:string, startDate:Date, isPreviousKIMember: boolean}

export type ChangeWorkTimeFormValues = NonNullable<ChangeWorkTimeFormModel>

export const ChangeWorkTimeSchema: Schema<ChangeWorkTimeFormValues> = yup.object({
    isPreviousKIMember: yup.bool().defined("isPreviousKIMember"),

    startDate: yup.date().when("isPreviousKIMember", ([isPreviousKIMember], schema) => {
        if (isPreviousKIMember) {
            return schema
                .required("Udfyld en start dato")
                .min(new Date(2024, 0, 1, 0, 0, 0, 0), "Medlemskabsændringer før d. 1/1-2024, skal ske ved at kontakte Medlemsservice på tlf. 38156600 eller via mail service@dm.dk");
        }

        return schema
            .required("Udfyld en start dato");
    }),

    // startDate: yup.date().required("Udfyld en start dato"),
    workTime: yup.string().required("Vælg Fuldtid/deltid"),
});

