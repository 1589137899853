import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight,faCaretRight, faCaretDown, faEnvelope, faEnvelopeOpenText, faHouseUser, faUpload, faPaperclip, faClock, faCalendar, faWindowClose } from "@fortawesome/free-solid-svg-icons";


export const ColoredRightArrowIcon = (color: string = "#fff") => <svg xmlns="http://www.w3.org/2000/svg" width="26" height="15"
    viewBox="0 0 26 15">
    <g id="Group_27" data-name="Group 27" transform="translate(-425.5 -1594)">
        <line id="Line_1" data-name="Line 1" x2="22"
            transform="translate(425.5 1601.5)" fill="none" stroke={color}
            strokeWidth="3" />
        <path id="Polygon_2" data-name="Polygon 2" d="M7.5,0,15,8H0Z"
            transform="translate(451.5 1594) rotate(90)" fill={color} />
    </g>
</svg>;
export const RightArrowIcon = ColoredRightArrowIcon();

export const CaretDownIcon = <FontAwesomeIcon icon={faCaretDown} />;

export const CaretRightIcon = <FontAwesomeIcon icon={faCaretRight} />;

export const HomeIcon = <FontAwesomeIcon icon={faHouseUser} />;

export const AttachmentIcon = <FontAwesomeIcon icon={faPaperclip} />;

export const UploadIcon = <FontAwesomeIcon icon={faUpload} />;

export const ClockIcon = <FontAwesomeIcon icon={faClock} />;

export const DateIcon = <FontAwesomeIcon icon={faCalendar} />;

export const LetterOpenIcon = <FontAwesomeIcon icon={faEnvelopeOpenText} size={"2x"} />;

export const LetterClosedIcon = <FontAwesomeIcon icon={faEnvelope} size={"2x"} />;

export const DeleteFileIcon = <FontAwesomeIcon icon={faWindowClose} size={"2x"} color={"grey"}/>;