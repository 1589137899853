import { Box, Paper, Stack } from "@mui/material";
import { useState } from "react";
import { CTAWithPictureElement,RichtextElement } from "../../../models/umbracoElement";
import Richtext from "../Richtext/richtext";

export interface CTAWithPictureProps {
    data : string;
}

export const CTAWithPicture = (props: CTAWithPictureProps) => {
    const [elm, _] = useState<CTAWithPictureElement>(JSON.parse(props.data));

    const convertToRichTextProps : (settings: CTAWithPictureElement) => RichtextElement = 
        (settings) => {
            return {
                title: settings.text,
                richTextColor : settings.richTextColor,
                richtext: settings.richtext,
                buttonLabel: settings.buttonLabel,
                linkUrl: settings.linkUrl?.url,
                contentTypeAlias: settings.contentTypeAlias,
                id: settings.id,
            };
        };

    return <Stack sx={{padding:"25px 25px 25px 0"}} direction={elm.picturePlacement == "Venstre" ? "row" : "row-reverse"} alignItems="stretch" >
        <Box sx={{display:"flex", flexDirection:"column", justifyContent: "center", width:"33%"}}>
            <img style={{width:"100%"}} src={elm.picture?._url} alt={elm.pictureAltText}></img>
        </Box>
        <Box sx={{ width:"66%"}}>
            {Richtext(
                {data:JSON.stringify(convertToRichTextProps(elm))}
            )}
        </Box>
    </Stack>;
};