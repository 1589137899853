import { useState, useEffect } from "react";
import { MetadataService } from "../../../api/services/MetadataService";
import { ApiError, CrmEntity, PaymentGroupService, MainEmployment, ContactsService, MembershipService } from "../../../api";
import { Form, Formik } from "formik";
import { ChangeJobPosition } from "../../../api/models/ChangeJobPosition";
import { UmbracoWorkChangeTypes, WorkFormElement } from "../../../models/umbracoElement";
import { ApiErrorBody } from "../../../models/apiError";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import formStyles from "../../_GenericElements/_GenericFormElements/form.module.scss";
import { FormGroup, Stack } from "@mui/material";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import { ConfirmMessageBox } from "../ConfirmMessageBox/ConfirmMessageBox";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { GenericValidations } from "../../_GenericElements/_GenericFormElements/GenericValidations";
import { FullTime, PartTime, profilPageLink } from "../../../constants/common-constants";
import { formatDateDDMMYY } from "../../../helpers/Utils";

export type IChangeToWorkingFormProps = {
    data: string
}

export type IChangeToWorkingFormState = {
    loading: boolean;
}

const WorkingFormLabels: (elementConfig: WorkFormElement) => Record<keyof formValues, string> = (elementConfig) => {
    return {
        "startDate": elementConfig.startDatoLabel || "Start dato",
        "jobPosition": elementConfig.stillingLabel || "Stilling",
        "hasFullTimeEmployment": elementConfig.arbejderFuldtidLabel || "Arbejder fuldtid",
        "newPlaceOfWork": elementConfig.ansaettelsesstedLabel || "Ansættelsessted",
        "employmentType": elementConfig.ansaettelsestypeLabel || "Ansættelsestype",
        "placeOfWorkNotinList": elementConfig.tilfojAnsaettelsesstedLabel || "Tilføj Ansættelsessted",
        "placeOfEmploymentName": elementConfig.virksomhedsnavnLabel || "Virksomhedsnavn",
        "companyAddress": elementConfig.virksomhedsadresseLabel || "Virksomhedsadresse",
        "cvr": elementConfig.cVRLabel || "CVR-nummer",
        "vipTap": elementConfig.vipTapLabel ||"VIP/TAP",
        "workTime": elementConfig.workTimeLabel || "Fuldtid/deltid",
        "isPreviousKIMember": "",
        workArea: elementConfig.arbejdsomradeLabel || "Arbejdsområde",
    };
};
type Nullable<T> = { [K in keyof T]: T[K] | null };
type formValues = Nullable<Omit<MainEmployment, "startDate" | "isFreelancer"| "relatedSector"> & { startDate: Date, workTime: string, isPreviousKIMember: boolean }>
type validatedFormValues = Omit<MainEmployment, "startDate" | "isFreelancer"| "relatedSector"> & { startDate: Date, workTime: string };

export const ChangeToWorkingForm = (props: IChangeToWorkingFormProps) => {
    let parsedData: WorkFormElement = JSON.parse(props.data);

    const [loading, setLoading] = useState(true);
    const [readonly, setReadOnly] = useState(false);
    const [workplaces, setWorkPlaces] = useState<NonNullable<CrmEntity[]>>([]);
    const [workAreas, setWorkAreas] = useState<NonNullable<CrmEntity[]>>([]);
    const [jobPositions, setjobPositions] = useState<NonNullable<CrmEntity[]>>([]);
    const [employmentTypes, setemploymentTypes] = useState<NonNullable<CrmEntity[]>>([]);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [showConfirm, setShowConfirm] = useState(false);
    const [defaultValues, setDefaultValues] = useState<formValues>({
        startDate: new Date(),
        placeOfWorkNotinList: false,
        hasFullTimeEmployment: false,
        placeOfEmploymentName: undefined,
        companyAddress: undefined,
        cvr: undefined,
        jobPosition: {primaryName: "", id:""},
        employmentType: {primaryName: "", id:""},
        newPlaceOfWork: {primaryName: "", id:""},
        workTime: "",
        isPreviousKIMember: false,
        workArea: { primaryName: "", id: "" },
    });
    const [agreementStartDate, setAgreementStartDate] = useState<Date>();

    const [isLoading, setIsLoading] = useState(false);
    const [KIText, setKIText] = useState("Medlemskabsændringer før d. 1/1-2024, skal ske ved at kontakte Medlemsservice på tlf. 38156600 eller via mail service@dm.dk.");

    const GetCurrentInformation = async () => {
        let currentInformation: MainEmployment | undefined = undefined;
        try {
            setIsLoading(true);
            let agreementInformation = await MembershipService.getMembership();
            agreementInformation.startDate && setAgreementStartDate(new Date(agreementInformation.startDate));

            currentInformation = await PaymentGroupService.getCurrentWorkInformation();

        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 422) {
                    console.log("Brugeren var ikke i arbejde");
                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg("Der er sket en fejl. Data er ikke gemt. Prøv venligst igen eller kontakt DM.");
                    console.error(errorInfo);
                    setReadOnly(true);
                }
            }
        } finally {
            let isPreviousKIMember = await getIsPreviousKIMember();

            let newDefaults: formValues = currentInformation != undefined ?
                {
                    startDate: new Date(),
                    workTime: currentInformation.hasFullTimeEmployment ? FullTime : PartTime,
                    placeOfWorkNotinList: currentInformation.placeOfWorkNotinList ?? false,
                    hasFullTimeEmployment: currentInformation.hasFullTimeEmployment ?? false,
                    placeOfEmploymentName: currentInformation.placeOfEmploymentName ?? undefined,
                    companyAddress: currentInformation.companyAddress ?? undefined,
                    cvr: currentInformation.cvr ?? undefined,
                    jobPosition: currentInformation.jobPosition ?? {primaryName: "", id:""},
                    employmentType: currentInformation.employmentType ?? {primaryName: "", id:""},
                    newPlaceOfWork: currentInformation.newPlaceOfWork ?? {primaryName: "", id:""},
                    isPreviousKIMember: isPreviousKIMember,
                    workArea: currentInformation.workArea ?? {primaryName: "", id: ""},
                } : {
                    ...defaultValues, isPreviousKIMember: isPreviousKIMember,
                };
            setDefaultValues(newDefaults);
            setIsLoading(false);
        }
    };

    const getIsPreviousKIMember = async () => {
        try {
            return await ContactsService.getIsPreviousKiMember();
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    const GetOptions = async () => {
        void await Promise.all(
            [
                MetadataService.getAllWorkPlaces().then(wp => setWorkPlaces(wp)),
                MetadataService.getAllJobPositions().then(jp => setjobPositions(jp)),
                MetadataService.getAllEmploymentTypes().then(et => setemploymentTypes(et)),
                MetadataService.getAllWorkAreas().then(wa => setWorkAreas(wa)),
            ]
        );
        setLoading(false);
    };

    const changeJobPosition = async (formData: validatedFormValues) => {
        let jobPositionData: ChangeJobPosition = {
            ...formData,
            startDate: formData.startDate.toISOString(),
        };
        try {
            await PaymentGroupService.changeJobPosition(jobPositionData);
            alert("Tak for din indmeldelse, din stilling vil automatisk blive skiftet");
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 422) {
                    setErrorMsg("Det ønskede skifte er ikke muligt fra din nuværende kontingent gruppe");
                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg(errorInfo.title);
                }
            }
        }
    };

    const changePaymentGroup = async (formData: validatedFormValues) => {
        let workingData: MainEmployment = {
            ...formData,
            hasFullTimeEmployment: formData.workTime === FullTime,
            isFreelancer: false,// there is a component to switch to freelance. This cannot be true here.
            newPlaceOfWork: formData.newPlaceOfWork?.id ? formData.newPlaceOfWork : undefined,
            startDate: formData.startDate.toISOString(),
        };
        try {
            void await PaymentGroupService.setToWorking(workingData);
            setShowConfirm(true);
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 422) {
                    setErrorMsg("Det ønskede skifte er ikke muligt fra din nuværende kontingent gruppe");
                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg(errorInfo.title);
                }
            }
        }
    };

    const jobPositionChangeValidation = (formData : formValues) => {
        const Validations = new GenericValidations<formValues>(WorkingFormLabels(parsedData), formData);
        const requiredFields: (keyof formValues)[] = [
            "startDate",
            "hasFullTimeEmployment",
            "jobPosition",
        ];
        requiredFields.forEach(rf=>Validations.required(rf));

        if (defaultValues.isPreviousKIMember && formData.startDate != null && formData.startDate < new Date(2024, 0, 1, 0, 0 ,0 ,0)) {
            Validations.errors.startDate =  KIText;
        }

        if (formData.startDate != null && agreementStartDate && formData.startDate < agreementStartDate) {
            let dateFormatted = formatDateDDMMYY(agreementStartDate);
            Validations.errors.startDate = `Du kan registrere ændringer fra den dato, du blev medlem af DM: ${dateFormatted}`;
        }

        return { validatedValues: formData as validatedFormValues, errors: Validations.errors };
    };

    const validateForm = (formData : formValues) => {
        const Validations = new GenericValidations<formValues>(WorkingFormLabels(parsedData), formData);
        const requiredFields: (keyof formValues)[] = [
            "startDate",
            "hasFullTimeEmployment",
            "placeOfWorkNotinList",
            "employmentType",
            "jobPosition",
        ];
        if (formData.placeOfWorkNotinList) {
            requiredFields.push("cvr", "companyAddress", "placeOfEmploymentName");
            Validations.GenericTextValidations.exactLength("cvr", 8);
        } else {
            requiredFields.push("newPlaceOfWork");
        }

        requiredFields.forEach(rf=>Validations.required(rf));

        if (defaultValues.isPreviousKIMember && formData.startDate != null && formData.startDate < new Date(2024, 0, 1, 0, 0 ,0 ,0)) {
            Validations.errors.startDate =  KIText;
        }

        if (formData.startDate != null && agreementStartDate && formData.startDate < agreementStartDate) {
            let dateFormatted = formatDateDDMMYY(agreementStartDate);
            Validations.errors.startDate = `Du kan registrere ændringer fra den dato, du blev medlem af DM: ${dateFormatted}`;
        }

        return { validatedValues: formData as validatedFormValues, errors: Validations.errors };
    };

    useEffect(() => {
        void GetCurrentInformation();
        void GetOptions();
    }, []);

    const FormElements = new GenericFormElements<formValues>(WorkingFormLabels(parsedData));
    const worktimeDropdownOptions = [FullTime, PartTime];

    const workingtimeDropdown = FormElements.StringDropDown("workTime", worktimeDropdownOptions, undefined);

    const placeOfWorkNotInListCheckbox = <FormElements.BasicYesNoSwitch formValueName={"placeOfWorkNotinList"}></FormElements.BasicYesNoSwitch>;
    let dateField = <FormElements.BasicDateInput formValueName="startDate" />;

    const AdditionalRequiredInfoFields = (values: formValues) => {
        if (values.placeOfWorkNotinList) {
            return <>
                <FormElements.BasicTextInput formValueName="placeOfEmploymentName"  />
                <FormElements.BasicTextInput formValueName="companyAddress" />
                <FormElements.BasicTextInput formValueName="cvr" />
            </>;
        } else if (!changingOnlyJobPosition) {
            return <FormElements.RecordAutoComplete formValueName="newPlaceOfWork" options={workplaces} loading={workplaces.length == 0 && loading}
            />;
        } else {
            return;
        }
    };
    const onCloseConfirmMessage=()=>{
        setShowConfirm(false);
        window.location.href=profilPageLink;
    };
    let changingOnlyJobPosition = parsedData.workChangeType == UmbracoWorkChangeTypes.NewPosition;

    let form = <Formik
        initialValues={defaultValues}
        // validationSchema={changingOnlyJobPosition ? JobPositionChangeSchema : workingSchema}
        onSubmit={async (values, { setSubmitting }) => {
            let { validatedValues, errors } = validateForm(values);
            if (changingOnlyJobPosition) {
                await changeJobPosition(validatedValues);
            } else {
                await changePaymentGroup(validatedValues);
            }
            setSubmitting(false);
        }}
        enableReinitialize={true}
        validate={(values) => changingOnlyJobPosition ? jobPositionChangeValidation(values).errors : validateForm(values).errors}
    >
        {({ values,
            isSubmitting,
            isValid,
            errors,
            touched,
            handleSubmit }) =>
            <Form className={formStyles.dmForm} onSubmit={handleSubmit}>
                {dateField}
                {workingtimeDropdown}
                <FormGroup>
                    {!changingOnlyJobPosition && placeOfWorkNotInListCheckbox}
                </FormGroup>

                {AdditionalRequiredInfoFields(values)}
                {!changingOnlyJobPosition && FormElements.RecordDropDown("employmentType", employmentTypes, employmentTypes.length == 0 && loading, false, isSubmitting)}
                {FormElements.RecordDropDown("jobPosition", jobPositions, jobPositions.length == 0 && loading, false, isSubmitting)}
                {FormElements.RecordDropDown("workArea", workAreas, workAreas.length == 0 && loading, false, isSubmitting)}
                <FormElements.SubmitButton loading={isSubmitting} type="submit" disabled={readonly || isSubmitting || isLoading} />
                {values.startDate && showConfirm && <ConfirmMessageBox fromDate={values.startDate} onClose={onCloseConfirmMessage} />}
                <MessageDiv type={MessageType.error} msg={errorMsg} />
            </Form>
        }
    </Formik>;
    return <Stack direction="column" justifyItems="flex-start" alignItems="grow">
        <h2>{parsedData.workChangeType}</h2>
        {parsedData.contentText && SantizedRichHtml(parsedData.contentText)}
        {form}
    </Stack>;
};