import { TableProps } from "@mui/material";
import style from "./LabelValueTable.module.scss";
export interface LabelValueTableProps extends TableProps {
    customClassName?:string
}

export const LabelValueTable: React.FC<LabelValueTableProps> = (props) => {
    const { customClassName, ...rest } = props;
    return <table className={`${style.labelvalutable} ${customClassName||""}`} {...rest}>
        {props.children}
    </table>;
};