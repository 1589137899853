import React from "react";
import { ApiError, AttachmentFileName, EventMaterial, Message } from "../../../api";
import DMTable, { DMTabelData, DMTableColumn } from "../../_GenericElements/DMTable/table";
import { format } from "date-fns";
import { defaultStyles, FileIcon } from "react-file-icon";
import tablestyles from "../../_GenericElements/DMTable/table.module.scss";
import style from "./event-overview.module.scss";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { DanishDateFns } from "../../../helpers/DanishDateFns";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";

interface EventOverviewFileTableProps {
    materialList?: EventMaterial[] | null;
    title?: string;
}

export const EventOverviewFileTable = (props: EventOverviewFileTableProps) => {
    // const [materials, setMaterials] = React.useState<EventMaterial[]>([]);
    let materials = props.materialList ?? [];
    const [errorMsg, setErrorMsg] = React.useState("");
    const downloadFile = async (file: AttachmentFileName) => {
        try {
            const fileId = file.id ?? "";
            if (fileId) {
                const url = `/api/v1/events/materials/${fileId}`;

                const rawResponse = await fetch(url, {
                    method: "GET",

                });
                const result = await rawResponse.blob();
                const blobUrl = URL.createObjectURL(result);
                let anchor = document.createElement("a");
                anchor.download = file.fileName || "";
                anchor.href = blobUrl;
                anchor.click();
            } else {
                setErrorMsg("Der er sket en fejl ved at downloade fil. - Id på filen mangler.");
            }

        } catch (e) {
            if (e instanceof ApiError) {
                const x = 1;
                console.error(e);
                setErrorMsg("Der er sket en fejl ved at downloade fil. ");
            }
        }
    };

    const clickableFile = (file: AttachmentFileName | null) => {
        if (!file) { return <div></div>; }

        let names = file.fileName.split(".");
        let ext: string = names.pop() || "ukendt";
        return <div key={file.fileName} title={file.fileName} className={tablestyles.fileitem}>
            <a href="#" key={file.fileName} onClick={() => downloadFile(file)}><FileIcon extension={ext} {...defaultStyles} /><span>{names[0]}</span></a>
        </div>;
    };

    /*
    export type EventMaterial = {
        id?: string;
        created?: string | null;
        subject?: string | null;
        note?: string | null;
        fileName?: string | null;
        mimeType?: string | null;
    }
    */

    const columns: DMTableColumn[] = [
        {
            id: "subject",
            label: "Emne",
            minWidth: 100,
            mobile: true,
        },
        {
            id: "note",
            label: "Note",
            minWidth: 400,
        },
        {
            id: "created",
            label: "Tid",
            minWidth: 100,
        },
        {
            id: "file",
            label: "Dokument",
            minWidth: 100,
            mobile: true,
        },
    ];


    function createData(materialId: string, subject: string, note: string, created: string, filename: string | null): DMTabelData {
        return {
            id: materialId,
            values: [
                { id: "subject", value: subject },
                { id: "note", value: SantizedRichHtml(note) },
                { id: "created", value: created },
                { id: "file", value: clickableFile(filename ? { id: materialId, fileName: filename } : null) },
            ],
        };
    }

    const rows = (materials?.length ?? 0) == 0 ?
        [] :
        materials
            .filter((e): e is Required<EventMaterial> => e.subject != undefined)
            .map((e) => createData(
                e.id,
                e.subject ?? "",
                e.note ?? "",
                e.created ? DanishDateFns.format(new Date(Date.parse(e.created)), "dd.MM.yyyy HH:mm") : "",
                e.fileName || null
            ));

    return (
        <div className={style.materialdiv}>{rows.length > 0 && <>
            <h2>{props.title || ""}</h2>
            <div> <DMTable columns={columns} data={rows} /> </div>
        </>}
        <MessageDiv type={MessageType.error} msg={errorMsg} />
        </div>
    );
};