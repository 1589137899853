import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import style from "./table.module.scss";
import { Collapse, IconButton, ThemeProvider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DateIcon, ClockIcon } from "../Icons/Icons";
import { DanishDateFns } from "../../../helpers/DanishDateFns";
import Loader from "../Loader/loader";
import { isMobile } from "../../../constants/common-constants";

export interface DMTableColumn {
    id: string;
    label: string;
    minWidth?: number | string;
    maxWidth?: number | string;
    width?: number | string;
    align?: "right";
    hasCollapsibleContents?: boolean;
    format?: (value: number | string | boolean | JSX.Element) => string | JSX.Element;
    mobile?: boolean;
}

export interface DMTabelData {
    id: string;
    values: DMTableDataValue[];
    isBold?: boolean;
    collapsed?: boolean;
}

export interface DMTableDataValue {
    id: string;
    value: string | number | boolean | JSX.Element;
}

function getDataByColumn(id: string, row: DMTabelData) {
    return row.values
        .find(x => x.id == id)?.value;
}

export interface DMTableRowProps {
    columns: DMTableColumn[];
    row: DMTabelData;
    onClick?: (value: string) => void;
}

export function DMTableRow(props: DMTableRowProps) {
    const [collapsed, setCollapsed] = React.useState(true);
    const row = props.row;

    let useCollapseColumn: boolean = false;
    props.columns.map((column) => { useCollapseColumn = useCollapseColumn || (column.hasCollapsibleContents ?? false); });

    return <TableRow
        className={`${(useCollapseColumn ? style.collapsible : "")} ${(collapsed ? "" : style.show)} ${(props?.onClick ? style.clickable : "")}`}
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        onClick={() => props?.onClick ? props.onClick(row.id) : null}>{(useCollapseColumn ?
            <TableCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setCollapsed(!collapsed)}
                >
                    {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </IconButton>
            </TableCell> : null)}
        {props.columns.map((column, columnIdx) => {
            let styles: React.CSSProperties = {};
            if (column.minWidth) { styles["minWidth"] = column.minWidth; }
            if (column.maxWidth) { styles["maxWidth"] = column.maxWidth; }
            if (column.width) { styles["width"] = column.width; }

            const value = getDataByColumn(column.id, row);
            let cellContent = <div className={row.isBold ? style.dmtablecellBold : style.dmtablecell}>
                {column.format && typeof (value) != undefined ?
                    column.format(value!) :
                    value}</div>;

            if (props.columns[columnIdx].hasCollapsibleContents ?? false) {
                cellContent = <div className={style.collapsed}>
                    {cellContent}
                </div>;
            }

            return (

                <TableCell key={column.id} align={column.align} style={styles}
                    className={(props.columns[columnIdx].hasCollapsibleContents ?? false) ? style.collapsible : ""}>
                    {cellContent}
                </TableCell>
            );
        })}
    </TableRow>;
}

export interface DMTableProps {
    data: DMTabelData[];
    columns: DMTableColumn[];
    onClick?: (value: string) => void;
    initialRowsPrPage?: number;
}

export const DMTable = (props: DMTableProps) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    React.useEffect(() => {
        if (props.initialRowsPrPage) {
            setRowsPerPage(props.initialRowsPrPage);
        }
    }, []);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    let columns = props.columns;
    if (isMobile()) {
        columns = columns.filter(x => x.mobile);
        columns.forEach(x => x.minWidth = undefined);

    }
    let useCollapseColumn: boolean = false;
    props.columns.map((column) => { useCollapseColumn = useCollapseColumn || (column.hasCollapsibleContents ?? false); });

    return (
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none", borderRadius: "none" }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table" className={style.root}
                    size="small" >
                    <TableHead>
                        <TableRow>{useCollapseColumn ? <TableCell /> : null}
                            {columns.map((column) => {
                                let styles: React.CSSProperties = { fontWeight: "bold", fontSize: "larger" };
                                if (column.minWidth) { styles["minWidth"] = column.minWidth; }
                                if (column.maxWidth) { styles["maxWidth"] = column.maxWidth; }
                                if (column.width) { styles["width"] = column.width; }
                                return (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={styles}>
                                        {column.label}
                                    </TableCell>
                                );
                            }
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                let open = false;
                                return <DMTableRow key={row.id} row={row} columns={columns}
                                    onClick={props.onClick} />;
                            })}
                    </TableBody>
                </Table>
            </TableContainer>{props.data.length < rowsPerPage ? null :
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={props.data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />}
        </Paper>

    );
};

export enum OnEmpty {
    ShowAll,
    HideTable,
    HideTableAndTitle
}

interface TitleProps {
    elementType?: keyof JSX.IntrinsicElements;
    title?: React.ReactNode | string;
}

const TitleTag = ({ title, elementType: ElementType = "h2" }: TitleProps) => {
    return <ElementType>{title}</ElementType>;
};

export interface DMTableWrapperProps extends DMTableProps {
    title?: string;
    titleHTag?: keyof JSX.IntrinsicElements;
    emptyTableTitle?: string;
    loading?: boolean;
    onEmpty?: OnEmpty;
}


export const DMTableWrapper = (props: DMTableWrapperProps) => {
    switch (props.onEmpty) {
        case OnEmpty.HideTableAndTitle:
            return props.loading ? <Loader /> : ((props.data?.length ?? 0) == 0 ? null : <>
                <TitleTag elementType={props.titleHTag} title={props.title} />
                <DMTable columns={props.columns} data={props.data} onClick={props.onClick}
                    initialRowsPrPage={props.initialRowsPrPage} />
            </>);
        case OnEmpty.HideTable:
            return <>
                <TitleTag elementType={props.titleHTag} title={props.title} />
                {props.loading ? <Loader /> : ((props.data?.length ?? 0) == 0 ? null : <DMTable columns={props.columns} data={props.data} onClick={props.onClick}
                    initialRowsPrPage={props.initialRowsPrPage} />)}
            </>;
        case OnEmpty.ShowAll:
        default:
            return <>
                <TitleTag elementType={props.titleHTag} title={props.title} />
                {props.loading ? <Loader /> : ((props.data?.length ?? 0) > 0) ? <DMTable columns={props.columns} data={props.data} onClick={props.onClick}
                    initialRowsPrPage={props.initialRowsPrPage} /> : <p>{props.emptyTableTitle ?? "Listen er tom."}</p>}
            </>;
    }
};

export const formatDateTime = (datetime?: Date | null) => {
    return (datetime ?
        <div className={style.datetimecell}>
            <span className={style.date}>{DateIcon} {DanishDateFns.format(datetime, "dd.MM.yy")}</span><br />
            <span className={style.time}>{ClockIcon} {DanishDateFns.format(datetime, "HH:mm")}</span>
        </div> : "");
};

export default DMTable;