import { ChangeJobPosition, CrmEntity } from "../../../api";
import { WorkDetailsUpdate } from "../../../api/models/WorkDetailsUpdate";
import * as yup from "yup";
import { Schema } from "yup";
import { CRMEntitySchema } from "../../_GenericElements/_GenericFormElements/GenericFormSchema";


export type WorkDetailsFormValues = NonNullable<Omit<WorkDetailsUpdate, "startDate"> & { startDate: Date | null }>
export type JobPositionChangeFormValues = NonNullable<Omit<ChangeJobPosition, "startDate"> & { startDate: Date }>
export type NewJobFormCrmOptions = Pick<WorkDetailsFormValues, "jobPosition">

export const JobPositionChangeSchema: Schema<JobPositionChangeFormValues> = yup.object({
    startDate: yup.date().required("Udfyld en start dato"),
    hasFullTimeEmployment: yup.bool().defined("hasFullTimeEmployment"),
    jobPosition: CRMEntitySchema.required("Vælg en stilling"),
});

export const workDetailsSchema: Schema<WorkDetailsFormValues> = yup.object({
    startDate: yup.date()
        .nullable()
        .typeError("Udfyld en dato")
        .required("Udfyld en dato"),
    placeOfWorkNotinList: yup.boolean().optional(),
    workArea: yup
        .object({
            id: yup.string().required("Vælg et arbejdsområde"),
            primaryName: yup.string().required("Vælg et arbejdsområde"),
        })
        .required("Vælg et arbejdsområde"),
    placeOfWork: CRMEntitySchema.transform((c: CrmEntity) => c.id && c.primaryName ? c : undefined)
        .optional(),
    employmentType: CRMEntitySchema.transform((c: CrmEntity) => c.id && c.primaryName ? c : undefined)
        .optional(),
    jobPosition: yup
        .object({
            id: yup.string().required("Vælg en stilling"),
            primaryName: yup.string().required("Vælg en stilling"),
        })
        .required("Vælg en stilling"),
    placeOfEmploymentName: yup.string()
        .when(["placeOfWork", "placeOfWorkNotinList"],
            {
                is: (placeOfWork: CrmEntity, placeOfWorkNotinList: boolean) => !placeOfWork.id || placeOfWorkNotinList,
                then: (schema) => schema
                    .required("Mangler navn på ny arbejdsplads")
                    .min(1, "Mangler navn på ny arbejdsplads"),
                otherwise: (schema) => schema.nullable()
                    .notRequired(),
            }),
    companyAddress: yup.string()
        .when(["placeOfWork", "placeOfWorkNotinList"],
            {
                is: (placeOfWork: CrmEntity, placeOfWorkNotinList: boolean) => !placeOfWork.id || placeOfWorkNotinList,
                then: (schema) => schema
                    .required("Mangler adresse på ny arbejdsplads"),
                otherwise: (schema) => schema.nullable()
                    .notRequired(),
            }),
    cvr: yup.string()
        .when(["placeOfWork", "placeOfWorkNotinList"],
            {
                is: (placeOfWork: CrmEntity, placeOfWorkNotinList: boolean) => !placeOfWork.id || placeOfWorkNotinList,
                then: (schema) => schema
                    .required("Mangler cvr nummer på ny arbejdsplads")
                    .length(8, "CVR nummer skal være 8 cifre"),
                otherwise: (schema) => schema.nullable()
                    .notRequired(),
            }),
});