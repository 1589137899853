import * as yup from "yup";
import { Schema } from "yup";
import { AgreementGroup } from "../../../api/models/AgreementGroup";
import { Available } from "../../../api/models/Available";

export type AvailableFormValues = NonNullable<Omit<Available, "startDate"> & { startDate: Date, isPreviousKIMember: boolean }>

export const AvailableSchema: Schema<AvailableFormValues> = yup.object({
    isPreviousKIMember: yup.bool().defined("isPreviousKIMember"),

    startDate: yup.date().when("isPreviousKIMember", ([isPreviousKIMember], schema) => {
        if (isPreviousKIMember) {
            return schema
                .required("Udfyld en start dato")
                .min(new Date(2024, 0, 1, 0, 0, 0, 0), "Medlemskabsændringer før d. 1/1-2024, skal ske ved at kontakte Medlemsservice på tlf. 38156600 eller via mail service@dm.dk.");
        }

        return schema
            .required("Udfyld en start dato");
    }),


    availableType: yup.object({
        priceGroup: yup.string()
            .defined("Mangler type")
            .required("Mangler type"),
        displayName: yup.string()
            .defined("Mangler type")
            .required("Mangler type"),
        organisation: yup.mixed<AgreementGroup.organisation>()
            .defined("Mangler type")
            .required("Mangler type")
            .oneOf(Object.values(AgreementGroup.organisation)),
        agreementGroupTypes: yup.array()
            .defined("Mangler type")
            .required("Mangler type"),
        availableForUpdate: yup.bool()
            .defined("Mangler type")
            .required("Mangler type"),
    }),
});