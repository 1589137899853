/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/unbound-method */
import { useEffect, useMemo, useState } from "react";
import { RepresentativeService, ApiError, RepresentativeType, ContactsService, ContactRead, CrmEntity, MetadataService, VIPorTAP } from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";
import styles from "./representative-submission.module.scss";
import { RepresentativeBaseList, RepresentativeListType } from "../RepresentativeList/representative-base-list";
import { ContactStep } from "./steps/contact-step";
import { OrganizationStep, OrganizationStepProps } from "./steps/organization-step";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import React from "react";
import { RepresentativeSubmissionElement } from "../../../models/umbracoElement";
import { EventContactInformation } from "../../Event/EventOverview/event-contact-information";
type RepresentativeSubmissionFormProps = {
    data: string,
    id: string,
    onCancel?: () => void,
    shouldReload?: boolean
}
export type RepresentativeContactStepData = {
    workplaceList: CrmEntity[],
    typeList: RepresentativeType[],
    viptapTypes: VIPorTAP[],
    employmentTypes: CrmEntity[],
    jobpositions: CrmEntity[],
    contact: ContactRead,
}
export const RepresentativeSubmissionForm = (props: RepresentativeSubmissionFormProps) => {

    const [contactStepData, setContactStepData] = useState<RepresentativeContactStepData>({ employmentTypes: [], viptapTypes: [], jobpositions: [], workplaceList: [], typeList: [], contact: {} as ContactRead });
    const [allowedAreas, setAllowedAreas] = useState<OrganizationStepProps>();
    const [errorMessage, setErrorMessage] = useState("");
    const [viptap, setViptap] = useState(false);
    const [loading, setLoading] = useState(true);
    const [umbracoSettings] = React.useState<RepresentativeSubmissionElement>(JSON.parse(props.data));
    const goToOrganizationChoiseStep = (submissionId: string, areas: CrmEntity[], hideOrganization: boolean) => {
        setAllowedAreas({
            contactId: props.id,
            submissionId: submissionId,
            areas: areas,
            hideOrganization: hideOrganization,
            reloadAfterSubmit: props.shouldReload ?? false,
            cancelBtn: cancelButton,
            onPreviousClick: () => {
              setAllowedAreas(undefined);
            }
        });
    };

    useEffect(() => {
        (async () => {
            try {
                const contact = await ContactsService.getContact();
                let types: RepresentativeType[] = [];
                let jobs: CrmEntity[] = [];
                let employmentTypes: CrmEntity[] = [];
                let viptapTypes: VIPorTAP[] = [];
                let jobpositions: CrmEntity[] = [];
                if (!contact?.mainEmployment) {
                    setErrorMessage("Der mangler primær arbejdsplads. Tilføj din primær arbejdsplads.");
                } else {
                    const promises: Promise<any>[] = [];
                    promises.push(RepresentativeService.getRepresentativeTypes().then(rt => types = rt));
                    promises.push(RepresentativeService.getVipTapTypes().then(vt => viptapTypes = vt));
                    promises.push(ContactsService.isViPorTap().then(vt => setViptap(vt)));
                    await Promise.all(promises)
                        .then(() => {
                            viptapTypes[2].name = "VIP og TAP";
                            setContactStepData({ viptapTypes: viptapTypes, jobpositions: jobpositions, employmentTypes: employmentTypes, workplaceList: jobs, typeList: types, contact: contact });
                        })
                        .catch((err) => {
                            setErrorMessage("Der er sket en fejl. Data kunne ikke hentes.");
                            console.error(err);
                        })
                        .finally(() => setLoading(false));
                }

            } catch (err) {
                setErrorMessage("Der er sket en fejl. Data kunne ikke hentes.");
                setLoading(false);
                console.error(err);
            }
        })();
    }, []);
    const cancelButton = useMemo(() => props.onCancel ? <StandardButton onClick={props.onCancel} showArrow={false} >Annuller</StandardButton> : null, [props.onCancel]);
    return (
        <div className={styles.dmForm}>
            <h1>Anmeld hverv - trin {allowedAreas ? "2" : "1"} af 2</h1>
            {loading ? <Loader /> :
                <>
                    <div>
                        <EventContactInformation></EventContactInformation>
                        {allowedAreas && <h2>Kontaktoplysninger</h2> }
                        <ContactStep
                            umbracosettings={umbracoSettings}
                            cancelBtn={cancelButton}
                            setErrorMessage={setErrorMessage}
                            setAllowedAreas={goToOrganizationChoiseStep}
                            contactid={props.id} data={contactStepData}
                            isviptap = {viptap}
                            disabled = {!!allowedAreas}/>
                    </div>
                    {allowedAreas && <h2>Organisationsoplysninger</h2> }
                    {allowedAreas && <OrganizationStep {...allowedAreas} />}
                </>
            }
            <MessageDiv type={MessageType.error} msg={errorMessage} />
        </div >


    );
};
