import React from "react";
import style from "./EndMembership.module.scss";
import { ApiError, CrmEntity, EndMembership, EndReason, MembershipService, MetadataService} from "../../../api";
import { Form, Formik } from "formik";
import { EndMembershipFormLabels } from "./EndMembershipFormLabels";
import { EndMembershipFormSchema, EndMembershipSchemaType } from "./EndMembershipFormSchema";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import { ApiErrorBody } from "../../../models/apiError";
import globalStyle from "../../../global.scss";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";


export type IEndMembershipFormProps = {
    data: string
}
export type IEndMembershipFormState = {
    loading: boolean;
}

export const EndMembershipForm = (props: IEndMembershipFormProps) => {
    const [errorMsg, setErrorMsg] = React.useState<string>("");
    const [termiantionReasons, setTerminationReasons] = React.useState<NonNullable<EndReason[]>>([]);
    const [loading, setLoading] = React.useState(true);
    const [hasExistingTermination, setHasExistingTermination] = React.useState(false);

    const endMembership= async (formData: Partial<EndMembershipSchemaType>) => {
        let endMembershipData: Partial<EndMembership> = {
            ...formData,
            date: formData.date ? formData.date.toISOString() : undefined,
        };
        try {
            await MembershipService.endMembership(endMembershipData as EndMembership);
            location.reload(); 
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 400) {
                    let errorInfo : ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg(errorInfo.title);
                }
            }
        }
    };

    const GetPreviousTermination = async () => {
        await MembershipService.getEndMembershipInfo().then(previousTerm => {
            if (previousTerm && previousTerm.createdOn && (previousTerm.leaveDate && new Date(previousTerm.leaveDate) > new Date())) {
              setHasExistingTermination(true);
            }
        });
    }

    const GetOptions = async () => {
        void await Promise.all(
            [
                MetadataService.getAllTerminationReason().then(tr => setTerminationReasons(tr)),
            ]
        );
        setLoading(false);
    };

    React.useEffect(() => {
        void GetPreviousTermination();
        void GetOptions();
    }, []);

    let form = <Formik
        initialValues={{
            reason: undefined,
            date: new Date(),
        } as Partial<EndMembershipSchemaType> }
        validationSchema={EndMembershipFormSchema}
        onSubmit={async (values :Partial<EndMembershipSchemaType>, { setSubmitting }) => {
            await endMembership(values);
            setSubmitting(false);
        }}
    >
        {({ handleSubmit,
            isSubmitting,
            values,            
        }) => {
            const formElements = new GenericFormElements(EndMembershipFormLabels);

            let reasonDropDown = <formElements.RecordDropDownFullValue<EndReason>
                disabled={hasExistingTermination} formValueName="reason" options={termiantionReasons} loading={loading} />;
            let optionalDate = <formElements.BasicDateInput formValueName="date" />;
            return (
                <Form onSubmit={handleSubmit} >
                    {reasonDropDown}
                    {values.reason?.includeDate ? optionalDate : ""}
                <formElements.SubmitButton className={style.submitBtn} loading={isSubmitting} type="submit"
                    disabled={isSubmitting || hasExistingTermination || loading}>{hasExistingTermination && "Under behandling"}</formElements.SubmitButton>                   
                    <MessageDiv type={MessageType.error} msg={errorMsg} />
                </Form>
            );
        }
        }
    </Formik>;
    return <div>
        <h1>Udmeldelse</h1>
        {form}
    </div>;
};