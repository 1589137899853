/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/unbound-method */
import { useEffect, useState } from "react";
import { RepresentativeService, ApiError, RepresentativeType, ContactsService, ContactRead, CrmEntity, MetadataService } from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";
import styles from "./representative-list.module.scss";
import { RepresentativeBaseList, RepresentativeListType } from "./representative-base-list";
import { RepresentativeSubmissionForm } from "../RepresentativeSubmissionForm/representative-submission-form";
import { Button } from "@mui/material";
import { IncidentList } from "../../Incidents/incident-list";
import { RepresentativeElement, UmbracoIncidentsFilterType } from "../../../models/umbracoElement";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { isMobile } from "../../../helpers/MobileHelper";
type RepresentativeListProps = {
    id: string,
    data: string,
    trid?:string
}
export type RepresentativeContactStepData = {
    networkList: CrmEntity[],
    workplaceList: CrmEntity[],
    typeList: RepresentativeType[],
    employmentTypes: CrmEntity[],
    jobpositions: CrmEntity[],
    contact: ContactRead,

}
export const RepresentativeList = (props: RepresentativeListProps) => {
    const [umbracoSettings] = useState<RepresentativeElement>(JSON.parse(props.data || "{}"));
    const createlink = () => {
        return `https://${window.location.host}/${umbracoSettings.linkToForm.url}`;
    };
    return (<div>
        {<div className={styles.listdiv}>
            <RepresentativeBaseList listtype={RepresentativeListType.Representative} />
            <div className={styles.registerbtndiv}>
          <StandardButton showArrow={true} href={umbracoSettings.linkToForm.url}>Anmeld hverv</StandardButton>
            </div></div>}
        {<div className={styles.listdiv}>
            <RepresentativeBaseList listtype={RepresentativeListType.Submission} ></RepresentativeBaseList>
        </div>
        }
    </div >
    );
};
