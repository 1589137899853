/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EndMembershipInfo = {
    memberStatus?: EndMembershipInfo.memberStatus | null;
    leaveReason?: string | null;
    leaveDate?: string | null;
    createdOn?: string | null;
}

export namespace EndMembershipInfo {

    export enum memberStatus {
        IKKEMEDLEM = 'Ikkemedlem',
        UNDEROPRETTELSE = 'Underoprettelse',
        MEDLEM = 'Medlem',
        TIDLIGEREMEDLEM = 'Tidligeremedlem',
        UNDERUDMELDELSE = 'Underudmeldelse',
    }


}