/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from "react";
import { ApiError, AttachmentFileName, Incident, IncidentsService, Message } from "../../api";
import Loader from "../_GenericElements/Loader/loader";
import DMTable, { DMTabelData, DMTableColumn, formatDateTime } from "../_GenericElements/DMTable/table";
import { dateDifference } from "./incident-list";
import { SantizedRichHtml } from "../_GenericElements/Richtext/richtext";
import { MessageForm } from "./message-form";
import { style } from "@mui/system";
import { UmbracoIncidentOverview } from "../../models/umbracoElement";

import { clickableFileList } from "./file-list";
import globalStyle from "../../global.scss";
import { DanishDateFns } from "../../helpers/DanishDateFns";
import { MessageDiv, MessageType } from "../_GenericElements/MessageDiv/MessageDiv";

interface IncidentOverviewProps {
    data: string;
    incidentnumber: string;
    name: string;
}

export const IncidentOverview = (props: IncidentOverviewProps) => {
    let umbracoSettings: UmbracoIncidentOverview = JSON.parse(props.data);
    const [loading, setLoading] = React.useState(true);
    const [incident, setIncident] = React.useState<Incident>();
    const [messages, setMessages] = React.useState<NonNullable<Message[]>>([]);
    const [files, setFiles] = React.useState<File[]>([]);
    const [errorMsg, setErrorMsg] = React.useState<string>();
    let incidentnumber = props.incidentnumber;

    React.useEffect(() => {
        void GetData();
    }, []);

    const GetData = async () => {
        await Promise.all([
            GetMessages(),
            GetIncidentInfo(),
        ]);
        setLoading(false);
    };

    const deleteFile = (fileName: string) => {
        const newFiles = files.filter(file => file.name !== fileName);
        setFiles(newFiles);
    };

    const clearFiles = () => {
        setFiles([]);
    };

    const addFiles = (newFiles: FileList) => {
        if (!newFiles) {
            return;
        }
        const length = newFiles.length;
        for (let i = 0; i < length; i++) {
            setFiles(files.concat(newFiles[i]));
        }
    };

    const addMessage = (createdMessage: Message) => {
        messages.unshift(createdMessage);
        setMessages(messages);
    };

    const GetMessages = async () => {
        try {
            let messages = await IncidentsService.getAllMessagesForIncident(umbracoSettings.incidentType, incidentnumber);
            messages.sort((a, b) => dateDifference(a.sentOn, b.sentOn));
            setMessages(messages);
            setLoading(false);
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 401) {
                    setErrorMsg("Du er ikke autoriseret til at tilgå beskeder for denne sag.");
                } else if (e.status == 404) {
                    setErrorMsg("Der kunne ikke findes en sag med det angivne sagsnummer.");
                } else {
                    setErrorMsg("Kunne ikke hente beskeder for sagen.");
                }
            }
        }
    };

    const GetIncidentInfo = async () => {
        try {
            let incident = await IncidentsService.getIncidentById(umbracoSettings.incidentType, incidentnumber);
            setIncident(incident);

            setLoading(false);
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 401) {
                    setErrorMsg("Du er ikke autoriseret til at tilgå denne sag.");
                } else if (e.status == 404) {
                    setErrorMsg("Der kunne ikke findes en sag med det angivne sagsnummer.");
                } else {
                    setErrorMsg("Kunne ikke hente information om sagen.");
                }
            }
        }
    };

    const downloadFile = async (file: AttachmentFileName) => {
        try {
            if (!file.id) {
                setErrorMsg("Kunne ikke downloade filen.");
                return;
            }
            const url = `/api/v1/incidents/${umbracoSettings.incidentType}/messages/${props.incidentnumber}/attachments/${file.id}`;

            const rawResponse = await fetch(url, {
                method: "POST",

            });
            const result = await rawResponse.blob();
            const blobUrl = URL.createObjectURL(result);
            let anchor = document.createElement("a");
            anchor.download = file.fileName || "";
            anchor.href = blobUrl;
            anchor.click();
        } catch (e) {
            if (e instanceof ApiError) {
                setErrorMsg("Kunne ikke downloade filen.");
            }
        }
    };



    const columns: DMTableColumn[] = [
        {
            id: "content",
            label: "Besked",
            format: (value) => (typeof value === "string") ? SantizedRichHtml(value) : "",
            hasCollapsibleContents: true,
            minWidth: 200,
            mobile: true,
        },
        {
            id: "sender",
            label: "Afsender",
            width: 150,
        },
        {
            id: "senton",
            label: "Dato/Tid",
            width: 150,
            maxWidth: 150,
        },
        {
            id: "files",
            label: "Filer",
            width: 150,
            maxWidth: 150,
            mobile: true,
        },
    ];
    function trimAfterBreakLine(input: string): string {
      // Define the pattern for the break line
      const breakLinePattern = /_{10,}/;

      // Find the index of the break line
      const index = input.search(breakLinePattern);

      // If the break line is found, return the substring before it
      if (index !== -1) {
        return input.substring(0, index).trim();
      }

      // If no break line is found, return the original string
      return input;
    }

    function createData(msgId: string, content: string, fromDM: boolean, lastSenderName: string | null, datetime: Date, unread: boolean, files: AttachmentFileName[]): DMTabelData {
        let offsetDate = DanishDateFns.addMinutes(datetime, datetime.getTimezoneOffset() * -1);
        return {
            id: msgId,
            values: [
              { id: "content", value: trimAfterBreakLine(content) },
                { id: "sender", value: lastSenderName ?? <i>{fromDM ? "DM" : (props.name||"Mig")}</i> },
                // { id: "sender", value: fromDM ? "DM" : "Mig" },
                { id: "senton", value: formatDateTime(offsetDate) },
                { id: "files", value: clickableFileList(files, downloadFile) },
            ],
            isBold: unread,
        };
    }

    const rows = messages.length == 0 ?
        [] :
        messages
            .filter((e): e is Required<Message> => e.content != undefined)
            .map((e) => createData(
                e.id,
                e.content ?? "",
                e.fromPortalStaff,
                e.lastSenderName || null,
                new Date(Date.parse(e.sentOn || "")),
                e.unread,
                e.attachmentFileNames ?? []));


    return <div>
        <h2>{incident ? incident.title : ""}</h2>
        <h3>{`Sagsnr: ${props.incidentnumber}`}</h3>
        <h4>{`Status: ${incident?.isArchived ? "Lukket" : "Aktiv"}`}</h4>
        <br />
        {loading ? <Loader /> :
            <div>
                <DMTable columns={columns} data={rows} />
                {incident && !incident.isArchived && <MessageForm files={files} addFiles={addFiles}
                    incidentType={umbracoSettings.incidentType}
                    containsIncidentFormField={false}
                    foldableMessageForm={true}
                    categories={[]}
                    subject={incident.title ?? ""}
                    deleteFile={deleteFile}
                    addMessage={addMessage}
                    incidentTicketNumber={props.incidentnumber}
                    clearFiles={clearFiles}
                    errorMsg={errorMsg}
                    setErrorMsg={setErrorMsg}
                    messages={messages}
                    incidentId={incident.guidId}
                />}
                  
                <MessageDiv type={MessageType.error} msg={errorMsg} />
            </div>}

    </div>;
};



