/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect } from "react";
import { MembershipService, ApiError, EndMembershipInfo } from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";
import { EndMembershipInfoElement, Link } from "../../../models/umbracoElement";
import LabelValue from "../../_GenericElements/LabelValue/label-value";
import style from "./end-membership-info.module.scss";
import { DanishDateFns } from "../../../helpers/DanishDateFns";

import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";

interface EndMembershipInfoProps {
    data: string
}

export const EndMembershipInfoComponent = (props: EndMembershipInfoProps) => {
    const umbracoSettings: EndMembershipInfoElement = JSON.parse(props.data);
    const [data, setdata] = React.useState<EndMembershipInfo>();
    const [errorMsg, setErrorMsg] = React.useState<string>("");
    useEffect(() => {
        void (async () => {
            try {
                let Data = await MembershipService.getEndMembershipInfo();
                setdata(Data);
                console.log(Data);
            } catch (e) {
                setErrorMsg("Der er sket fejl ved at hente udmeldelses information");
                console.error(e);
            }
        })();

    }, []);

    if (!data) {
        return <Loader />;
    }
    if (data.memberStatus === EndMembershipInfo.memberStatus.IKKEMEDLEM||
        data.memberStatus === EndMembershipInfo.memberStatus.TIDLIGEREMEDLEM) {
        return <div />;
    }
    if (data.memberStatus !== EndMembershipInfo.memberStatus.UNDERUDMELDELSE) {
        return <div>
            <h2>{umbracoSettings.udmeldingOverskrift || "Status på medlemskab"}</h2>
            <p>{umbracoSettings.udmeldingTekst || "Dit medlemskab er aktivt"}</p>
        </div>;
    } else {
        return <div>
            <h2>
                Udmeldelsesstatus
            </h2>
            <table>
                <LabelValue trClassName={style.thwidth} label={(umbracoSettings.statusLabel || "Status") } value={data.memberStatus}></LabelValue>
                <LabelValue label={(umbracoSettings.udmeldingsArsag || "Udmeldelsesårsag")} value={data.leaveReason}></LabelValue>
                <LabelValue label={(umbracoSettings.udmeldingRegistrationDato || "Udmeldelse registreret den")} value={data.createdOn ? DanishDateFns.format(new Date(data.createdOn), "dd-MM-yyyy") : ""}></LabelValue>
                <LabelValue label={(umbracoSettings.udmeldingsDato || "Udmeldelsesdato") } value={data.leaveDate ? DanishDateFns.format(new Date(data.leaveDate), "dd-MM-yyyy") : ""}></LabelValue>
            </table>
            <MessageDiv type={MessageType.error} msg={errorMsg} />
        </div>;
    }
};