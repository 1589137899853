import { CrmEntity } from "../../../api";

export class GenericValidations<FormType extends Record<string, any>> {
    private Labels: Record<keyof FormType, string>;
    private values: FormType;
    public errors: Record<keyof FormType, string>;

    constructor(labels: Record<keyof FormType, string>, values: FormType) {
        this.Labels = labels;
        this.errors = <Record<keyof FormType, string>>{};
        this.values = values;
    }

    private GetValue: (field: keyof FormType) => any = (field) => {
        return this.values[field];
    }

    private GetTypedValue<T>(field: keyof FormType) {
        return this.GetValue(field) as T;
    }

    private isCrmEntity(value: any) : value is CrmEntity {
        return ("id" in value && "primaryName" in value);
    }

    public required = (field: keyof FormType) => {
        let value = this.GetValue(field);
        if (typeof value == "object") {
            if (value && this.isCrmEntity(value)) {
                return value.id ? undefined : this.addError(field, `${this.Labels[field]} skal være udfyldt`);
            }
        }
        if (typeof value === "boolean") {
            return value != null ? undefined : this.addError(field, `${this.Labels[field]} skal være udfyldt`);
        } else {
            return value ? undefined : this.addError(field, `${this.Labels[field]} skal være udfyldt`);
        }
    }

    private addError = (field: keyof FormType, error: string) => {
        if (!this.errors[field]) {
            this.errors[field] = error;
            return error;
        }
    }

    public GenericTextValidations = {
        minLength: (field: keyof FormType, minimum: number) => {
            let value = this.GetTypedValue<string>(field);
            if (value && value.length > minimum) {
                return;
            } else {
                return this.addError(field, `${this.Labels[field]} skal være mindst ${minimum} karakterer`);
            }
        },

        maxLength: (field: keyof FormType, maximum: number) => {
            let value = this.GetTypedValue<string>(field);
            if (value && value.length < maximum) {
                return;
            } else {
                return this.addError(field, `${this.Labels[field]} må ikke være længere end ${maximum} karakterer`);
            }
        },

        exactLength: (field: keyof FormType, length: number) => {
            let value = this.GetTypedValue<string>(field);
            if (value && value.length === length) {
                return;
            } else {
                return this.addError(field, `${this.Labels[field]} skal være ${length} karakterer`);
            }
        },
    }

    public GenericDateValidations = {

    }

    public GenericBoolValidations = {

    }

}
