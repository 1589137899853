/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { RepresentativeService, Representative, MetadataService, CrmEntity, DMOrganization } from "../../../../api";
import LabelValue from "../../../_GenericElements/LabelValue/label-value";
import { hideOrganizationType } from "../../../../constants/common-constants";
import { Form, Formik } from "formik";
import { GenericFormElements } from "../../../_GenericElements/_GenericFormElements/GenericFormElements";
import { RecordMultipleDropDown } from "../../../_GenericElements/_GenericFormElements/RecordMultipleDropdown";
import * as yup from "yup";
import styles from "../representative-overview.module.scss";
import Loader from "../../../_GenericElements/Loader/loader";
import { MessageDiv, MessageType } from "../../../_GenericElements/MessageDiv/MessageDiv";
import { StandardButton } from "../../../_GenericElements/StandardButton/StandardButton";
import { RepresentativeElement } from "../../../../models/umbracoElement";
import { DMDialog } from "../../../_GenericElements/DMDialog/dm-dialog";
type RepresentativeDetailProps = {
    id: string
    contactid: string
    goBack?: () => void
}

export const RepresentativeEditFormLabels: Record<keyof RepresentativeEditFormValues, string> = {
    "workPhone": "Arbejdstelefon",
    "mobile": "Mobilnummer",
    "emailForarea": "Email ansættelsessted – HR eller leder",
    "email": "Arbejdsemail",
    "coverageArea": "Dækningsområde",
    "endDate": "Slut dato",
};
type RepresentativeEditFormValues = NonNullable<Pick<Representative, "workPhone" | "mobile" | "email" | "emailForarea" | "coverageArea"> & { /* job?:CrmEntity,*/endDate?: Date | null }>

const FormElements = new GenericFormElements<RepresentativeEditFormValues>(RepresentativeEditFormLabels);
type ddData = {
    acOrganizations: CrmEntity[];
   
}
const updateRepresentativeSchema = yup.object({
    areas: yup.array().min(1, "Mangler ansættelsessted")
        .max(3, "Max 3 ansættelsessteder"),
    email: yup.string().required("Mangler email"),
    emailForarea: yup.string().required("Mangler ansættelsessted email"),
});

export const RepresentativeDetail = (props: RepresentativeDetailProps) => {
    const [data, setData] = useState<Representative>();
    const [ddData, setDdData] = useState<ddData>({acOrganizations: [] });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const res = await RepresentativeService.getRepresentative(props.id);
                if (!res) { setErrorMessage("Der findes ikke nogen data."); }
                const acres = await MetadataService.getAcOrganizationList();
                setDdData({ acOrganizations: acres });
                setData(res);
            } catch (err) {
                setErrorMessage("Der er sket en fejl. Data kunne ikke hentes.");
                console.error(err);

            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const updateRepresentative = async (values: RepresentativeEditFormValues & { acORganizations?: DMOrganization[], areas?: DMOrganization[] }) => {
        setSubmitted(false);
        setErrorMessage("");
        const updatedRepresentative: Representative = {
            ...values,
            id: props.id,
            contactId: props.contactid,
            endDate: values.endDate ? format(values.endDate, "yyyy-MM-dd") : null,
        };
        try {
            await RepresentativeService.updateRepresentative(updatedRepresentative);
            if (values.endDate) {
                window.location.href = `${window.location.origin}/tillidshverv`;
            }
            const updated = await RepresentativeService.getRepresentative(props.id);
            console.log(updated);
            setData(updated);
            setSubmitted(true);
        } catch (err) {
            setErrorMessage("Der er sket en fejl. Data kunne ikke hentes.");
            console.error(err);
        }
    };


    const getForm = useCallback(() => {
        return (data ? <Formik
            initialValues={{
                acOrganizations: data.acOrganizations ?? [],
                coverageArea: data.coverageArea ?? "",
                email: data.email ?? "",
                emailForarea: data.emailForarea ?? "",
                workPhone: data.workPhone ?? "",
                mobile: data.mobile ?? "",
                endDate: data.endDate ? new Date(data.endDate) : null,
            }}
            validationSchema={updateRepresentativeSchema}
            onSubmit={async (values, { setSubmitting }) => {
                console.log(values);
                await updateRepresentative(values);
                setSubmitting(false);
            }}
        >
            {({ values,
                handleSubmit,
                isSubmitting,
                isValid,
                initialValues,
                setFieldValue,
            }) => (
                <div className={styles.dmForm}>
                    <h1>{data.type || ""}</h1>
                    <Form onSubmit={handleSubmit} >
                        <LabelValue trClassName={styles.labeltr} label="Hverv" value={data.type || ""} />
                        {data.vipTap? <LabelValue trClassName={styles.labeltr} label="VIP/TAP" value={data.vipTap == "VIPogTAP" ? "VIP og TAP" : data.vipTap} />: <></>}
                        {<LabelValue trClassName={styles.labeltr} label="Netværk" value={data.networks?.map(x => x.name).join(", ") || ""} />}
                        <LabelValue trClassName={styles.labeltr} label="Samtykke" value={data.consent == true ? "Ja" : "Nej"} />
                        <LabelValue trClassName={styles.labeltr} label="Start dato" value={data.startDate ? format(new Date(data.startDate), "dd/MM/yyyy") : ""} />
                        <LabelValue trClassName={styles.labeltr} label="Anmeldelsesområde" value = {data.areasText||""}/>
                        <br />
                        <FormElements.BasicDateInput formValueName="endDate" />
                        <FormElements.BasicTextInput formValueName="coverageArea"/>

                        {data.type !== hideOrganizationType &&
                            RecordMultipleDropDown("acOrganizations", "AC organisationer", ddData?.acOrganizations ?? [], setFieldValue, loading, data.acOrganizations?.map(x => x.id!))
                        }
                        <FormElements.BasicTextInput formValueName="workPhone"/>
                        <FormElements.BasicTextInput formValueName="mobile"/>
                        <FormElements.BasicTextInput formValueName="email"/>
                        <FormElements.BasicTextInput formValueName="emailForarea" />
                        <div className={styles.btnsdiv}>
                            {props.goBack && <div className={styles.cancelbtndiv}> <StandardButton showArrow={false} onClick={props.goBack} >
                                Tilbage
                            </StandardButton></div>}
                            {<StandardButton showArrow={false} type="submit" loading={isSubmitting}
                                disabled={isSubmitting}>
                                Rediger
                            </StandardButton>}

                        </div>
                    </Form>
                </div>
            )}
        </Formik> : null);
    }, [data, loading]);

    return (
        <>
            {loading ? <Loader /> : getForm()}
            <MessageDiv type={MessageType.error} msg={errorMessage} />
            {data?.type && submitted &&
             <DMDialog
                 title={`${data?.type} ændring`}
                 content={`Ændringerne for ${data?.type} er gemt successfuldt.`}
                 open={true}
                 onclose={()=>setSubmitted(false)}/>}
        </>
    );

};