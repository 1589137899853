/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EventRegistrationBase = {
    price?: number | null;
    eventId?: string | null;
    id?: string | null;
    status?: EventRegistrationBase.status | null;
    state?: EventRegistrationBase.state | null;
    hasPaied?: boolean | null;
}

export namespace EventRegistrationBase {

    export enum status {
        TILMELDING_GODKENDT = 'TilmeldingGodkendt',
        AFMELDT = 'Afmeldt',
        DEAKTIVERET = 'Deaktiveret',
        UDEBLEVET = 'Udeblevet',
        TILMELDING_REGISTRERET = 'TilmeldingRegistreret',
        AFMELDING_REGISTRERET = 'AfmeldingRegistreret',
        AFLYST = 'Aflyst',
        TILMELDING_OPRETTET_MIT_DM = 'TilmeldingOprettetMitDM',
    }

    export enum state {
        AKTIV = 'Aktiv',
        INAKTIV = 'Inaktiv',
    }


}