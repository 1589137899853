import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, FormControlLabel, List, Stack } from "@mui/material";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import { Form, Formik, Field, ErrorMessage, validateYupSchema } from "formik";
import style from "./event-signup.module.scss";
import * as api from "../../../api/services/EventService";
import { eventSignupSchema } from "./event-signup-schema";
import { Switch, TextField } from "formik-mui";
import type { Event } from "../../../api/models/Event";
import { ApiError, EventQuestion, EventSignUp, EventRegistrationResponse, EventOptionalPurchase, PayTransaction, EventSignUpAnswer } from "../../../api";
import { EventSignupFormLabels } from "./event-signup-labels";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { EventOverviewElement } from "../../../models/umbracoElement";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import { DMDialog } from "../../_GenericElements/DMDialog/dm-dialog";
import { handleEventPaymentError } from "../EventOverview/event-payment-error-handling";

export type eventSignupFormProps = {
    _event: Event;
    umbracoSettings: EventOverviewElement;
    isMember: boolean;
}

type DynamicField = {
    id: string;
    question: string;
    required: boolean;
    value: string | never[] | null;
}

const consentTextForNotMember = `<div>Når du tilmelder dig vores arrangement, oplyser du dit navn, telefonnummer og e-mailadresse til DM.<br />
Vi behandler dine personoplysninger med det formål at kunne gennemføre og evaluere arrangementet, jf. GDPR artikel 6(1)(f), om legitim interesse. Derudover benytter vi dine oplysninger til business intelligence, analyse samt for at kunne vise dig vores hvervekampagner og andre annoncer på SoMe, jf. GDPR artikel 6(1)(f), om legitim interesse. Endelig behandler vi dine personoplysninger med det formål at kontakte dig per e-mail eller telefon med tilbud om medlemskab af DM.<br />
Du kan altid anmode os om at slette dine oplysninger igen. Læs mere på <a target="_blank" href="https://dm.dk/privatlivspolitik">dm.dk/privatlivspolitik</a>.</div>`;

export const EventSignupForm = (props: eventSignupFormProps) => {
    const FormElements = new GenericFormElements(EventSignupFormLabels);
    const [shouldSignupWaitlist, setShouldSignupWaitlist] = useState(false);
    const [soldout, setSoldout] = useState(false);
    const [hasGuest, setHasGuest] = useState(false);
    const [registeredBefore, setRegisteredBefore] = useState(false);
    const questions = useCallback((isGuest: boolean) => props._event.questions?.map((question, index) => {
        const name = isGuest ? "guest.dynamicFields" : "dynamicFields";
        switch (question.type) {
            case EventQuestion.type.ENKELTEKST: {
                return <FormElements.BasicTextInput formValueName={question.question} valueRef={`${name}[${index}].value`} />;
            }

            case EventQuestion.type.BOOLESKJANEJ: {
                return FormElements.BasicYesNoRadioButtons(question.question, `${name}[${index}].value`);
            }

            case EventQuestion.type.ENKELTVALG: {
                if (question && question.options) {
                    const options: string[] = question.options.split("\n");
                    return FormElements.StringDropDown(question.question, options, `${name}[${index}].value`);
                }
                return <div />;
            }
            case EventQuestion.type.SP_RGSM_LMEDFLERESVARMULIGHEDER: {
                if (question && question.options) {
                    const options: string[] = question.options.split("\n");
                    return FormElements.StringMultiSelectDropDown(question.question, options, `${name}[${index}].value`);
                }
                return <div />;
            }
            default: {
                <div />;
                break;
            }
        }
    }), [props._event.questions]);

    const createRegistration = (eventSignUpData: EventSignUp, setLoading: (loading: boolean) => void) => {

        console.log("Sending event registration data...", eventSignUpData);
        api.EventService.register(
            eventSignUpData
        ).then((status) => {
            console.log("Response: Event registered...", status);
            window.location.search = "registered=1";
        })
            .catch((err) => {
                if (err instanceof ApiError) {
                    if (err.status === 409) {
                        setRegisteredBefore(true);
                    } else {
                        handleEventPaymentError(err);
                    }
                }
                console.error(err);
            })
            .finally(() => { setLoading(false); });
    };
    const guestPrice = useMemo(() =>
        (props._event.eventGuestPrice || 0)
        , [props._event]);

    const buyIns = useCallback((isGuest: boolean) => 
        props._event.optionalPurchaseOptions?.map((buyIn, index) => { return { buyIn: buyIn, index: index } })
            .filter(x => x.buyIn.onlyForGuests === isGuest)
            .map(x => {
                const name = isGuest ? "guest.buyIns" : "buyIns";
                return FormElements.BasicYesNoRadioButtons(
                    `${x.buyIn.name || "Mangler text"} (${new Intl.NumberFormat("da-DK").format(x.buyIn.price || 0)} kroner)`,
                    `${name}[${x.index}].value`
                );
    }), [props._event.optionalPurchaseOptions]);
    
    useEffect(() => {
        let shouldSignUpWaitinglist = false;
        let isSoldOut = false;
        if (props._event) {
            // max capacity is defined, and free event
            if (props._event.maxCapacity && (!props._event.eventPrice || props._event.eventPrice == 0)) {
                // max capacity reached?
                if (props._event.maxCapacity - (props._event.registrationCount ?? 0) - (hasGuest ? 1 : 0) <= 0) {
                    if (props._event.hasWaitingList == true) {
                        shouldSignUpWaitinglist = true;
                    } else {
                        isSoldOut = true;
                    }
                }
            }
        }
        setSoldout(isSoldOut);
        setShouldSignupWaitlist(shouldSignUpWaitinglist);
    }, [props._event, hasGuest]);



    const getPrice = useCallback((
        values: {
            initialPrice: number,
            guest: {
                hasGuest: boolean, buyIns?: { id: string, label: string, price: number, value?: string | null }[]
            }
            buyIns?: { id: string, label: string, price: number, value?: string | null }[]
        }) => {
        let guestPrice = 0;
        const optionPrice = values.buyIns?.filter(x => x.value && x.value == "Yes").map(x => x.price)
            .reduce((sum, current) => sum + current, 0) ?? 0;
        if (props._event.canGuestJoin && values.guest.hasGuest) {
            guestPrice += props._event.eventGuestPrice ?? 0;
            const guestOptionPrice = values.guest?.buyIns?.filter(x => x.value && x.value == "Yes").map(x => x.price)
                .reduce((sum, current) => sum + current, 0) ?? 0;
            guestPrice += guestOptionPrice ?? 0;

        }
        console.log(optionPrice, guestPrice);
        return optionPrice + values.initialPrice + guestPrice;
    }, [props._event]);

    const setDynamicFields = (question: EventQuestion): DynamicField => {
        return {
            id: question.id,
            question: question.question,
            required: question.isRequired ?? false,
            value: question.type == EventQuestion.type.SP_RGSM_LMEDFLERESVARMULIGHEDER ? [] :
                question.type == EventQuestion.type.BOOLESKJANEJ ? null :
                    question.type == EventQuestion.type.ENKELTEKST || question.type == EventQuestion.type.ENKELTVALG ? "" :
                        null,
        };
    };
    const setAnswer = (dynamicField: DynamicField) => {
        return {
            eventQuestionId: dynamicField.id, answer: (typeof dynamicField.value) == "string" ? dynamicField.value as string :
                (typeof dynamicField.value) == "boolean" ? dynamicField.value?.toString() :
                    (dynamicField.value as string[]).join(),
        };
    };

    const setBuyIn = (purchase: EventOptionalPurchase) => {
        return {
            id: purchase.id,
            label: purchase.name || "Missing Label",
            price: purchase.price ?? 0,
            value: "No",
        };
    };

    return (
        <div>
            <Formik
                initialValues={{
                    id: props._event.id,
                    acceptedTerms: false,
                    initialPrice: props._event.eventPrice ?? 0,
                    buyInPrice: 0,
                    buyIns: props._event.optionalPurchaseOptions?.map(purchase => setBuyIn(purchase)),
                    dynamicFields: props._event.questions?.map(question => setDynamicFields(question)),
                    guest: {
                        hasGuest: false,
                        dynamicFields:
                            props._event.questions?.map(question => setDynamicFields(question)),
                        buyIns: props._event.optionalPurchaseOptions?.map(purchase => setBuyIn(purchase)),
                        guestFirstName: "",
                        guestLastName: "",
                    },
                }}
                validate={(values) => {
                    console.log(values);
                    setHasGuest(values.guest.hasGuest);
                    return {};
                }}
                validationSchema={eventSignupSchema}
                onSubmit={(values, { setSubmitting }) => {

                    setSubmitting(true);
                    const data: EventSignUp = {
                        eventId: values.id,
                        acceptedTradeTerms: values.acceptedTerms,
                        expectedPrice: values.initialPrice + values.buyInPrice,
                        optionalPurchases: values.buyIns ? values.buyIns.filter(x => x.value == "Yes").map(x => x.id) : [],
                        answers: values.dynamicFields ? values.dynamicFields.map(x => setAnswer(x)) :
                            [],
                    };
                    if (hasGuest) {
                        data.guest = {
                            guestFirstName: values.guest?.guestFirstName,
                            guestLastName: values.guest?.guestLastName,
                            answers: values.guest?.dynamicFields ? values.guest?.dynamicFields.map(x => setAnswer(x)) : [],
                            optionalPurchases: values.guest?.buyIns ? values.guest?.buyIns.filter(x => x.value == "Yes").map(x => x.id) : [],
                        };
                    }
                    console.log("Data", data);
                    createRegistration(data, setSubmitting);
                }
                }
            >
                {({ values, errors, isSubmitting, handleSubmit }) => {
                    return (
                        <Form className={style.eventSignupForm} onSubmit={handleSubmit}>
                            {props._event.questions && props._event.questions.length > 0 ? <h3>Tilvalg</h3> : <div></div>}
                            {questions(false)}
                            {props._event.optionalPurchaseOptions && props._event.optionalPurchaseOptions.length > 0 ? <h3>Tilkøb</h3> : <div></div>}
                            <Stack>
                                {buyIns(false)}
                            </Stack>
                            {props._event.canGuestJoin == true && props.isMember &&
                                <div>
                                    <FormControlLabel
                                        control={<Field component={Switch} type="checkbox"
                                            name="guest.hasGuest" />}
                                        label={"Tilføj en gæst" + (guestPrice > 0 ? ` (${new Intl.NumberFormat("da-DK").format(guestPrice)} kroner)` : "")} />
                                    {hasGuest && <fieldset>
                                        <legend>Gæst tilmelding</legend>

                                        <h3>Navn</h3>
                                        <FormElements.BasicTextInput formValueName="guest.guestFirstName" />
                                        <FormElements.BasicTextInput formValueName="guest.guestLastName" />
                                        {props._event.questions && props._event.questions.length > 0 ? <h3>Tilvalg (gæst)</h3> : <div></div>}
                                        {questions(true)}
                                        {props._event.optionalPurchaseOptions && props._event.optionalPurchaseOptions.length > 0 ? <h3>Tilkøb (gæst)</h3> : <div></div>}
                                        <Stack>
                                            {buyIns(true)}
                                        </Stack>
                                    </fieldset>}
                                </div>}
                            {(values.initialPrice && values.initialPrice > 0) ?
                                <FormControlLabel
                                    control={<Field
                                        component={Switch}
                                        type="checkbox"
                                        name="acceptedTerms" />}
                                    label={<span>Jeg accepterer <a rel="noreferrer" target="_blank" href="https://dm.dk/handelsvilkaar">handelsvilkårene</a></span>} /> : <div />}
                            <ErrorMessage name="acceptedTerms">{msg => <div className={style.errorMsg}> {msg} </div>}</ErrorMessage>
                            {getPrice(values) > 0 && <label className={style.fullPrice}>{`Fuld Pris: ${new Intl.NumberFormat("da-DK").format(getPrice(values))} kroner`}</label>}
                            {shouldSignupWaitlist && <MessageDiv type={MessageType.error} msg={props.umbracoSettings?.fullbookedText || "Dette arrangement er desværre fuldt booket, men du kan skrive dig på venteliste"} />}
                            {soldout && <MessageDiv type={MessageType.error} msg={props.umbracoSettings?.soldoutText || "Dette arrangement er desværre fuldt booket"} />}
                            {!soldout && <>
                                {!props.isMember && <div>{SantizedRichHtml(consentTextForNotMember)}</div>}
                                <div className={style.submitBtndiv}>
                                    <StandardButton loading={isSubmitting} disabled={isSubmitting || !props._event.isRegisterationOpen}
                                        type={"submit"}
                                        showArrow={false}>
                                        {shouldSignupWaitlist ? (props.umbracoSettings?.signupToWaitlistLabel || "Skriv mig op på venteliste") : "Tilmeld arrangement"}
                                    </StandardButton>
                                </div></>}
                        </Form>
                    );
                }}
            </Formik>
            {(shouldSignupWaitlist || soldout) &&
                <DMDialog title={"Fuldt booket"} content={shouldSignupWaitlist ? (props.umbracoSettings?.fullbookedText || "Dette arrangement er desværre fuldt booket, men du kan skrive dig på venteliste") :
                    (soldout ? (props.umbracoSettings?.soldoutText || "Dette arrangement er desværre fuldt booket") : "")} open={true}
                    onclose={() => { }}></DMDialog>}
            {(registeredBefore) &&
                <DMDialog title={"Tidligere afmeldt"} content={"Du er afmeldt dette arrangement. Hvis du vi tilmeldes igen, så kontakt DM via Kontakt her på Mit DM."} open={true}
                    onclose={() => { setRegisteredBefore(false); }}></DMDialog>}
        </div >
    );
};