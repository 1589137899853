import { useEffect, useRef, useState } from "react";
import { MetadataService } from "../../../api/services/MetadataService";
import { ApiError, CrmEntity, ContactsService } from "../../../api";
import { Form, Formik, FormikProps } from "formik";
import { WorkDetailsFormValues, workDetailsSchema } from "./UpdateWorkDetailsSchema";
import { WorkDetailsUpdate } from "../../../api/models/WorkDetailsUpdate";
import { WorkFormElement } from "../../../models/umbracoElement";
import { ApiErrorBody } from "../../../models/apiError";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import globalStyle from "../../../global.scss";
import formStyles from "../../_GenericElements/_GenericFormElements/form.module.scss";
import { Stack } from "@mui/material";
import LabelValue from "../../_GenericElements/LabelValue/label-value";
import { Freelance, SelfEmployed } from "../../../constants/common-constants";
import { UpdateConfirmMessageBox } from "../UpdateConfirmMessageBox/UpdateConfirmMessageBox";

export type IChangeToUpdateWorkDetailsFormProps = {
}

const UpdateWorkDetailsFormLabels: (elementConfig?: WorkFormElement) => Record<keyof WorkDetailsFormValues, string> = (elementConfig) => {
    return {
        "startDate": elementConfig?.startDatoLabel || "Gældende fra",
        "jobPosition": elementConfig?.stillingLabel || "Stilling",
        "placeOfWork": elementConfig?.ansaettelsesstedLabel || "Ansættelsessted",
        "placeOfEmploymentName": elementConfig?.virksomhedsnavnLabel || "Virksomhedsnavn",
        "companyAddress": elementConfig?.virksomhedsadresseLabel || "Virksomhedsadresse",
        "cvr": elementConfig?.cVRLabel || "CVR",
        "employmentType": elementConfig?.ansaettelsestypeLabel || "Ansættelsestype",
        "placeOfWorkNotinList": elementConfig?.tilfojAnsaettelsesstedLabel || "Tilføj Ansættelsessted",
        "workArea": "Arbejdsområde",
    };
};

export const UpdateWorkDetailsForm = (props: IChangeToUpdateWorkDetailsFormProps) => {
    const [loading, setLoading] = useState(true);
    const [workplaces, setWorkPlaces] = useState<NonNullable<CrmEntity[]>>([]);
    const [jobPositions, setjobPositions] = useState<NonNullable<CrmEntity[]>>([]);
    const [employmentTypes, setemploymentTypes] = useState<NonNullable<CrmEntity[]>>([]);
    const [workAreas, setWorkAreas] = useState<NonNullable<CrmEntity[]>>([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [allowChangingEmploymentType, setAllowChangingEmploymentType] = useState<boolean>(true);
    const [displayStartDate, setDisplayStartDate] = useState<boolean>(false);
    const formikRef = useRef<FormikProps<WorkDetailsFormValues>>(null);

    const [defaultValues, setDefaultValues] = useState<WorkDetailsFormValues>({
        startDate: null,
        placeOfEmploymentName: undefined,
        companyAddress: undefined,
        cvr: undefined,
        jobPosition: { id: "", primaryName: "" },
        placeOfWork: { id: "", primaryName: "" },
        employmentType: { id: "", primaryName: "" },
        placeOfWorkNotinList: false,
        workArea: { id: "", primaryName: ""},
    });

    const GetCurrentInformation = async () => {
        try {
            let currentInformation = await ContactsService.getMainEmployment();

            let newDefaults: WorkDetailsFormValues =
            {
                startDate: null,
                placeOfEmploymentName: currentInformation.placeOfEmploymentName ?? undefined,
                companyAddress: currentInformation.companyAddress ?? undefined,
                cvr: currentInformation.cvr ?? undefined,
                jobPosition: currentInformation.jobPosition ?? { id: "", primaryName: "" },
                placeOfWork: currentInformation.placeOfWork ?? { id: "", primaryName: "" },
                employmentType: currentInformation.employmentType ?? { id: "", primaryName: "" },
                workArea: currentInformation.workArea ?? { id: "", primaryName: "" },
                placeOfWorkNotinList: false,
            };

            setAllowChangingEmploymentType(currentInformation.employmentType?.primaryName != Freelance && currentInformation.employmentType?.primaryName != SelfEmployed);
            setDefaultValues(newDefaults);
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 422) {
                    console.log("Brugeren var ikke i arbejde");

                } else if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg(errorInfo.title);
                }
            }
        }
    };

    const GetOptions = async () => {
        void await Promise.all(
            [
                MetadataService.getAllWorkPlaces().then(wp => setWorkPlaces(wp)),
                MetadataService.getAllJobPositions().then(jp => setjobPositions(jp)),
                MetadataService.getAllEmploymentTypes().then(et => setemploymentTypes(et)),
                MetadataService.getAllWorkAreas().then(wa => setWorkAreas(wa)),
            ]
        );
        setLoading(false);
    };

    const updateMainEmployment = async (formData: WorkDetailsFormValues) => {
        let jobPositionData: WorkDetailsUpdate = {
            ...formData,
            placeOfWork: formData.placeOfWorkNotinList ? undefined : formData.placeOfWork?.id ? formData.placeOfWork : undefined,
            startDate: formData.startDate!.toISOString(),
        };

        try {
            await ContactsService.updateMainEmployment(jobPositionData);
            setShowConfirm(true);
        } catch (e) {
            if (e instanceof ApiError) {
                let errorInfo: ApiErrorBody = JSON.parse(e.body);
                setErrorMsg(errorInfo.title);
            }
        }
    };

    useEffect(() => {
        void GetOptions();
        void GetCurrentInformation();
    }, []);

    const labels = UpdateWorkDetailsFormLabels();

    // Dropdowns form elements
    const FormElements = new GenericFormElements<WorkDetailsFormValues>(labels);

    let form = <Formik
        initialValues={defaultValues}
        validationSchema={workDetailsSchema}
        onSubmit={async (values, { setSubmitting }) => {
            if (!formikRef.current?.dirty) {
                setDisplayStartDate(true);
                return;
            }

            await updateMainEmployment(values);
            setSubmitting(false);
        }}
        enableReinitialize={true}
        innerRef={formikRef}
        validate={() => {
            !formikRef.current?.dirty && setDisplayStartDate(true);
        }}
    >
        {({ values,
            isSubmitting,
            handleSubmit,
            dirty,
            errors,
        }) => {

            return (
                <Form className={formStyles.dmForm} onSubmit={handleSubmit}>
                    <FormElements.BasicYesNoSwitch formValueName="placeOfWorkNotinList" />
                    {values.placeOfWorkNotinList ? <>

                        <FormElements.BasicTextInput formValueName="companyAddress" />
                        <FormElements.BasicTextInput formValueName="placeOfEmploymentName" />
                        <FormElements.BasicTextInput formValueName="cvr" />
                    </>                :
                        <FormElements.RecordAutoComplete formValueName="placeOfWork"  options={workplaces}
                            loading={workplaces.length == 0 && loading} />
                    }
                    {allowChangingEmploymentType ?
                        FormElements.RecordDropDown("employmentType", employmentTypes, employmentTypes.length == 0 && loading, false, isSubmitting) :
                        <LabelValue label={labels.employmentType} value={values.employmentType.primaryName}></LabelValue>
                    }
                    {FormElements.RecordDropDown("jobPosition", jobPositions, jobPositions.length == 0 && loading, false, isSubmitting)}

                    {FormElements.RecordDropDown("workArea", workAreas, workAreas.length == 0 && loading, false, isSubmitting)}

                    { (dirty || displayStartDate) && <FormElements.BasicDateInput formValueName="startDate"  />}

                    <FormElements.SubmitButton type="submit" loading={isSubmitting}  disabled={isSubmitting} />

                    {errorMsg && <div><span className={globalStyle.errortext}>{errorMsg}</span></div>}
                </Form>
            );
        }
        }
    </Formik>;
    return <Stack direction="column" justifyItems="flex-start" alignItems="grow">
        {form}
        {showConfirm && <UpdateConfirmMessageBox onClose={() => setShowConfirm(false)} title="Ansættelse ændring" />}
    </Stack>;
};