import { Schema } from "yup";
import { MemberConsent } from "../../../api";
import * as yup from "yup";

export const consentFormSchema: Schema<MemberConsent> = yup.object({
    consentId: yup.string().required(),
    giveConsent: yup.bool().required(),
    memberConsentId:  yup.string().notRequired(),
    consentText: yup.string().notRequired(),
    consentVersion: yup.string().notRequired(),
});