/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DMOrganization } from '../models/DMOrganization';
import type { MainEmployment } from '../models/MainEmployment';
import type { Representative } from '../models/Representative';
import type { RepresentativeSubmission } from '../models/RepresentativeSubmission';
import type { RepresentativeType } from '../models/RepresentativeType';
import { request as __request } from '../core/request';

export class RepresentativeService {

    /**
     * @returns RepresentativeSubmission Success
     * @throws ApiError
     */
    public static async getRepresentativeSubmissions(): Promise<Array<RepresentativeSubmission>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/representatives/submissions`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async registerRepresentativeOrganizations(
requestBody?: RepresentativeSubmission,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/representatives/submissions`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
            },
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns RepresentativeSubmission Success
     * @throws ApiError
     */
    public static async getRepresentativeSubmission(
id: string,
): Promise<RepresentativeSubmission> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/representatives/submissions/${id}`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns Representative Success
     * @throws ApiError
     */
    public static async getTerminatedRepresentatives(): Promise<Array<Representative>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/representatives/terminated`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns Representative Success
     * @throws ApiError
     */
    public static async getTerminatedRepresentative(
id: string,
): Promise<Representative> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/representatives/terminated/${id}`,
        });
        return result.body;
    }

    /**
     * @returns Representative Success
     * @throws ApiError
     */
    public static async getRepresentatives(): Promise<Array<Representative>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/representatives`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static async registerRepresentative(
requestBody?: RepresentativeSubmission,
): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/representatives`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns MainEmployment Success
     * @throws ApiError
     */
    public static async updateRepresentative(
requestBody?: Representative,
): Promise<MainEmployment> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/representatives`,
            body: requestBody,
            errors: {
                400: `Bad Request`,
            },
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns Representative Success
     * @throws ApiError
     */
    public static async getRepresentative(
id: string,
): Promise<Representative> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/representatives/${id}`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns DMOrganization Success
     * @throws ApiError
     */
    public static async getAllowedOrganizations(
id: string,
): Promise<Array<DMOrganization>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/representatives/allowedorganizations/${id}`,
        });
        return result.body;
    }

    /**
     * @param id 
     * @returns DMOrganization Success
     * @throws ApiError
     */
    public static async getSubmissionAllowedOrganizations(
id: string,
): Promise<Array<DMOrganization>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/representatives/submissions/allowedorganizations/${id}`,
        });
        return result.body;
    }

    /**
     * @returns RepresentativeType Success
     * @throws ApiError
     */
    public static async getRepresentativeTypes(): Promise<Array<RepresentativeType>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/representatives/types`,
        });
        return result.body;
    }

    /**
     * @returns RepresentativeType Success
     * @throws ApiError
     */
    public static async getVipTapTypes(): Promise<Array<RepresentativeType>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/representatives/viportap`,
        });
        return result.body;
    }

}