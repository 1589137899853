import { DMDialog } from "../../_GenericElements/DMDialog/dm-dialog";

type ConfirmMessageBoxProps = {
  onClose?: () => void
}
export const ConfirmMessageBox = (props: ConfirmMessageBoxProps) => {

    return <DMDialog
        title={"Tillidshverv anmeldt"}
        content={"Din anmeldelse af tillidshverv er registreret"}
        open={true}
        onclose={props.onClose}></DMDialog>;
};