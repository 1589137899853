import { CrmEntity } from "../../../api";
import { Field, ErrorMessage } from "formik";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "formik-mui";
import Loader from "../Loader/loader";
import { useEffect, useState } from "react";
import { DMTheme } from "../../../helpers/DMTheme";
import { Box } from "@mui/material";

export const RecordMultipleDropDown = (formValueName: string, label: string, options: CrmEntity[], setValue: (field: string, value: any, validate?: boolean) => void, loading: boolean, defaultValues: string[] = []) => {
    if (typeof formValueName != "string") {
        return "";
    }
    console.log(defaultValues);
    const [ddValues, setDdValues] = useState<string[]>(defaultValues);
    useEffect(() => {
        setValue(`${formValueName}`, options.filter(x => ddValues.includes(x.id)), true);
    }, [ddValues]);

    const handleChange = (selected: string[]) => {
        if (selected != undefined) {
            setDdValues(selected);
        }
    };
    
    return <FormControl fullWidth>

        <Field
            fullwidth
            label={<Box sx={{ background: DMTheme.palette.background.default }}>
                {label}
            </Box>}
            component={Select}
            type={"text"}
            name={`${formValueName}`}
            multiple={true}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            onChange={(evt: any) => { handleChange(evt.target.value); }}
            inputProps={{ name: `${formValueName}.primaryName`, id: `${formValueName}.id` }}
            value={ddValues}
        >
            {loading ? <Loader /> : options.map((entity, index) => {
                return <MenuItem sx={{
                    fontFamily: "AvenirNext",
                    fontWeight: 400,
                    fontStyle: "normal",
                }}
                    key={entity.id} value={entity.id}>
                    {entity.primaryName}
                </MenuItem>
            })}
            <MenuItem key="default" sx={{ display: "none" }} value={""}>
                </MenuItem>
        </Field>

        <ErrorMessage name={formValueName} />
    </FormControl>;
};