/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CrmEntity } from './CrmEntity';

export type ContactUpdate = {
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    email?: string | null;
    primaryPhone?: string | null;
    mobilePhone?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    postalCode?: string | null;
    city?: string | null;
    country?: CrmEntity;
    language?: ContactUpdate.language | null;
    receiveDMAcademyMag?: boolean | null;
    eventNewsAtDM?: boolean | null;
    sectorNewsletter?: boolean | null;
    newsLetterFromBIO?: boolean | null;
    magazineResearchForumCanRead?: boolean | null;
    magazineResearchForumSubcriber?: boolean | null;
    magazineDMPrintedSubscriber?: boolean | null;
    magazineDMStudPrintedSubscriber?: boolean | null;
    momentum?: boolean | null;
    isBioPillar?: boolean | null;
    isDigiPillar?: boolean | null;
}

export namespace ContactUpdate {

    export enum language {
        DANSK = 'Dansk',
        ENGELSK = 'Engelsk',
    }


}