import React, { useEffect } from "react";
import { ApiError, Incident, IncidentsService, Draft } from "../../api";
import { UmbracoIncidentsList } from "../../models/umbracoElement";
import Loader from "../_GenericElements/Loader/loader";
import DMTable, { DMTabelData, DMTableColumn, DMTableWrapper, formatDateTime, OnEmpty } from "../_GenericElements/DMTable/table";
import { Stack } from "@mui/material";
import { LetterClosedIcon, LetterOpenIcon, ClockIcon, DateIcon } from "../_GenericElements/Icons/Icons";
import { StandardButton } from "../_GenericElements/StandardButton/StandardButton";
import { isMobile } from "../../helpers/MobileHelper";
import { DanishDateFns } from "../../helpers/DanishDateFns";
import { datetimecell } from "../_GenericElements/DMTable/table.module.scss";

export const dateDifference = (stringA: string | undefined | null, stringB: string | undefined | null) => {
    const distantPast = new Date(0);
    let a = stringA ? new Date(stringA) : distantPast;
    let b = stringB ? new Date(stringB) : distantPast;
    return b.getTime() - a.getTime();
};

const columns: DMTableColumn[] = [
    {
        id: "title",
        label: "Titel",
        minWidth: 300,
        maxWidth: 300,
        mobile: true,
    },
    {
        id: "incidentid",
        label: "Sagsnr.",
        minWidth: 220,
        maxWidth: 220,
    },
    {
        id: "lastmessagetime",
        label: "Sidste aktivitet",
        minWidth: 170,
        maxWidth: 170,
        mobile: true,
    },
    {
        id: "unread",
        label: "Ulæst",
        format: (value) => (typeof value === "boolean") ? (value ? LetterClosedIcon : LetterOpenIcon) : "",
    },
];

export type IncidentListProps = {
    data: string
}

export type UmbracoIncidentListProps = {
    data: string
}

export const UmbracoIncidentList = (settings: UmbracoIncidentListProps) => {
    const [umbracoSettings] = React.useState<UmbracoIncidentsList>(JSON.parse(settings.data));
    return IncidentList(umbracoSettings);
};

export const IncidentList = (umbracoSettings: UmbracoIncidentsList) => {
    const [loading, setLoading] = React.useState(true);
    const [incidents, setIncidents] = React.useState<NonNullable<Incident[]>>([]);
    const [errorMsg, setErrorMsg] = React.useState<string>();

    React.useEffect(() => {
        void getIncidents();
    }, []);



    const getIncidents = async () => {
        let response;
        try {
            if (umbracoSettings.showArchived) {
                response = await IncidentsService.getAllArchivedIncidents(umbracoSettings.incidentType);
            } else {
                response = await IncidentsService.getAllActiveIncidents(umbracoSettings.incidentType);
            }
            response.sort((a, b) => dateDifference(a.lastUpdated, b.lastUpdated));
            setIncidents(response);
            setLoading(false);
        } catch (e) {
            if (e instanceof ApiError) {
                setErrorMsg("Kunne ikke hente beskeder.");
            }
        }
    };

    const getOverviewUrl = (id: string) => {

        return `https://${window.location.host}/${umbracoSettings.redirectLink}/${id}`;

    };

    function createData(incidentid: string , title: string, datetime: Date | null, fromDM: boolean, status: string, unread: boolean): DMTabelData {
        return {
            id: incidentid,
            values: [
                { id: "title", value: title },
                { id: "incidentid", value: incidentid },
                { id: "lastmessagetime", value: formatDateTime(datetime) },
                { id: "status", value: status },
                { id: "unread", value: unread },
            ],
            isBold: unread,
        };
    }

    const getCreatetUrl = () => {
        return `https://${window.location.host}/${umbracoSettings.redirectLinkToForm}/`;
    };

    const rows = incidents.length == 0 ?
        [] :
        incidents
            .filter((e) : e is Required<Incident> => e.id != undefined)
            .map((e) => {
                let date: Date | null = e.lastUpdated ? new Date(Date.parse(e.lastUpdated)) : null;
                let offsetDate = DanishDateFns.addMinutes(date ? date : 0, date ? date.getTimezoneOffset() * -1: 0);
                return createData(
                    e.id ?? "ukendt",
                    e.title ?? "",
                    offsetDate,
                    e.senderOfLastMsgIsPortalStaff,
                    e.status ?? "",
                    e.hasUnreadMessages);
            });

    let newButton = (umbracoSettings.showArchived || umbracoSettings.hideCreateButton) ? null :
        <StandardButton sx={{alignSelf: isMobile ? "center" : "flex-end" }} hidden={umbracoSettings.showArchived} onClick={() => window.location.href = getCreatetUrl()}
            showArrow={true}>
        Ny besked
        </StandardButton>;

    return <Stack direction="column">
        <DMTableWrapper
            columns={columns}
            data={rows}
            onClick={(id: string) => window.location.href = getOverviewUrl(id)}
            initialRowsPrPage={umbracoSettings.previewSize}
            emptyTableTitle={"Ingen beskeder tilgængelige under denne kategori"}
            loading={loading}
            titleHTag={"h2"}
            onEmpty={umbracoSettings.showArchived ? OnEmpty.HideTableAndTitle : OnEmpty.ShowAll}
            title={umbracoSettings.title}
        />
        {loading ? null : newButton}
    </Stack>;
};
