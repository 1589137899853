import { AppBar, Box, Link, LinkProps, Menu, MenuItem, Paper, Stack, styled, Toolbar, Typography } from "@mui/material";
import { BreadCrumbElement, NavigationElement } from "../../models/umbracoElement";

import { DMTheme } from "../../helpers/DMTheme";
import React from "react";
import { CaretDownIcon, ColoredRightArrowIcon } from "../_GenericElements/Icons/Icons";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import { Instance } from "@popperjs/core";
import styles from "./MobileMenu.module.scss";

export interface MegaMenuProps {
    data: string;
}

export interface DropdownProps {
    element: BreadCrumbElement
    index: number
}

const RemoveLinkStyling = { color: DMTheme.palette.info.contrastText, textDecoration: "none", paddingLeft: "0.5rem", paddingRight: "0.5rem" };

export const UnstyledLink: React.FC<LinkProps> = (props) => {
    return <Link {...props} sx={RemoveLinkStyling}>
        {props.children}
    </Link>;
};


const textElement = (element: BreadCrumbElement, index: number) => {
    return <Box key={`textElement-${index}`}
        sx={{
            height: "100%",
            alignItems: "center",
            display: "flex",
            marginLeft: index == 0 ? "10%" :"1.25rem",
            borderBottom: element.IsCurrent ? "3px solid" : "",
            borderColor: DMTheme.palette.info.contrastText,
        }} ><Typography key={`menuItem-${index}`}
            variant="navigation"
            color={DMTheme.palette.info.contrastText}
            component="div">
            <UnstyledLink style={{ fontSize: "100%" }} href={element.Url}>{element.Title}</UnstyledLink>
        </Typography>
    </Box>;
};

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "none",
        background: "none",
    },
    [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
        margin: "24px 0 !important",
        padding: "4px 0px",
    },
});

const DropDownMenu = (props: DropdownProps) => {
    const areaRef = React.useRef<HTMLDivElement>(null);
    const popperRef = React.useRef<Instance>(null);
    const { element, index } = props;

    return <Box sx={{
        height: "100%",
        alignItems: "center",
        display: "flex",
        marginLeft: index == 0 ? "10%" : "1.25rem",
        borderBottom: element.IsCurrent ? "3px solid" : "",
        borderColor: DMTheme.palette.info.contrastText,
    }} >
        {<Typography
            variant="navigation"
            color={DMTheme.palette.info.contrastText}
            component="div">
            <NoMaxWidthTooltip
                PopperProps={{
                    popperRef,
                    anchorEl: {
                        getBoundingClientRect: () => {
                            return new DOMRect(
                                areaRef.current!.getBoundingClientRect().x,
                                areaRef.current!.getBoundingClientRect().bottom,
                                areaRef.current!.getBoundingClientRect().width,
                                0,
                            );
                        },

                    },
                }}
                placement={"bottom-start"}
                title={<Typography sx={{ backgroundColor: "#fff" }} variant="h6"
                    color={DMTheme.palette.info.contrastText}
                    component="div">
                    <table style={{
                        width: "100%",
                        backgroundColor: DMTheme.palette.secondary.main,
                        fontSize: "18px", lineHeight: "50px",
                    }}>

                        {props.element.Children.map((c, i) =>
                            <tr key={`dropdownMenuItem-${i}`}>
                                <Link sx={RemoveLinkStyling} href={c.Url}>
                                    <td><div className={styles.dropdownMenuItem} style={{ marginRight: "20px" }}>{c.Title}</div> </td>
                                </Link>
                            </tr>
                        )}
                    </table>

                </Typography>}
            >
                <Box ref={areaRef}>
                    <UnstyledLink href={element.Url}>{element.Title}</UnstyledLink>
                    <span>{CaretDownIcon}</span>
                </Box>
            </NoMaxWidthTooltip>
        </Typography>}
    </Box>;
};

export const MegaMenu = (props: MegaMenuProps) => {
    const menuItems: BreadCrumbElement[] = JSON.parse(props.data ?? "[]");
    return <AppBar position="static" sx={{ height: 90, position: "relative", backgroundColor: DMTheme.palette.secondary.main, marginTop: DMTheme.spacing(10), width: "100%" }}>
        <Toolbar variant="dense" sx={{ height: "100%", display: "flex" }}>
            {menuItems.map((m, i) => {
                return m.Children.length > 0 ?
                    <DropDownMenu key={`menuItem-${i}`} element={m} index={i}></DropDownMenu> :
                    textElement(m, i);
            })}

        </Toolbar>
    </AppBar>
    ;
};