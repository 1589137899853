/* eslint-disable camelcase */
import { createTheme } from "@mui/material/styles";
import { daDK } from "@mui/material/locale";
import { EventRegistrationBase } from "../api";

export const DMColors = {
    dm_pink: "#FFD7DC",
    dm_coral: "#FF785F",
    dm_red: "#FF4B4B",
    dm_middle_red: "#CD3246",
    dm_bordeaux: "#8C142D",
    dm_reddish_brown: "#5A0014",
    dm_beige: "#F5F0EB",
    dm_yellow: "#FFF064",
    dm_green: "#00D28C",
    dm_blue: "#2D7DFF",
    dm_dark_blue: "#141E4B",
    dm_sand: "#ebe6e1",
};

export let DMTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: DMColors.dm_middle_red,
            light: "#cd3246",
            dark: "#8c142d",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: DMColors.dm_sand,
        },
        error: {
            main: DMColors.dm_red,
        },
        warning: {
            main: DMColors.dm_yellow,
        },
        info: {
            // main: "rgba(226,226,226,10)",
            main: DMColors.dm_sand,
            contrastText: "#000000",
        },
        success: {
            main: DMColors.dm_green,
        },
    },
    shape: {
        borderRadius: 4,
    },
    spacing: 8,
    typography: {
        fontFamily: "DMBold",
        navigation: {
            fontFamily: "DMBold,serif",
            fontWeight: 700,
            fontStyle: "normal",
            fontSize: "23px",
        },
        h2: {
            fontFamily: "DMBold,serif",
            fontWeight: 700,
            fontStyle: "normal",
            fontSize: "26px",
        },
        body: {
            fontFamily: "AvenirNext",
            fontWeight: 400,
            fontStyle: "normal",
            fontSize: "17.6px",
        },
    },
    transitions: {
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            // most basic recommended timing
            standard: 300,
            // this is to be used in complex animations
            complex: 375,
            // recommended when something is entering screen
            enteringScreen: 225,
            // recommended when something is leaving screen
            leavingScreen: 195,
        },
    },
}, daDK);

// Add component theming - uses palette so in its own createTheme
DMTheme = createTheme(DMTheme, {
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: "AvenirNext",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontSize: "20px",      
                },
            },
        },
        MuiBreadcrumbs:{
            styleOverrides: {
                root: {  
                    fontFamily: "AvenirNext",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {  
                    fontFamily: "AvenirNext",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontSize: "20px",
                    backgroundColor:"#ffffff",
                }, 
             
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginTop: 5,
                    marginBottom: 5,
                    backgroundColor:"transperant",
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginTop: 5,
                    marginBottom: 5,                    
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    marginTop: 5,
                    marginBottom: 5,
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: DMTheme.palette.primary.contrastText,
                },
            },
        },

        MuiButton: {
            defaultProps: {
                variant: "contained",
                color: "primary",
            },
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    borderRadius: "0",
                    height: "50px", 
                    "@media only screen and (max-width: 760px)" : {
                        alignSelf: "center",
                        marginBottom: "2%",
                    },
                    
                    transition: DMTheme.transitions.create(["border-radius"],
                        {
                            duration: DMTheme.transitions.duration.shorter,
                            easing: DMTheme.transitions.easing.easeInOut,
                        }
                    ),
                    textTransform: "none",           
                    "&:hover": {
                        borderRadius: "50px",
                        backgroundColor: DMColors.dm_middle_red,
                        boxShadow: "none",
                    },
                },
            },
        },
    },
});



declare module "@mui/material/styles" {
    interface TypographyVariants {
        navigation: React.CSSProperties;
        body: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        navigation?: React.CSSProperties;
        body?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        navigation: true;
        body: true;
    }
}