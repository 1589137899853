import { ApiError, PayTransaction } from "../../../api";
import { ApiErrorBody } from "../../../models/apiError";

export const handleEventPaymentError = (err: ApiError) => {

    if (err.status === 307) {
        // console.log(`Response 307: Payment - Redirecting to ${err.body} ..`);
        location.href = err.body;
        return <span><a href={err.body} target="_blank" rel="noreferrer">Klik her for at åbne betalingsvinduet manuelt.</a></span>;
    } else if (err.status === 406) {
        let trans: PayTransaction = err.body as PayTransaction;
        let msg: string = trans.payErrorMessage ?? "Der er sket noget fejl ved betaling";

        alert(`Kunne ikke åbne betalingsvindue pga. en intern fejl: ${msg}`);
    } else {
        if (typeof (err.body) === "string") {
            let errorInfo: ApiErrorBody = JSON.parse(err.body);
            // this.setState({ error: errorInfo.title });
            alert(errorInfo.title);
        } else {
            // Uncaught error.
            alert("Der er sket en fejl ved tilmelding. Venligst kontakt DM.");

        }
    }
};

