/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/unbound-method */
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { RepresentativeService, RepresentativeSubmission, Representative } from "../../../api";
import DMTable, { DMTabelData, DMTableColumn, DMTableWrapper, OnEmpty } from "../../_GenericElements/DMTable/table";

import { errortext } from "../../../global.scss";
import Loader from "../../_GenericElements/Loader/loader";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
export enum RepresentativeListType {
    Submission = "submission",
    Termination = "termination",
    Representative = "representative"
}

type ListSetting = {
    title: string,
    columns: DMTableColumn[],
    detailPath: string,
    onEmpty: OnEmpty,
    emptyTableTitle?: string,
    createData: <T>(data: T[]) => DMTabelData[],
    getData: () => Promise<any>
}
type RepresentativeBaseListProps = {
    listtype: RepresentativeListType
}
const represenatativeColumn: DMTableColumn[] = [
    {
        id: "r_type",
        label:"Hverv",
        minWidth: 100,
        maxWidth: 100,
    }, {
        id: "r_startDate",
        label: "Start dato",
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "r_areas",
        label: "Anmeldelsesområde",
        minWidth: 100,
        maxWidth: 100,
    },
    {
        id: "r_coveragearea",
        label: "Dækningsområde",
        minWidth: 100,
        maxWidth: 100,
    },
];
const subimissionColumn: DMTableColumn[] = [
    {
        id: "s_type",
        label: "Hverv",
        minWidth: 100,
        maxWidth: 100,
    },
    {
        id: "s_areas",
        label: "Anmeldelsesområde",
        minWidth: 75,
        maxWidth: 75,
    },
    {
        id: "s_coveragearea",
        label: "Dækningsområde",
        minWidth: 100,
        maxWidth: 100,
    },
    {
        id: "s_previous",
        label: "Tidligere tillidsvalgte",
        minWidth: 100,
        maxWidth: 100,
    },
   
];
const terminatedColumns: DMTableColumn[] = [
    {
        id: "t_type",
        label: "Hverv",
        minWidth: 100,
        maxWidth: 100,
    }, {
        id: "t_endDate",
        label: "Slut dato",
        minWidth: 70,
        maxWidth: 70,
    },
    {
        id: "t_areas",
        label: "Anmeldelsesområde",
        minWidth: 100,
        maxWidth: 100,
    },
    {
        id: "t_coveragearea",
        label: "Dækningsområde",
        minWidth: 100,
        maxWidth: 100,
    }, 
];

const createSubmissionData = (data: RepresentativeSubmission[]): DMTabelData[] => {
    return data.map((x: RepresentativeSubmission) => {
        return {
            id: x.id,
            values: [
                { id: "s_type", value: x.type?.name },
                { id: "s_areas", value: x?.areas?.map(a => a.name).join(", ") },
                { id: "s_coveragearea", value: x.coverageArea },
                { id: "s_previous", value: x.previousRepresentative }, 
            ],
            isBold: false,
        } as DMTabelData;
    });
};
const createTerminaedData = (data: Representative[]): DMTabelData[] => {
    return data.map((x: Representative) => {
        return {
            id: x.id,
            values: [
                { id: "t_type", value: x.type },
                { id: "t_endDate", value: format(new Date(x.endDate!), "dd/MM/yyyy") },
                { id: "t_areas", value: x.areasText },
                { id: "t_coveragearea", value: x.coverageArea },
            ],
            isBold: false,
        } as DMTabelData;
    });
};
const createRepresentativeData = (data: Representative[]): DMTabelData[] => {
    return data.map((x: Representative) => {
        return {
            id: x.id,
            values: [
                { id: "r_type", value: x.type },
                { id: "r_startDate", value: format(new Date(x.startDate!), "dd/MM/yyyy") },
                { id: "r_areas", value: x.areasText },
                { id: "r_coveragearea", value: x.coverageArea },            
            ],
            isBold: false,
        } as DMTabelData;
    });
};

const RepresentativeListConfig: { [k: string]: ListSetting } = {
    [RepresentativeListType.Submission]: {
        title: "Aktive anmeldelser",        
        emptyTableTitle: "Ingen aktive anmeldelser at vise",
        detailPath: "anmeldelse",
        onEmpty: OnEmpty.HideTableAndTitle,
        columns: subimissionColumn,
        getData: RepresentativeService.getRepresentativeSubmissions,
        createData: createSubmissionData,
    },
    [RepresentativeListType.Termination]: {
        title: "Afsluttede tillidshverv",
        detailPath: "afsluttet",
        onEmpty: OnEmpty.HideTableAndTitle,
        columns: terminatedColumns,
        getData: RepresentativeService.getTerminatedRepresentatives,
        createData: createTerminaedData,
    },
    [RepresentativeListType.Representative]: {
        title: "Mine tillidshverv",
        emptyTableTitle: "Ingen aktive tillidshverv at vise",
        detailPath: "aktiv",
        onEmpty: OnEmpty.ShowAll,
        columns: represenatativeColumn,
        getData: RepresentativeService.getRepresentatives,
        createData: createRepresentativeData,
    },
};

export const RepresentativeBaseList = (props: RepresentativeBaseListProps) => {
    const [tableData, setTableData] = useState<DMTabelData[]>([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const type: RepresentativeListType = props.listtype;
    const config = RepresentativeListConfig[type];
    const goToDetail = (id: string) => {
        window.location.href = `${window.location.origin}/tillidshverv/${config.detailPath}/${id}`;

    };
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const res = await config.getData();
                setTableData(config.createData(res));
            } catch (err) {
                setErrorMessage("Der er sket en fejl. Data kunne ikke hentes.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return <>
        <DMTableWrapper
            columns={config.columns}
            data={tableData}
            onClick={goToDetail}
            initialRowsPrPage={10}
            loading={loading}
            titleHTag={"h1"}
            title={config?.title}
            onEmpty={config.onEmpty}
            emptyTableTitle={config.emptyTableTitle}
        />       
        <MessageDiv type={MessageType.error} msg={errorMessage} />  
    </>; 
};