/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AgreementGroup } from './AgreementGroup';

export type DMUser = {
    idNumber?: string | null;
    fullName?: string | null;
    email?: string | null;
    crmId?: string;
    subscriptionType?: DMUser.subscriptionType | null;
    agreementId?: string | null;
    readonly accessRoles?: Array<'None' | 'Ordinær_i_ansættelse_dont_use' | 'Ordinær_ikke_i_ansættelse_dont_use' | 'Ledig' | 'Studerende' | 'Pensionist' | 'Passiv' | 'Potentielt_medlem' | 'IsStaffRepresentative' | 'Ikke_TR' | 'DM' | 'JA' | 'DSL' | 'Ikke_politisk_aktiv'> | null;
    currentAgreementGroup?: AgreementGroup;
    currentMembershipStatus?: DMUser.currentMembershipStatus;
    staffRepresentativeStatus?: DMUser.staffRepresentativeStatus;
    currentTargetGroups?: Array<'Pensionist' | 'Dimitend' | 'Freelance_Selvstaendig' | 'Studerende' | 'Barsel' | 'Ledig' | 'Leder' | 'Kongresdeltagere'> | null;
    activeRepresentativeId?: string | null;
    staffRepresentativeAccount?: string | null;
    organisation?: DMUser.organisation | null;
    subOrganisation?: DMUser.subOrganisation | null;
    isNotPolitical?: boolean;
}

export namespace DMUser {

    export enum subscriptionType {
        UDMELDT = 'Udmeldt',
        BARSEL = 'Barsel',
        IARBEJDE = 'IArbejde',
        LEDIG = 'Ledig',
        STUDERENDE = 'Studerende',
        FREELANCEELLER_SELVST_NDIG = 'FreelanceellerSelvstændig',
        PENSIONISTELLER_EFTERL_N = 'PensionistellerEfterløn',
    }

    export enum currentMembershipStatus {
        POTENTIAL_MEMBER = 'PotentialMember',
        MEMBER = 'Member',
        NONE = 'None',
    }

    export enum staffRepresentativeStatus {
        TILLIDSREPR_SENTANT = 'Tillidsrepræsentant',
        SUPPLEANT = 'Suppleant',
        NONE = 'None',
    }

    export enum organisation {
        DM = 'DM',
        JA = 'JA',
        DSL = 'DSL',
    }

    export enum subOrganisation {
        DSL = 'DSL',
    }


}