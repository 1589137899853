/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect, useState } from "react";
import { CrmEntity, IncidentsService, IncidentCreate, Incident, IncidentCategory } from "../../api";
import { UmbracoIncidentForm } from "../../models/umbracoElement";
import { SantizedRichHtml } from "../_GenericElements/Richtext/richtext";
import { MessageForm } from "./message-form";

interface IncidentFormProps {
    data: string;
}

export const IncidentForm = (props: IncidentFormProps) => {
    let umbracoSettings: UmbracoIncidentForm = JSON.parse(props.data);
    const [categories, setCategories] = useState<IncidentCategory[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [errorMsg, setErrorMsg] = useState<string>();

    // For future use
    // const getSubjects = async () => {
    //     const subs = await IncidentsService.getSubjects(umbracoSettings.incidentType);
    //     setSubjects(subs);
    // };
    const getCategories = async () => {
        const cats = await IncidentsService.getCategories(umbracoSettings.incidentType);
        setCategories(cats);
    };
    useEffect(() => {
        void getCategories();
    }, []);

    const deleteFile = (fileName: string) => {
        const newFiles = files.filter(file => file.name !== fileName);
        setFiles(newFiles);
    };

    const clearFiles = () => {
        setFiles([]);
    };

    const addFiles = (newFiles: FileList) => {
        if (!newFiles) {
            return;
        }
        let newFilesArray = Array.from(newFiles);

        setFiles(files.concat(newFilesArray));

    };

    return (<>
        <h1>{umbracoSettings.title || "Send Besked"}</h1>
        {umbracoSettings.contentText &&
            SantizedRichHtml(umbracoSettings.contentText)
        }
        <MessageForm
            foldableMessageForm={false}
            incidentType={umbracoSettings.incidentType}
            containsIncidentFormField={true}
            // subjects={subjects}
            categories={categories}
            incidentTicketNumber={""}
            files={files}
            addFiles={addFiles}
            deleteFile={deleteFile}
            clearFiles={clearFiles}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            redirectLink={umbracoSettings.redirectLink}
            messages={[]}
        /></>
    );
};