/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EventRegistrationRead = {
    price?: number | null;
    eventId?: string | null;
    id?: string | null;
    status?: EventRegistrationRead.status | null;
    state?: EventRegistrationRead.state | null;
    hasPaied?: boolean | null;
    registrationId?: string;
    eventName?: string | null;
    eventNumber?: string | null;
    eventStartDate?: string | null;
    eventEndDate?: string | null;
    eventDeregistrationDate?: string | null;
    eventRegion?: string | null;
    registrationDate?: string | null;
    registrationStatus?: string | null;
}

export namespace EventRegistrationRead {

    export enum status {
        TILMELDING_GODKENDT = 'TilmeldingGodkendt',
        AFMELDT = 'Afmeldt',
        DEAKTIVERET = 'Deaktiveret',
        UDEBLEVET = 'Udeblevet',
        TILMELDING_REGISTRERET = 'TilmeldingRegistreret',
        AFMELDING_REGISTRERET = 'AfmeldingRegistreret',
        AFLYST = 'Aflyst',
        TILMELDING_OPRETTET_MIT_DM = 'TilmeldingOprettetMitDM',
    }

    export enum state {
        AKTIV = 'Aktiv',
        INAKTIV = 'Inaktiv',
    }


}