import { Button, ButtonProps, Stack } from "@mui/material";
import { boolean } from "yup";
import { isMobile } from "../../../helpers/MobileHelper";
import { RightArrowIcon } from "../Icons/Icons";
import Loader from "../Loader/loader";
import style from "./StandardButton.module.scss";

export interface StandardButtonProps extends ButtonProps {
    showArrow?: boolean;
    loading?: boolean;
}

export const StandardButton: React.FC<StandardButtonProps> = (props) => {
    const { showArrow, loading, ...rest } = props;
    return <Button className={`${style.standardbutton} ${showArrow!==false?style.witharrow:""}`} sx={{textAlign: isMobile ? "center" : "inherit"}} {...rest}>
        <Stack sx={{ width: "100%"}} direction="row" justifyContent="space-evenly"
            alignItems="center"
            spacing={2} > 
            {loading ? <Loader /> : props.children}{(showArrow ?? true) ? RightArrowIcon : null}
        </Stack>
    </Button>;
};