/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgreementGroup } from '../models/AgreementGroup';
import type { CrmEntity } from '../models/CrmEntity';
import type { EndReason } from '../models/EndReason';
import type { RepresentativeType } from '../models/RepresentativeType';
import { request as __request } from '../core/request';

export class MetadataService {

    /**
     * @param partialName 
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getFilteredWorkPlaces(
partialName: string,
): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/workplaces/${partialName}`,
            errors: {
                400: `Bad Request`,
            },
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getAllWorkPlaces(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/workplaces`,
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getAllWorkAreas(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/workareas`,
        });
        return result.body;
    }

    /**
     * @returns RepresentativeType Success
     * @throws ApiError
     */
    public static async getRepresentativeTypes(): Promise<Array<RepresentativeType>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/representativetypes`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getWorkplaceList(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/representativeworkplaces`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getAcOrganizationList(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/acorganizations`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getNetworks(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/networks`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getCoutries(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/countries`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getAllEmploymentTypes(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/employment_types`,
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getFreelanceIndependentEmploymentTypes(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/employment_types/freelanceindependent`,
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getAllJobPositions(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/job_position`,
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getAllAgreementGroups(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/agreement_groups`,
        });
        return result.body;
    }

    /**
     * @returns AgreementGroup Success
     * @throws ApiError
     */
    public static async getRetirementTypes(): Promise<Array<AgreementGroup>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/retirement_types`,
        });
        return result.body;
    }

    /**
     * @returns AgreementGroup Success
     * @throws ApiError
     */
    public static async getAvailableLeaveGroup(): Promise<Array<AgreementGroup>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/availableleave_types`,
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getAllPlacesOfStudy(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/places_of_study`,
        });
        return result.body;
    }

    /**
     * @param partialName 
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getFilteredPlacesOfStudy(
partialName: string,
): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/places_of_study/${partialName}`,
            errors: {
                400: `Bad Request`,
            },
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getAllTypesOfStudy(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/types_of_study`,
        });
        return result.body;
    }

    /**
     * @returns EndReason Success
     * @throws ApiError
     */
    public static async getAllTerminationReason(): Promise<Array<EndReason>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/terminationReason`,
        });
        return result.body;
    }

    /**
     * @param partialName 
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getFilteredTypesOfStudy(
partialName: string,
): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/types_of_study/${partialName}`,
            errors: {
                400: `Bad Request`,
            },
        });
        return result.body;
    }

    /**
     * @param educationLevel 
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getFilteredEducationFields(
educationLevel: string,
): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/education_fields/${educationLevel}`,
            errors: {
                400: `Bad Request`,
            },
        });
        return result.body;
    }

    /**
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getAllEducationCourses(): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/education_courses`,
        });
        return result.body;
    }

    /**
     * @param partialName 
     * @returns CrmEntity Success
     * @throws ApiError
     */
    public static async getFilterededucationCourses(
partialName: string,
): Promise<Array<CrmEntity>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/metadata/education_courses/${partialName}`,
            errors: {
                400: `Bad Request`,
            },
        });
        return result.body;
    }

}