import { CrmEntity } from "../../../api";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import { uuid } from "../../../constants/regex-expressions";

export const CRMEntitySchema : ObjectSchema<CrmEntity,any,any,"d"> = yup.object({
    id: yup.string()
        .defined()
        .matches(uuid, {excludeEmptyString: true}),
    primaryName: yup.string()
        .defined(),
}).default({ id: "", primaryName: "" });

export const CRMEntitySchemaWithMesssage=(message:string)=> yup.object({
    id: yup.string()
        .defined(message)
        .required(message)
        .matches(uuid, {excludeEmptyString: true}),
    primaryName: yup.string()
        .defined(message)
        .required(message),
}).default({ id: "", primaryName: "" });