/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/unbound-method */
import { useEffect, useState } from "react";
import { CrmEntity, MetadataService, RepresentativeService, RepresentativeSubmission } from "../../../../api";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { GenericFormElements, IdValue } from "../../../_GenericElements/_GenericFormElements/GenericFormElements";
import { RecordMultipleDropDown } from "../../../_GenericElements/_GenericFormElements/RecordMultipleDropdown";
import styles from "../representative-submission.module.scss";
import { StandardButton } from "../../../_GenericElements/StandardButton/StandardButton";
import { MessageDiv, MessageType } from "../../../_GenericElements/MessageDiv/MessageDiv";
import { representativePageLink } from "../../../../constants/common-constants";
import { ConfirmMessageBox } from "../../ConfirmMessageBox/ConfirmMessageBox";

export type OrganizationStepProps = {
    areas: CrmEntity[],
    submissionId: string,
    contactId: string,
    hideOrganization: boolean,
    reloadAfterSubmit: boolean,
    cancelBtn: JSX.Element | null,
    onPreviousClick: () => void
}

type RepresentativeOrgsSubmissionFormValues = NonNullable<Pick<RepresentativeSubmission, "coverageArea">>;

export const OrgsFormLabels: Record<keyof RepresentativeOrgsSubmissionFormValues, string> = {

    "coverageArea": "Dækningsområde",

};
export const OrganizationStep = (props: OrganizationStepProps) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [acOrganizations, setAcOrganizations] = useState<CrmEntity[]>([]);
    const [showConfirm, setShowConfirm] = useState(false);

    const submissionOrganisationSchema = yup.object({

        areas: yup.array().min(1, "Mangler ansættelsessted")
            .max(3, "Max 3 ansættelsessteder"),
        coverageArea:yup.string().required("Mangler dækningsområde. Her skal du specificere det konkrete område du dækker som tillidsvalgt. Eksempel \"Alle AC ansatte i Administrationen\" Vær så konkret som mulig!"),

    });


    const FormElements = new GenericFormElements<RepresentativeOrgsSubmissionFormValues>(OrgsFormLabels);
    const registerSubmissionOrganizations = async (submission: RepresentativeOrgsSubmissionFormValues) => {

        try {
            const newsubmission: RepresentativeSubmission = {
                ...submission,
                id: props.submissionId,
                contactId: props.contactId,
            };
            await RepresentativeService.registerRepresentativeOrganizations(newsubmission);

            if (props.reloadAfterSubmit) {
                window.location.reload();
            }

        } catch (err) {
            setErrorMessage("Der er sket fejl. Indtastede informatnioner i trin 2 er ikke gemt.");
            console.error(err);
        }
    };
    useEffect(() => {
        (async () => {
            const acOrganizations = await MetadataService.getAcOrganizationList();
            setAcOrganizations(acOrganizations);
        })();
    }, []);

    const onCloseConfirmMessage=()=>{
        setShowConfirm(false);
        window.location.href = representativePageLink ;
    };

    const form = <Formik
        initialValues={{
            areas: [],
            acOrganizations: [],
            coverageArea: "",
        }}
        validationSchema={submissionOrganisationSchema}
        onSubmit={async (values, { setSubmitting }) => {
            await registerSubmissionOrganizations(values);
            setShowConfirm(true);
            setSubmitting(false);
        }}
    >
        {({ values,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
        }) => (
            <Form onSubmit={handleSubmit} >
                {RecordMultipleDropDown("areas", "Ansættelsessteder", props.areas ?? [], setFieldValue, !props.areas)}
                {!props.hideOrganization && RecordMultipleDropDown("acOrganizations", "AC organisationer", acOrganizations ?? [], setFieldValue, !acOrganizations)}
                <FormElements.BasicTextInput formValueName="coverageArea" />
                {<div className={styles.btnsdiv} style={{width:"100%"} }>
                    {props.cancelBtn && <div className={styles.cancelbtndiv}>
                        {props.cancelBtn}</div>}
                  <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <StandardButton showArrow={false} onClick={props.onPreviousClick}>
                      Tilbage
                    </StandardButton>
                    <StandardButton showArrow={false} loading={isSubmitting} type="submit"
                        disabled={isSubmitting}>
                        Anmeld
                    </StandardButton>
                  </div>
                </div>}
                {showConfirm && <ConfirmMessageBox onClose={onCloseConfirmMessage} />}
                <MessageDiv type={MessageType.error} msg={errorMessage} />
            </Form>
        )
        }
    </Formik>;

    return <div>
        {form}
    </div>;
};


