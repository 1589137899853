import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { ContactUpdate } from "../../../api/models/ContactUpdate";
import { ContactsService, NewsSubcription } from "../../../api";
import * as api from "../../../api/services/ContactsService";
import Loader from "../../_GenericElements/Loader/loader";
import { PrintedMagazinesFormElement } from "../../../models/umbracoElement";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { NewsNMagazineDefaultValues, GetUpdatedStateWithContact } from "../NewsSubscriptions/news-subscriptions-form";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { printedMagazinesUpdateSchema } from "./printed-magazines-schema";
import { isMobile } from "../../../helpers/MobileHelper";
import { DescribedCheckbox } from "../DescribedCheckbox/described-checkbox";

export interface IPrintedMagazinesFormProps {
    data: string;
}

export const PrintedMagazinesForm = (props: IPrintedMagazinesFormProps) => {
    const [umbracoSettings] = useState<PrintedMagazinesFormElement>(props.data ? JSON.parse(props.data) : {});
    const [isLoading, setLoading] = useState(true);
    const [defaultValues, setDefaultValues] = useState<NewsSubcription>(NewsNMagazineDefaultValues);

    useEffect(() => {
        void getCurrentData();
    }, []);

    const getCurrentData = async () => {
        setLoading(true);
        const contact = await api.ContactsService.getNewsSubscriptions();

        setDefaultValues(GetUpdatedStateWithContact(contact));
        setLoading(false);
    };

    const updateNewsSubscriptions = async (formData: NewsSubcription) => {
        await ContactsService.updateNewsSubscriptions(formData);
    };

    const newsSubscriptionForm = (<>
        <h2>{umbracoSettings.title || ""}</h2>
        <Formik
            initialValues={defaultValues}
            onSubmit={async (values, {setSubmitting}) => {
                await updateNewsSubscriptions(values);
                setSubmitting(false);
            }}
            validationSchema={printedMagazinesUpdateSchema}
            enableReinitialize={true}
        >
            {({ errors, values, isSubmitting}) =>
                <Form style={{ textAlign: isMobile ? "center" : "inherit" }}>

                    {values.canReceiveResearchForum && <DescribedCheckbox<NewsSubcription>
                        name="receiveResearchForumDigitally"
                        label={umbracoSettings.magazineResearchForumLabel || "Forsker forum"}
                        description={umbracoSettings.magazineResearchForumDescription} />}

                    <DescribedCheckbox<NewsSubcription>
                        name="receiveDMAcademyMagazinePrinted"
                        label={umbracoSettings.magazineDMPrintedLabel || "DM Akademikerbladet på tryk"}
                        description={umbracoSettings.magazineDMPrintedDescription} />

                    <DescribedCheckbox<NewsSubcription>
                        name="receiveDMAcademyMagazinePrintedStud"
                        label={umbracoSettings.magazineDMStudPrintedLabel || "DM Akademikerbladet på tryk (Studerende)"}
                        description={umbracoSettings.magazineDMStudPrintedDescription} />

                    {(values.isBioPillar || values.receiveMomentumPrinted) && <DescribedCheckbox<NewsSubcription>
                        name="receiveMomentumPrinted"
                        label={umbracoSettings.momentumSubscriptionLabel || "Modtag fagbladet Momentum på tryk"}
                        description={umbracoSettings.momentumSubscriptionDescription} />}

                    {(values.canReceiveDMCommunicationMagazine) && <DescribedCheckbox<NewsSubcription>
                        name="receiveDMCommunicationMagazine"
                        label={umbracoSettings.magazineDMCommunicationLabel || "DM Kommunikation"}
                        description={umbracoSettings.magazineDMCommunicationDescription} />}

                    <div className="mag-research-digital-subscription">
                        <label><b>{umbracoSettings.magazineResearchForumDigitalSubscriptionLabel}</b></label><br />
                        {SantizedRichHtml(umbracoSettings.magazineResearchforumDigitalSubscriptionLink)}
                    </div>

                    <div className="mag-dm-digital-subscription">
                        <label><b>{umbracoSettings.magazineDMDigitalSubscriptionLabel}</b></label><br />
                        {SantizedRichHtml(umbracoSettings.magazineDMDigitalSubscriptionLink)}
                    </div>

                    <StandardButton type="submit" loading={isSubmitting} disabled={isSubmitting}
                        showArrow={false}>
                        {umbracoSettings.submitButtonLabel || "Opdatér"}
                    </StandardButton>
                </Form>
            }
        </Formik></>);

    return (
        isLoading ?
            <Loader></Loader> :
            <div>
                {newsSubscriptionForm}
            </div>
    );
};