import { AgreementGroup, Retired } from "../../../api";
import * as yup from "yup";
import { Schema } from "yup";

export type RetirementFormValues = Omit<Retired, "startDate" | "endDate" > & { startDate: Date, endDate?: Date, isPreviousKIMember: boolean }

export const RetirementFormSchema: Schema<RetirementFormValues> = yup.object({
    isPreviousKIMember: yup.bool().defined("isPreviousKIMember"),

    startDate: yup.date().when("isPreviousKIMember", ([isPreviousKIMember], schema) => {
        if (isPreviousKIMember) {
            return schema
                .required("Udfyld en start dato")
                .min(new Date(2024, 0, 1, 0, 0, 0), "Medlemskabsændringer før d. 1/1-2024, skal ske ved at kontakte Medlemsservice på tlf. 38156600 eller via mail service@dm.dk.");
        }

        return schema
            .required("Udfyld en start dato");
    }),

    endDate: yup.date(),

    pensionType: yup.object({
        priceGroup: yup.string()
            .defined("Mangler type")
            .required("Mangler type"),
        displayName: yup.string()
            .defined("Mangler type")
            .required("Mangler type"),
        organisation: yup.mixed<AgreementGroup.organisation>()
            .defined("Mangler type")
            .required("Mangler type")
            .oneOf(Object.values(AgreementGroup.organisation)),
        agreementGroupTypes: yup.array()
            .defined("Mangler type")
            .required("Mangler type"),
        availableForUpdate: yup.bool()
            .defined("Mangler type")
            .required("Mangler type"),
    }),
});