import React, { } from "react";
import { LoginFormElement } from "../../../models/umbracoElement";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import style from "./login.module.scss";


export interface CreateUserBoxProps {
    boxStyle : React.CSSProperties;
    boxProps : IndividualLoginBoxProps;
}
export interface IndividualLoginBoxProps {
    children: JSX.Element,
    title:string,
    body:string,
}

export const LoginBox = (props:CreateUserBoxProps) => {
    return <div className={style.loginBox} style={props.boxStyle}>
        <h2 style={{margin:"0"}}>{props.boxProps.title}</h2> 
        <div style={{flexGrow:1}}>{SantizedRichHtml(props.boxProps.body)}</div>
        <div className={style.linkdiv}>
            {props.boxProps.children}
        </div>
    </div>;
};