import { NetworkListElement } from "../../../models/umbracoElement";
import { ContactsService, ApiError, Network, CrmEntity } from "../../../api";
import React, { useEffect } from "react";
import Loader from "../../_GenericElements/Loader/loader";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { isMobile } from "../../../helpers/MobileHelper";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";

export type INetworkProps = {
    data: string, 
    
}

export const NetworkList = (props: INetworkProps) => {
    let umbracoSettings: NetworkListElement = JSON.parse(props.data);
    const [data, setdata] = React.useState<Array<Network>>();
    const [isMember, setisMember]= React.useState<boolean>(true);
    useEffect(() => {
        void (async () => {
            try {
                let Data: Array<Network> = await ContactsService.getNetworks();
                setdata(Data);
            } catch (e) {
                if (e instanceof ApiError) {
                    if (e.status == 500) {
                        console.log("Fandt ingen netværk");
                        setisMember(false);
                    }
                } 
            }

        })();

    }, []);

   
    return data ? <div>
        <h2>{umbracoSettings.netverkOverskrift}</h2>
        <p>{umbracoSettings.broedTekst && SantizedRichHtml(umbracoSettings.broedTekst)}</p>
       
        <div style={{ textAlign: isMobile ? "center" : "inherit", display:"flex", flexDirection:"column", marginBottom: "10px"}}>
            {data.map((network: Network, i) => 
                <FormControlLabel
                    key={i}
                    disableTypography={true}
                    control={<Checkbox  checked={true} disabled={true}  />}
                    label={network.name||""}
                />)}
                           
        </div>
        <div style={{ textAlign: isMobile ? "center" : "inherit" }}>
            
            <StandardButton href={umbracoSettings.dmLink || "https://dm.dk/din-karriere/netvaerk" } showArrow={true}>
                {umbracoSettings.gaTilNetverk}
            </StandardButton>
        </div>
    </div> : isMember ? <Loader /> : <div></div>;

};


