export const eventsignupModalOpenQuery = "?registering=true";
export const logoutText = "Log ud";
export const hideOrganizationType = "Arbejdsmiljørepræsentant";
export const consultingBooking = "Karrieresamtale";

export const SelfEmployed = "Selvstændig";
export const Freelance = "Freelance";

export const FullTime= "Arbejder over 18 timer om ugen";
export const PartTime= "Arbejder 18 timer eller derunder om ugen";

export const SizeMobile = 800;
export const isMobile = () => { return SizeMobile > window.innerWidth;};

export const missingFieldsReplaceToken="##{missingFields}##";
export const detailLinkReplaceToken="[kursusnummer]";

export const profilPageLink = window.origin + "/profil-og-medlemskab";
export const profilPageKontaktinformationLink = window.origin + "/profil-og-medlemskab/kontaktinformation";
export const eventPageLink = window.origin + "/arrangementer";
export const representativePageLink = window.origin + "/tillidshverv";
export const frontPage = window.origin + "/";