import * as yup from "yup";
import { Schema } from "yup";
import { NewsSubcription } from "../../../api";

// eslint-disable-next-line max-len
const uuidRegex = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/;

export const CRMEntitySchema = yup.object()
    .shape({
        id: yup.string().matches(uuidRegex),
        primaryName: yup.string(),
    })
    .defined();

export const newsSubscriptionsUpdateSchema: Schema<NewsSubcription> = yup.object({
    receiveDMAcademyMagazine: yup.bool(),
    eventNewsAtDM: yup.bool(),
    sectorNewsletter: yup.bool(),
    serviceMails: yup.bool(),
    bioNewsLetter: yup.bool(),
});