/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Dispatch, SetStateAction, useEffect, useState, useMemo } from "react";
import { Field, Form, Formik, useField } from "formik";
import { Checkbox, CheckboxWithLabel, TextField } from "formik-mui";
import InputLabel from "@mui/material/InputLabel";
import style from "./master-data.module.scss";
import { ContactUpdate } from "../../../api/models/ContactUpdate";
import { ContactsService } from "../../../api";
import { getMasterDataUpdateSchema } from "./MasterDataSchema";
import * as api from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";
import { FormControl, MenuItem, Switch } from "@mui/material";
import { Check, TouchAppRounded } from "@mui/icons-material";
import { MasterDataUpdateElement, NewsSubscriptionsFormElement } from "../../../models/umbracoElement";
import React from "react";
import globalStyle from "../../../global.scss";
import { CrmEntities, GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import { masterDataUpdateFormLabels } from "./master-data-labels";
import formStyle from "../../_GenericElements/_GenericFormElements/form.module.scss";
import { Http2ServerRequest } from "http2";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { MessageDiv, MessageType } from "../../_GenericElements/MessageDiv/MessageDiv";
import { UpdateConfirmMessageBox } from "../UpdateConfirmMessageBox/UpdateConfirmMessageBox";
// import { SwitchBaseProps } from 

export interface IMasterDataUpdateProps {
    data: string;
}

export const MasterDataUpdate = (props: IMasterDataUpdateProps) => {
    const [umbracoSettings] = useState<MasterDataUpdateElement>(props.data ? JSON.parse(props.data) : {});
    const [isSubmitting, setSubmitting] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [countries, setCountries] = useState<api.CrmEntity[]>([]);
    
    const [currentCountry, setCurrentCountry] = useState<api.CrmEntity>();
    const [formValues, setFormValues]:
        [ContactUpdate, Dispatch<SetStateAction<ContactUpdate>>] = useState({});
    const [validationSchema, setVaidationSchema] = useState(getMasterDataUpdateSchema(true));
    useEffect(() => {
        void getCurrentData();
    }, []);


    const getCurrentData = async () => {
        setLoading(true);
        const contact = await api.ContactsService.getContact();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const countrylist = await api.MetadataService.getCoutries();
        setCountries(countrylist);

        setFormValues({
            firstName: contact.firstName ?? "",
            middleName: contact.middleName ?? "",
            lastName: contact.lastName ?? "",
            email: contact.email ?? "",
            primaryPhone: contact.primaryPhone ?? "",
            mobilePhone: contact.mobilePhone ?? "",
            addressLine1: contact.addressLine1 ?? "",
            addressLine2: contact.addressLine2 ?? "",
            postalCode: contact.postalCode ?? "",
            city: contact.city ?? "",
            country: contact.country ?? countrylist?.[0],
            language: contact.language,
        });
        setLoading(false);
    };

    const updateContact = async (formData: ContactUpdate) => {
        try {
            let response = await ContactsService.updateContact(formData);
            setShowConfirm(true);
            return response;
        } catch (err) {
            if (err instanceof Error && err.message && (err.message === "Client Error")) {
                setErrorMsg("Ændringen kan desværre ikke gennemføres. Kontakt os venligst via telefon eller en besked her på Mit DM for at informere os om din ændring.");
            } else {
                setErrorMsg("Der skete en uventet fejl og ændringen er ikke gennemført. Prøv venligst igen senere og kontakt os ved gentagen fejl.");
            }
            Object.assign(formData, formValues);
            console.error(err);
        }
    };
    useEffect(() => {

        if (currentCountry) { 
            setVaidationSchema( getMasterDataUpdateSchema(currentCountry.primaryName == "Danmark"));         
        }

    }, [currentCountry]);

    const FormElements = new GenericFormElements<ContactUpdate>(masterDataUpdateFormLabels);

    const masterDataUpdateForm = useMemo(() => (
        <Formik
            initialValues={formValues}
            onSubmit={async (values) => {
                console.log(values);
                await updateContact(values);
                setSubmitting(false);
            }}
            validate={(x)=>setCurrentCountry(x.country)}
            validationSchema={validationSchema}
        >
            {({ errors, touched }) => {
                return <Form className={style.dmForm}>
                    <FormElements.BasicTextInput formValueName="firstName" />

                    <FormElements.BasicTextInput formValueName="middleName" />

                    <FormElements.BasicTextInput formValueName="lastName" />

                    <FormElements.BasicTextInput formValueName="addressLine1" />

                    <FormElements.BasicTextInput formValueName="addressLine2" />

                    <FormElements.BasicTextInput formValueName="postalCode" />

                    <FormElements.BasicTextInput formValueName="city" />

                    <FormElements.RecordAutoComplete formValueName="country" options={countries} loading={false} />

                    <FormElements.BasicTextInput formValueName="email" />

                    <FormElements.BasicTextInput formValueName="mobilePhone" />

                    <FormElements.BasicTextInput formValueName="primaryPhone" />

                    {FormElements.EnumDropDown("language", ContactUpdate.language)}


                    <FormElements.SubmitButton disabled={isSubmitting} loading={isSubmitting} type="submit">
                        {umbracoSettings.submitButtonLabel || ""}
                    </FormElements.SubmitButton>

             

                </Form>;
            }
            }
        </Formik>), [validationSchema,formValues]);

    return (
        isLoading ?
            <Loader></Loader> :
            <div>
                <h2>{umbracoSettings.text || "Mine Oplysninger"}</h2>
                {umbracoSettings.contentText && SantizedRichHtml(umbracoSettings.contentText)}
                {masterDataUpdateForm}
                {showConfirm && <UpdateConfirmMessageBox onClose={() => setShowConfirm(false)} title={`${umbracoSettings.text || "Mine Oplysninger"} ændring`} />}
                <MessageDiv type={MessageType.error} msg={errorMsg} />
            </div>
    );
};

export default MasterDataUpdate;