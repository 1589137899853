/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AgreementGroup = {
    displayName: string;
    priceGroup: string;
    organisation?: AgreementGroup.organisation;
    agreementGroupTypes: Array<'PartTimeWorkers' | 'Studying' | 'OnParentalLeave' | 'OnLeave' | 'Retired' | 'RetiredEarly' | 'Passive' | 'NonMembers' | 'Employed' | 'SelfEmployed' | 'Unemployed'>;
    availableForUpdate: boolean;
}

export namespace AgreementGroup {

    export enum organisation {
        DM = 'DM',
        JA = 'JA',
        DSL = 'DSL',
    }


}