import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import style from "./dm-dialog.module.scss";
import { StandardButton } from "../StandardButton/StandardButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        minWidth: "40vw",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export interface DMDialogProps {
    title?: string | JSX.Element;
    content?: string | JSX.Element;
    open?: boolean;
    onclose?: () => void;
  }
  
export const DMDialog = (props: DMDialogProps) => {
    const [open, setOpenState] = React.useState(props?.open ?? false);

    const setOpen = (open: boolean) => {
        setOpenState(open);
        if (!open) {
            if (props.onclose) {props.onclose();}
        }
    };

    return (
        <div>
            <BootstrapDialog
                onClose={() => setOpen(false)}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                    {props.title}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {props.content}
                </DialogContent>
                <DialogActions>
                    <StandardButton onClick={() => setOpen(false)} showArrow={false}
                        sx={{ m: "auto" }}>
                        Luk
                    </StandardButton>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};
