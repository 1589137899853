
import { Field } from "formik";
import { Checkbox } from "formik-mui";
import style from "./described-checkbox.module.scss";
import { NewsSubcription } from "../../../api";

export interface MagagineNewsChekboxProps<T> {
    name: keyof T;
    label: string;
    description: string;
    disabled?:boolean;
}

export const DescribedCheckbox = <T extends unknown>(props: MagagineNewsChekboxProps<T>) => {

    return <div className={style.checkboxdiv}>
        <Field
            component={Checkbox}
            type="checkbox"
            name={props.name}
            disabled={props.disabled}
        />
        <div>
            <b>{props.label}</b><br />
            {props.description}
        </div>
    </div>;

};