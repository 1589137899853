import { ContactUpdate, CrmEntity, MainEducationUpdate } from "../../../api";
import * as yup from "yup";
import { Schema } from "yup";

export type educationFormValues = NonNullable<Omit<
    MainEducationUpdate,
    "graduateDate" |
    "expectedGraduateDate"
>
    & {
        graduateDate: Date | undefined,
        expectedGraduateDate: Date | undefined,
        finishedEducation: boolean,
        isPreviousKIMember: boolean | undefined
    }>;

export const educationFormLabels : Record<string, string> = {
    "type":"Uddannelsens type",
    "course":"Kursus",
    "placeOfStudy":"Uddannelsessted",
    "graduateDate":"Dimmitenddato",
    "expectedGraduateDate":"Forventet Dimmitenddato",
    "finishedEducation":"Uddannelsen er afsluttet",
    "educationField": "Fagområde",
    "levelOfEducation": "Niveau",
    "furtherEducation": "Videreuddannelse",
};

// eslint-disable-next-line max-len
const uuidRegex = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/;

export const CRMEntitySchema = yup.object({
    id: yup.string()
        .defined()
        .matches(uuidRegex),
    primaryName: yup.string().defined(),
});

export const educationCreateSchema = yup.object({
    finishedEducation: yup.bool().defined("finishedEducation"),
    isPreviousKIMember: yup.bool().defined("isPreviousKIMember"),

    graduateDate: yup.date()
        .when(["finishedEducation", "isPreviousKIMember"], ([finishedEducation, isPreviousKIMember], schema) => {
            if (finishedEducation && isPreviousKIMember) {
                return schema
                    .required()
                    .max(Date(), "Datoen skal være i fortiden.")
                    .min(new Date(2024, 0, 1, 0, 0, 0, 0), "Medlemskabsændringer før d. 1/1-2024, skal ske ved at kontakte Medlemsservice på tlf. 38156600 eller via mail service@dm.dk.");
            } else if (finishedEducation) {
                return schema
                    .required()
                    .max(Date(), "Datoen skal være i fortiden.");
            }
            return schema
                .notRequired();
        }),

    expectedGraduateDate: yup.date().when("finishedEducation",
        {
            is: false,
            then: (schema) => schema
                .required("Mangler forventet dimmitenddato")
                .min(Date(), "Datoen skal være i fremtiden."),
            otherwise: (schema) => schema.notRequired(),
        }),

    placeOfStudy: CRMEntitySchema
        .required("Uddanelsesinstitution mangler"),
    educationField: CRMEntitySchema
        .required("Uddannelse mangler"),
});