import React from "react";

import { format } from "date-fns";
import Loader from "../../_GenericElements/Loader/loader";
import { DMTable, DMTableColumn, DMTabelData, DMTableWrapper, OnEmpty } from "../../_GenericElements/DMTable/table";
import * as api from "../../../api/services/EventService";
import { EventRegistrationRead } from "../../../api";

import { EventListElement } from "../../../models/umbracoElement";
import { DanishDateFns } from "../../../helpers/DanishDateFns";

export interface IEventListProps {
    data: string;
}

export interface IEventListState {
    show: boolean,
    events: EventRegistrationRead[]
    loading: boolean
    parsedData: EventListElement
}
const upcommingColumns: DMTableColumn[] = [
    {
        id: "titel",
        label: "Titel",
        minWidth: 400,
        maxWidth: 400,
        mobile: true,
    },
    {
        id: "dato",
        label: "Dato",
        minWidth: 100,
        mobile: true,
    },
    {
        id: "tidspunkt",
        label: "Tidspunkt",
        minWidth: 170,
    },
    {
        id: "tilmeldingstatus",
        label: "Tilmeldingstatus",
        minWidth: 50,
    },
];
const attendedColumns: DMTableColumn[] = [
    {
        id: "titel",
        label: "Titel",
        minWidth: 400,
        maxWidth: 400,
        mobile: true,
    },
    {
        id: "dato",
        label: "Dato",
        minWidth: 100,
        mobile: true,
    },
    {
        id: "tidspunkt",
        label: "Tidspunkt",
        minWidth: 170,
    },
    {
        id: "tilmeldingstatus",
        label: "Tilmeldingstatus",
        minWidth: 50,
    },
];

function createData(number: string, titel: string, dato: string, tidspunkt: string, tilmeldingstatus: string): DMTabelData {
    return {
        id: number,
        values: [
            { id: "titel", value: titel },
            { id: "dato", value: dato },
            { id: "tidspunkt", value: tidspunkt },
            { id: "tilmeldingstatus", value: tilmeldingstatus },
        ],
    };
}

export class EventList extends React.Component<IEventListProps, IEventListState> {

    constructor(props: IEventListProps) {
        super(props);
        const parsedData :EventListElement = JSON.parse(this.props.data);
        this.state = {
            show: false,
            events: [],
            loading: true,
            parsedData: parsedData,
        };
        void this.getData();
    }

    private getData = () => {
        console.log("Retrieving event data...");
        switch (this.state.parsedData.mode) {
            case 1:
                void this.getUpcommingEvents();
                break;
            case 2:
                void this.getAttendedEvents();
                break;
            default:
                throw new Error(`Unsupported mode ${this.state.parsedData.mode}`);

        }
    }

    private getUpcommingEvents = async () => {
        const _events = await api.EventService.getRegisteredEvents();
        this.setState({
            events: _events,
            loading: false,
        });
    }

    private getAttendedEvents = async () => {
        const _events = await api.EventService.getAttendedEvents();
        this.setState({
            events: _events,
            loading: false,
        });
    }


    public render(): JSX.Element {
        const list = this.state.events;
        const usingAttendedEvents: boolean = this.state.parsedData.mode == 2;
        const formatDate = (start: string | null | undefined, end: string | null | undefined) => {
            const dateStart: Date | null = start ? new Date(start) : null;
            const dateEnd: Date | null = end ? new Date(end) : null;

            return dateStart && dateEnd ?
                (dateStart.getDate() != dateEnd.getDate() ?
                    `${DanishDateFns.format(dateStart, "dd.MM")} - ${DanishDateFns.format(dateEnd, "dd.MM")} '${DanishDateFns.format(dateStart, "yy")}` :
                    DanishDateFns.format(dateStart, "dd.MM.yyyy")
                ) :    
                (dateStart ? DanishDateFns.format(dateStart, "dd.MM.yyyy") : "");
        };

        const formatTime = (start: string | null | undefined, end: string | null | undefined) => {
            return format(new Date(start!), "HH:mm");

        };
        const checkPayment = (event: EventRegistrationRead) => {
            if(this.state.parsedData.mode == 1 && event.status == "TilmeldingOprettetMitDM" ){
                return true;
            }
            else{
                return false;
            }
        }
        

        const getUrl = (id: string) => {

            return `https://${window.location.host}/arrangement/${id}`;

        };
        const rows = list.length == 0 ?
            [] :
            list.map((e: EventRegistrationRead) =>
                createData(e.eventNumber ?? "error", e.eventName ? e.eventName : "", formatDate(e.eventStartDate, e.eventEndDate), formatTime(e.eventStartDate, e.eventEndDate), checkPayment(e) ? "Betaling mangler" : (e.registrationStatus ?? "???"))
            );
        const columns = usingAttendedEvents ? 
            attendedColumns:
            upcommingColumns;

        return <DMTableWrapper
            loading={this.state.loading}
            title={this.state.parsedData.text}
            titleHTag={"h2"}
            columns={columns}
            data={rows}
            onClick={(id: string) => window.location.href = getUrl(id)}
            onEmpty={usingAttendedEvents ? OnEmpty.HideTableAndTitle : OnEmpty.ShowAll}
            emptyTableTitle={"Ingen arrangementer"}
        />;
    }
}