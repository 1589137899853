import * as yup from "yup";

export const messageWSubjectCreateSchema = yup.object({
    subject: yup.string().required("Emne skal udfyldes."),
    content: yup.string().required("Beskriv venligst en besked"),
    category: yup.object({
        id: yup.string()
            .min(0, "Kategori skal udfyldes.")
            .defined("Kategori skal udfyldes.")
            .required("Kategori skal udfyldes."),
    }).defined("Kategori skal udfyldes."),
});

export const messageCreateSchema = yup.object({
    content: yup.string().required("Beskriv venligst en besked"),  
});