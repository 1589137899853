/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as yup from "yup";

export const eventSignupSchema = yup.object({
    acceptedTerms: yup.bool()
        .when(["initialPrice"],
            {
                is: (initialPrice: number) => initialPrice > 0,
                then: (schema) => schema
                    .required()
                    .isTrue("Du skal acceptere handelsbetingelserne"),
                otherwise: (schema) => schema.nullable()
                    .notRequired(),
            }),
    dynamicFields: yup.array().of(
        yup.object().shape({
            id: yup.string(),
            question: yup.string(),
            value: yup.mixed()
                .when(["required"],
                    {
                        is: (required: boolean) => required,
                        then: (schema) => schema
                            .transform((value: string | never[] | null, orginalvalue) => {
                                console.log(value);
                                return value?.length == 0 ? null : value;
                            })
                            .required("Du skal tage stilling til dette felt")
                        ,
                        otherwise: (schema) => schema
                            .nullable()
                            .notRequired(),
                    }
                ),
            required: yup.bool(),
        })
            .test(
                "is-requered2", "Du skal tage stilling til dette felt2", field => !field.required || field.value != "" && field.value != [] && field.value != null
            )
    ),
    buyIns: yup.array().of(
        yup.object().shape({
            id: yup.string(),
            label: yup.string(),
            price: yup.number(),
            value: yup.mixed(),
        })
    ),
    guest: yup.object().shape({
        guestFirstName: yup.string()
            .when("hasGuest", {
                is: (hasGuest: boolean) => hasGuest,
                then: (schema) => schema
                    .transform((value: string | never[] | null) => {
                        console.log("then");
                        return value?.length == 0 ? null : value;
                    })
                    .required("Fornavn på gæst mangler"),
                otherwise: (schema) => {
                    console.log("not then"); return schema
                        .nullable()
                        .notRequired();
                },
            }),
        guestLastName: yup.string()
            .when("hasGuest", {
                is: (hasGuest: boolean) => hasGuest,
                then: (schema) => schema
                    .transform((value: string | never[] | null) => {
                        return value?.length == 0 ? null : value;
                    })
                    .required("Efternavn på gæst mangler"),
                otherwise: (schema) => schema.nullable()
                    .notRequired(),
            }),
        dynamicFields: yup.array()
            .when("hasGuest", {
                is: (hasGuest: boolean) => hasGuest,
                then: (schema) => schema.of(
                    yup.object().shape({
                        id: yup.string(),
                        question: yup.string(),
                        value: yup.mixed()
                            .when(["required"],
                                {
                                    is: (required: boolean) => required,
                                    then: (schema) => schema
                                        .transform((value: string | never[] | null, orginalvalue, test) => {
                                            console.log(value, schema, orginalvalue, test);
                                            return value?.length == 0 ? null : value;
                                        })
                                        .required("Du skal tage stilling til dette felt!!!")
                                    ,
                                    otherwise: (schema) => schema
                                        .nullable()
                                        .notRequired(),
                                }
                            ),
                        required: yup.bool(),
                    })
                        .transform((value: string | never[] | null) => {
                            return value?.length == 0 ? null : value;
                        })),
                otherwise: (schema) => schema.nullable()
                    .notRequired(),
            }),
        buyIns: yup.array().of(
            yup.object().shape({
                id: yup.string(),
                label: yup.string(),
                price: yup.number(),
                value: yup.mixed(),
            })
        ),
    }),
});