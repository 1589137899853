import { DMDialog } from "../../_GenericElements/DMDialog/dm-dialog";

type UpdateConfirmMessageBoxProps = {
  title: string,
  onClose?: () => void
}
export const UpdateConfirmMessageBox = (props: UpdateConfirmMessageBoxProps) => {

    return <DMDialog
        title={props.title || "Ændring modtaget"}
        content={"Tak for din ændring, som er blevet modtaget"}
        open={true}
        onclose={props.onClose}/>;
};