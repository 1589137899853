/* eslint-disable */
import {  PbsRegistrationElement } from "../../models/umbracoElement";

type PbsRegistrationProps = {
    data: string;
}

export const PbsRegistration = (props: PbsRegistrationProps) => {
    const umbracoSettings: PbsRegistrationElement = JSON.parse(props.data ?? "{}");

    return (
        <>
            <h2>{umbracoSettings.title||""}</h2>
            <div dangerouslySetInnerHTML={{ __html: umbracoSettings.contentText || "<div/>" }}></div>
            <div dangerouslySetInnerHTML={{ __html: umbracoSettings.code || "<div/>" }}></div>
        </>
    );

};