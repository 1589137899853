/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/unbound-method */
import { useEffect, useState } from "react";
import { CrmEntity, DMOrganization, RepresentativeService, RepresentativeSubmission, RepresentativeType, ContactsService, VIPorTAP } from "../../../../api";
import * as yup from "yup";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import { GenericFormElements, IdValue } from "../../../_GenericElements/_GenericFormElements/GenericFormElements";
import { RepresentativeContactStepData } from "../representative-submission-form";
import { hideOrganizationType } from "../../../../constants/common-constants";
import styles from "../representative-submission.module.scss";
import { StandardButton } from "../../../_GenericElements/StandardButton/StandardButton";
import { EventContactInformation } from "../../../Event/EventOverview/event-contact-information";
import { Typography } from "@mui/material";
import { RepresentativeSubmissionElement } from "../../../../models/umbracoElement";
import Richtext from "../../../_GenericElements/Richtext/richtext";
type ContactStepProps = {
    umbracosettings: RepresentativeSubmissionElement,
    data: RepresentativeContactStepData,
    contactid: string;
    setAllowedAreas: (submissionid: string, areas: CrmEntity[], hideOrganization: boolean) => void,
    setErrorMessage: (msg: string) => void
    cancelBtn: JSX.Element | null
    isviptap: boolean,
    disabled: boolean
}

type RepresentativeSubmissionFormValues = NonNullable<Pick<RepresentativeSubmission, "phone" | "mobile" | "email" | "emailForarea" | "type" | "consent" | "previousRepresentative" | "vipTap"> & { /* job?:CrmEntity,*/startDate: Date}>

export const ContactStepFormLabels: Record<keyof RepresentativeSubmissionFormValues, string> = {

    "phone": "Telefon, arbejdsplads",
    "mobile": "Mobilnummer",
    "emailForarea": "E-mail til HR eller leder",
    "email": "Arbejdsemail",
    "type": "Hverv",
    "consent": "Behandling af personfølsomme oplysninger",
    "previousRepresentative": "Tidligere tillidsvalgte",
    "startDate": "Start dato",
    "vipTap":"VIP/TAP",



};
export const ContactStep = (props: ContactStepProps) => {
    const { data, contactid, setAllowedAreas, setErrorMessage } = props;
    const submissionSchema = yup.object({
        startDate: yup.string().required("Mangler startdato"),
        email: yup.string().required("Mangler email"),
        emailForarea: yup.string().required("Mangler ansættelsessted email"),
        type: yup.object({
            id: yup.number()
                .min(0, "Mangler type")
                .defined("Mangler type")
                .required("Mangler type"),
        }),
    });
    const FormElements = new GenericFormElements<RepresentativeSubmissionFormValues>(ContactStepFormLabels);
    const delay = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };
    const startPullingAllowedAreas = async (id: string) => {

        let areas: DMOrganization[] = [];
        let count = 0;
        while (count < 5 && areas.length == 0) {
            console.log("start pulling", areas, new Date());
            areas = await RepresentativeService.getSubmissionAllowedOrganizations(id);
            console.log("endpoint called", areas, new Date());
            count++;
            if (areas.length == 0) {
                await delay(count * 1000);
            }
        }
        return areas;
    };
    const registerSubmissionContact = async (submission: RepresentativeSubmissionFormValues) => {
        try {
            const newsubmission: RepresentativeSubmission = {
                ...submission,
                startDate: format(submission.startDate, "yyyy-MM-dd"),
                contactId: contactid,
                type: { id: Number(submission.type?.id), name: submission.type?.name?.toString() },
                vipTap: submission.vipTap ? { id: Number(submission.vipTap?.id), name: submission.vipTap?.name?.toString() } : undefined,
            };
            const submissionid = await RepresentativeService.registerRepresentative(newsubmission);
            const allowedAreas = await startPullingAllowedAreas(submissionid);
            if (allowedAreas.length == 0) { throw new Error("No allowedAreas found"); }

            setAllowedAreas(submissionid, allowedAreas
                .filter(x => x.isWorkplace)
                .map(x => { return { id: x.id!, primaryName: x.name! } as CrmEntity; }), newsubmission.type?.name === hideOrganizationType);

        } catch (err) {
            setErrorMessage("Der er sket en fejl ved at anmelde hverv ");
            console.error(err);

        }
    };


    const consentMessage =
    <div>
        <h3>{props.umbracosettings.consentTitle || "Behandling af personfølsomme oplysninger"}</h3>
        {props.umbracosettings.offentligHvervTekst || "tekst om offentliggørelse hverv"}
        {props.umbracosettings.personvernTekst|| <p>Jeg giver tilladelse til, at DM gemmer og behandler mine data i overensstemmelse med <a style = {{color: "#FF4B4B"}} rel="noopener noreferrer" href="https://dm.dk/privatlivspolitik"
            target="_blank" title="DM's privatlivspolitik">DM's privatlivspolitik</a></p>}
    </div>;


    const form =
        <Formik
            initialValues={{
                startDate: new Date(),
                consent: true,
                email: data.contact.email,
                emailForarea: "",
                phone: data.contact.phone,
                mobile: data.contact.mobilePhone,
                previousRepresentative: undefined,
                type: { id: -1 },
                vipTap: undefined,
            }}
            validationSchema={submissionSchema}
            onSubmit={async (values, { setSubmitting }) => {
                await registerSubmissionContact(values);
                setSubmitting(false);
            }}
        >
            {({ values,
                handleSubmit,
                isSubmitting,
                isValid,
                initialValues,
            }) => (
                <Form onSubmit={handleSubmit} >
                    <FormElements.BasicDateInput disabled={props.disabled} formValueName="startDate" />
                    <FormElements.BasicTextInput disabled={props.disabled} formValueName="phone" />
                    <FormElements.BasicTextInput disabled={props.disabled} formValueName="emailForarea" />
                    {FormElements.IdNameDropdown("type", data.typeList, data.typeList.length === 0, props.disabled)}
                    {props.isviptap ? FormElements.IdNameDropdown("vipTap", data.viptapTypes, data.viptapTypes.length === 0, props.disabled):<></>}
                    <FormElements.BasicTextInput disabled={props.disabled} formValueName="previousRepresentative" />
                    {!props.disabled && <>
                        { consentMessage }
                        < div className={styles.btnsdiv}>
                            {props.cancelBtn && <div className={styles.cancelbtndiv}>
                                {props.cancelBtn}</div>}
                            <StandardButton showArrow={false} loading={isSubmitting} type="submit"
                                disabled={isSubmitting}>
                            Næste
                            </StandardButton>
                        </div>
                    </>
                    }
                </Form>
            )
            }
        </Formik>;
    return <div>
        {form}
    </div>;


};

