import { useEffect, useState } from "react";
import { ConsentForm } from "./consent-form";
import { ContactsService } from "../../../api/services/ContactsService";
import { Consent } from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";

export const ConsentList = () => {
    const [consents, setConsents] = useState<Consent[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const getConsents = async () => {
        setIsLoading(true);
        const consents = await ContactsService.getConsents();
        setConsents(consents);
        setIsLoading(false);
    };

    useEffect(() => {
        void getConsents();
    }, []);

    return <div >
        {isLoading && <Loader />}
        {consents.map((x, i) => {
            return (
                <ConsentForm key={i} consent={x}/>
            );
        })}
    </div>;
};