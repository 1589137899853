import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { ContactsService, PillarMembership, PillarTargetGroups } from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";
import { pillarMembershipsFormElement } from "../../../models/umbracoElement";
import React from "react";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { pillarMembershipsUpdateSchema } from "./pillar-memberships-schema";
import { isMobile } from "../../../helpers/MobileHelper";
import { DescribedCheckbox } from "../DescribedCheckbox/described-checkbox";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";

export interface IPillarMembershipFormProps {
    data: string;
}

const TargetGroupDefaultValues : PillarTargetGroups = {
    bioPillar: false,
    digiPillar: false,
};

export const PillarMembershipsForm = (props: IPillarMembershipFormProps) => {
    const [umbracoSettings] = useState<pillarMembershipsFormElement>(props.data ? JSON.parse(props.data) : {});
    const [isLoading, setLoading] = useState(true);
    const [defaultValues, setDefaultValues] = useState<PillarTargetGroups>(TargetGroupDefaultValues);

    useEffect(() => {
        void getCurrentData();
    }, []);

    const getCurrentData = async () => {
        setLoading(true);
        const targetGroups = await ContactsService.getTargetPillarGroups();
        setDefaultValues(targetGroups);
        setLoading(false);
    };

    const updatePillarMemberships = async (formData: PillarTargetGroups) => {

        let listOfPillarSubsInfo: PillarMembership[] = [];

        for (const [key, value] of Object.entries(formData)) {
            switch (key) {
                case "bioPillar":
                    listOfPillarSubsInfo.push({pillarIdCode: "000", subscribe: value, nameOfPillar: "Bio"});
                    break;
                case "digiPillar":
                    listOfPillarSubsInfo.push({pillarIdCode: "001", subscribe: value, nameOfPillar: "Digi"});
                    break;
            }
        }
        await ContactsService.updatePillarMemberships(listOfPillarSubsInfo);
    };


    const pillarMembershipForm = (<>
        <h2>{umbracoSettings.title || ""}</h2>
        <p>{umbracoSettings.freeText && SantizedRichHtml(umbracoSettings.freeText)}</p>

        <h4>{umbracoSettings.subtitle || ""}</h4>
        <Formik
            initialValues={defaultValues}
            onSubmit={async (values, {setSubmitting}) => {
                await updatePillarMemberships(values);
                setSubmitting(false);
                location.reload();
            }}
            validationSchema={pillarMembershipsUpdateSchema}
            enableReinitialize={true}
        >
            {({ isSubmitting}) =>
                <Form style={{ textAlign: isMobile ? "center" : "inherit" }}>
                    <DescribedCheckbox<PillarTargetGroups>
                        name="bioPillar"
                        label={umbracoSettings.bIOPillarLabel || "Meld dig ind i BIO"}
                        description={umbracoSettings.bIOPillarDescription} />

                    <DescribedCheckbox<PillarTargetGroups>
                        name="digiPillar"
                        label={umbracoSettings.dIGIPillarLabel || "Meld dig ind i DIGI"}
                        description={umbracoSettings.dIGIPillarDescription} />

                    <StandardButton type="submit" loading={isSubmitting} disabled={isSubmitting}
                        showArrow={false}>
                        {umbracoSettings.submitButtonLabel || "Opdatér"}
                    </StandardButton>
                </Form>
            }
        </Formik></>);

    return (
        isLoading ?
            <Loader></Loader> :
            <div>
                {pillarMembershipForm}
            </div>
    );
};