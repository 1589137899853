import React from "react";
import style from "./login.module.scss";
import { LoginFormElement } from "../../../models/umbracoElement";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { LoginSettings } from "./login-settings";
import { LoginBox } from "./login-box";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface ILoginProps {
    data: string;
    showemaillogin: boolean;
}

export const Login = (props: ILoginProps) => {
    const umbracoSettings: LoginFormElement = JSON.parse(props.data ?? "{}");
    
    /** Set goToForgotPassword to true if the user should skip login screen and go directly to forgot password flow. */
    const goToAzureB2C = (methodName: string, goToForgotPassword: boolean = false) => {
        let searchQuery = location.search ?? "";
        if (searchQuery) {
            searchQuery = searchQuery.replace("?", "&");
        }
        if (goToForgotPassword){
            searchQuery+="&newAccount=true";
        }
        location.assign(`/azureb2c?method=${methodName}${searchQuery}`);
    };
    const getCssProps = (umbracoSettings: LoginFormElement) => {
        const padding = umbracoSettings.boxPadding ? Number(umbracoSettings.boxPadding) : 10;
        const backgroundColor = umbracoSettings.boxColor || "transperant";
        return { padding: padding, backgroundColor: backgroundColor };

    };
    const [loginboxStyles] = React.useState<React.CSSProperties>(getCssProps(umbracoSettings));
    const [isMember, setIsMember] = React.useState<boolean>(true);

    return <div className={style.loginContainerWrapper}>
        <div className={style.loginContainer}>
            {/* {!showEmailLogin ? <> */}
            <h2> {umbracoSettings.text}</h2>
            <div className={style.accordionContainer}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header1">
                        <Typography className={style.accordionTitle}>Jeg er medlem</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <LoginBox boxStyle={loginboxStyles}
                            boxProps={LoginSettings({ umbracoSettings, method: "mitid-cpr", goToAzureB2C })} />
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className={style.accordionContainer}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header2">
                        <Typography className={style.accordionTitle}>Jeg er ikke medlem</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <LoginBox boxStyle={loginboxStyles}
                            boxProps={LoginSettings({ umbracoSettings, method: "nonmember-email", goToAzureB2C })} />
                    </AccordionDetails>
                </Accordion>
            </div>
            {/* <StandardButton showArrow={false} type="button"
            onClick={() => setIsMember(true)}>Jeg er medlem</StandardButton> <StandardButton showArrow={false} type="button"
            onClick={() => setIsMember(false)}>Jeg er ikke medlem</StandardButton></div> */}

            {/* <div className={style.loginBoxContainer} >
            {isMember && <LoginBox boxStyle={loginboxStyles}
                boxProps={LoginSettings({ umbracoSettings, method: "mitid-cpr", goToAzureB2C })} />
            }
            <LoginBox boxStyle={loginboxStyles}
                boxProps={LoginSettings({ umbracoSettings, method: (isMember ? "member-email" : "email"), goToAzureB2C })} />         */}
        </div>
    </div>;
};