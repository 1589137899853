/* eslint-disable camelcase */
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import { DMTheme } from "../../helpers/DMTheme";

export interface themeProviderProps {
    children: React.ReactNode
}

export const DMThemeProvider = (props: themeProviderProps) => {
    return <ThemeProvider theme={DMTheme}>{props.children}</ThemeProvider>;
};