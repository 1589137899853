import { AvailableForm } from "./AvailableForm/AvailableForm";
import { ChangeToParentalLeaveForm } from "./ParentalLeaveForm/ParentalLeaveForm";
import { RetirementForm } from "./RetirementForm/RetirementForm";
import { ChangeToWorkingForm } from "./WorkForm/WorkingForm";

export module PaymentGroupForms {
    AvailableForm;
    ChangeToParentalLeaveForm;
    RetirementForm;
    ChangeToWorkingForm;
}
export { AvailableForm, ChangeToParentalLeaveForm, RetirementForm, ChangeToWorkingForm };

export const CurrentSituation = (props: {}) => {

}; 