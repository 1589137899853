/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Form, Formik, FormikProps } from "formik";
import { ApiError, IncidentsService, NewFile, NewMessageCreate, Message, CrmEntity, IncidentCreate, IncidentCategory, Draft } from "../../api";
import { ApiErrorBody } from "../../models/apiError";
import { GenericFormElements } from "../_GenericElements/_GenericFormElements/GenericFormElements";
import { messageCreateSchema, messageWSubjectCreateSchema } from "./message-schema";
import React, { useEffect, useRef, useState } from "react";
import { FileListView } from "./file-list";
import { UmbracoIncidentsFilterType } from "../../models/umbracoElement";
import { UploadIcon } from "../_GenericElements/Icons/Icons";
import { StandardButton } from "../_GenericElements/StandardButton/StandardButton";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MessageDiv, MessageType } from "../_GenericElements/MessageDiv/MessageDiv";
import { isMobile } from "../../helpers/MobileHelper";
import { SantizedRichHtml } from "../_GenericElements/Richtext/richtext";
import style from "./incidents.module.scss";

export interface MessageFormProps {
  incidentType: UmbracoIncidentsFilterType,
  containsIncidentFormField: boolean,
  foldableMessageForm?: boolean,
  incidentTicketNumber: string,
  files: File[],
  // subjects: IncidentSubject[],
  categories: IncidentCategory[],
  subject?: string,
  errorMsg?: string,
  redirectLink?: string,
  incidentId?: string,
  setErrorMsg: (msg: string) => void,
  addMessage?: (message: Message) => void,
  addFiles: (files: FileList) => void,
  deleteFile: (fileName: string) => void,
  clearFiles: () => void,
  messages: Message[],
}
export const MessageForm = (props: MessageFormProps) => {
  const [draft, setDraft] = useState<Draft>();
  const [latestMessage, setLatestMessage] = useState<string>("");
  const pad = (num: number) => num.toString().padStart(2, '0');
  useEffect(() => {
    if (props.messages.length > 0) {
      const latestMessage = props.messages[0];
      const lastMessageContent = latestMessage.content;
      const fromName = latestMessage.lastSenderName;
      let dateStringFormat = "";

      if (latestMessage.sentOn) {
        const date = new Date(latestMessage.sentOn);
        dateStringFormat = `${date.getDate().toString()}/${date.getMonth() + 1}/${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getMinutes())}`;
      }

      const finalSignature = `<span><strong> Fra: </strong> ${fromName ?? ""} </span><br/><span><strong> Sendt: </strong>${dateStringFormat}</span><br/><span><strong> Emne: </strong> ${props.subject ?? ""} </span><br/><br/>`;
      setLatestMessage(finalSignature + lastMessageContent ?? "");
    }
  }, [props.messages, props.subject]);

  const messageDefaultFormValues: MessageAndIncidentCreate = {
    subject: "",
    content: "",
    category: { id: "", primaryName: "" },
  };

  const getDraftNotInIncident = async () => {
    let draft = await IncidentsService.getDraftMessageNotInIncident();

    if (draft) {
      setDraft(draft);
    }
  };

  const getDraftInIncident = async () => {
    if (props.incidentId) {
      let draft = await IncidentsService.getDraftMessageForIncident(props.incidentId);

      if (draft) {
        setDraft(draft);
      }
    }
  };

  const formikRef = useRef<FormikProps<MessageAndIncidentCreate>>(null);

  useEffect(() => {
    if (props.containsIncidentFormField) {
      void getDraftNotInIncident();
    } else {
      void getDraftInIncident();
    }
  }, []);

  useEffect(() => {
    if (formikRef.current && draft) {
      alert("Der blev fundet en kladde. Indholdet er blevet indsat i beskedfeltet.");

      draft.draftContent && formikRef.current.setFieldValue("content", draft.draftContent);
      draft.subject && formikRef.current.setFieldValue("subject", draft.subject);

      if (draft.category) {
        const category = props.categories.find((x) => draft.category !== undefined && x.entityReference.id === draft.category.id);
        category && formikRef.current.setFieldValue("category", { name: draft.category?.name, id: draft.category.id });
      }
    }
  }, [draft]);

  const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const createMessage = async (messageCreate: NewMessageCreate) => {
    try {
      // Add files to message
      const fileDtosPromise: NewFile[] = await Promise.all(props.files.map(async f => {
        const arrayBuffer = await f.arrayBuffer();
        const b64 = arrayBufferToBase64(arrayBuffer);
        const newFile: NewFile = {
          filename: f.name,
          data: b64,
        };
        return newFile;
      }));
      messageCreate.files = fileDtosPromise;

      // Send messageCreate
      const createdMessage = await IncidentsService.createMessage(props.incidentType, messageCreate);
      // Delete draft if one was set
      if (draft) {
        await deleteDraft(draft.id);
      }
      if (props.addMessage) {
        props.addMessage(createdMessage);
      }
      return true;
    } catch (e) {
      if (e instanceof ApiError) {
        props.setErrorMsg("Kunne ikke oprette beskeden.");
      }
      return false;
    }
  };

  // IncidentToken may not be known, if incident is yet to be created. Files are not managed by Formik
  interface MessageAndIncidentCreate extends Omit<NewMessageCreate, "incidentToken" | "files"> {
    category: CrmEntity | undefined,
  }

  const MessageFormLabels: Record<keyof MessageAndIncidentCreate, string> = {
    "subject": "Emne",
    "content": "Besked",
    "category": "Kategori",
    "latestMessage": "Sidste besked",
  };

  const createIncident = async (formData: IncidentCreate) => {
    try {
      const createdIncident = await IncidentsService.createIncident(props.incidentType, formData);
      return createdIncident.id;
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status == 400) {
          let errorInfo: ApiErrorBody = JSON.parse(e.body);
          props.setErrorMsg(errorInfo.title);
        }
      }
    }
  };

  const getIncidentUrl = (id: string) => {
    return `https://${window.location.host}/${props.redirectLink ?? "sag"}/${id}`;
  };

  const onFilesSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files;
    if (newFiles) {
      // eslint-disable-next-line
      for (var i = 0, file; file = newFiles[i]; i++) {
        let iFileSize = file.size;
        let msg = "";
        if (iFileSize > 5242880) {
          msg += "Filer kan ikke overskride 5 MB.\n\n";
          props.setErrorMsg(msg);
        } else {
          props.setErrorMsg("");
          props.addFiles(newFiles);
        }


      }
    }
  };

  //send draft function that takes the form values and makes api call to save the draft
  const sendDraft = async (values: MessageAndIncidentCreate) => {
    if (!values.content) {
      props.setErrorMsg("Beskedfeltet er tomt. Ingen kladde oprettet.");
      return;
    }

    try {
      let subject = values.subject ?? undefined;
      let incident = props.incidentId;
      let incidentCategory = values.category?.id;
      await IncidentsService.saveDraftInTable(values.content!, subject, incident, incidentCategory);
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status == 400) {
          let errorInfo: ApiErrorBody = JSON.parse(e.body);
          props.setErrorMsg(errorInfo.title);
        }
      }
    }
  };

  // delete draft function that takes draft id and makes api call to delete the draft
  const deleteDraft = async (draftId: string) => {
    try {
      await IncidentsService.deleteDraft(draftId);
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status == 400) {
          let errorInfo: ApiErrorBody = JSON.parse(e.body);
          props.setErrorMsg(errorInfo.title);
        }
      }
    }
  };

  // Function that starts a timer for 30 minutes when you load this page, and then saves the current draft
  useEffect(() => {
    const timer = setInterval(() => {
      sendDraft(formikRef.current!.values);
    }, 1000 * 60 * 30);
    return () => clearInterval(timer);
  }, [draft]);

  const form = <Formik
    innerRef={formikRef}
    enableReinitialize={true}
    initialValues={
      messageDefaultFormValues
    }
    validationSchema={props.containsIncidentFormField ? messageWSubjectCreateSchema : messageCreateSchema}
    onSubmit={async (values, { setSubmitting, resetForm }) => {

      let incidentTicketNumber: string | undefined = props.incidentTicketNumber;

      if (props.containsIncidentFormField) {
        // Create incident
        let incidentData: IncidentCreate = {
          category: { id: values.category!.id, primaryName: props.categories.find(x => x.entityReference.id === values.category?.id)?.primaryName || "" },
          subject: values.subject,
        };
        incidentTicketNumber = await createIncident(incidentData);
      }

      if (incidentTicketNumber) {
        // NOTE
        let subject = values.subject;

        if (values.subject === undefined || values.subject === "") {
          subject = props.subject;
        }

        // Create Message
        const messageCreate: NewMessageCreate = {
          incidentToken: incidentTicketNumber,
          content: values.content,
          subject: subject,
          latestMessage: latestMessage,
        };

        let success = await createMessage(messageCreate);

        if (success) {
          props.clearFiles();
          resetForm();
          setSubmitting(false);
        }


        if (props.containsIncidentFormField) {
          // If a new incident was created - go to it's address
          window.location.href = getIncidentUrl(incidentTicketNumber);
        }
      }
    }}
  >
    {({ handleSubmit,
      isSubmitting,
      values,
    }) => {
      const hiddenFileInput = React.useRef<HTMLInputElement>(null);
      const formElements = new GenericFormElements(MessageFormLabels);
      const subjectField = formElements.SubjectFieldInput("subject");
      const contentField = formElements.BigTextInput("content");
      const categoryDropdown = formElements.RecordDropDown("category", props.categories.map(x => x.entityReference), false, false, !props.categories || props.categories.length === 0 || isSubmitting);
      const helperText = <div className={style.helperdiv}>{SantizedRichHtml(props.categories.find(x => x.entityReference.id == values.category!.id)?.helperText!)}</div>;
      return (
        <Form onSubmit={handleSubmit} >
          {props.containsIncidentFormField && subjectField}
          {props.containsIncidentFormField && categoryDropdown}
          {props.containsIncidentFormField && values.category?.id && helperText}
          {contentField}

          <label>
            Vedhæft filer
          </label>

          <FileListView deleteFile={props.deleteFile} files={props.files} />

          <StandardButton disabled={props.files?.length >= 10} onClick={(e) => hiddenFileInput.current!.click()} showArrow={false}>
            <span>{UploadIcon} Upload</span>
            <input id="fileInput" type="file"
              ref={hiddenFileInput}
              onChange={e =>
                onFilesSelected(e)}
              style={{ display: "none" }} />
          </StandardButton>
          {props.files?.length >= 10 && <MessageDiv type={MessageType.error} msg="Du kan ikke uploade mere end 10 dokumenter" />}

          <StandardButton sx={{ float: isMobile ? "inherit" : "right" }} loading={isSubmitting} disabled={isSubmitting}
            type={"submit"}
            showArrow={false}>
            Send
          </StandardButton>
        </Form>
      );
    }
    }
  </Formik>;

  return props.foldableMessageForm && draft === null ? (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>Send besked</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {form}
      </AccordionDetails>
    </Accordion>) : (
    <div>
      {form}
    </div>);
};
