/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EventWaitlistBase = {
    price?: number | null;
    eventId?: string | null;
    status?: EventWaitlistBase.status | null;
    state?: EventWaitlistBase.state | null;
}

export namespace EventWaitlistBase {

    export enum status {
        AKTIV = 'Aktiv',
        INAKTIV = 'Inaktiv',
    }

    export enum state {
        AKTIV = 'Aktiv',
        INAKTIV = 'Inaktiv',
    }


}