import React, { useMemo, useState } from "react";
import { RichtextElement } from "../../../models/umbracoElement";
import DOMPurify from "dompurify";
import style, { richtext } from "./richtext.module.scss";
import globalStyle from "../../../global.scss";
import { StandardButton } from "../StandardButton/StandardButton";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { ContactsService } from "../../../api";

interface RichtextProps {
    data: string
}

function Richtext(props: RichtextProps) {
    const [elm, _] = useState<RichtextElement>(JSON.parse(props.data));

    // Can be deleted at 1-1/2024 and afterwards. Where this state is used should be replaced with elm.richText
    const [richText, setRichText] = React.useState<string | undefined>("");

    // Can be deleted at 1-1/2024 and afterwards.
    React.useEffect(() => {
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        let compareDate = new Date(2024, 0, 1, 0, 0, 0, 0);

        if (currentDate < compareDate && elm.title == "Ret mit kontingent") {
            void getIfPreviousKIMember();
        } else {
            setRichText(elm.richtext);
        }
    }, []);


    // Can be deleted at 1-1/2024 and afterwards.
    const getIfPreviousKIMember = async () => {
        let isPreviousKIMember: boolean = false;
        try {
            isPreviousKIMember = await ContactsService.getIsPreviousKiMember();
        } catch (err) {
            console.error(err);
        } finally {
            if (isPreviousKIMember) {
                setRichText("Du vil få adgang til kontingent rettelser d. 1/1-2024. Eventuelle rettelser før skal ske ved at kontakte Medlemsservice på tlf. 38156600 eller mail service@dm.dk.");
            } else {
                setRichText(elm.richtext);
            }
        }
    };


    const onButtonClick = () => {
        if (elm.linkUrl) { window.location.href = elm.linkUrl; }
    };

    const extraStyle = useMemo(() => ({ color: elm.richTextColor || "#000000" }), [elm]);
    return (
        <div style={extraStyle} className={style.richtextdiv}>
            {elm.title && <h2>{elm.title}</h2>}
            {SantizedRichHtml(richText)}
            {elm.buttonLabel && elm.linkUrl &&
                <StandardButton showArrow={true} onClick={onButtonClick}>{elm.buttonLabel}</StandardButton>
            }
        </div>
    );
}

export function SantizedRichHtml(html: string | undefined, extraStyle?: React.CSSProperties) {
    const TEMPORARY_ATTRIBUTE = "data-temp-href-target";

    DOMPurify.addHook("beforeSanitizeAttributes", function (node: Element) {
        if (node.tagName === "A") {
            if (!node.hasAttribute("target")) {
                node.setAttribute("target", "_self");
            }

            if (node.hasAttribute("target") && node.getAttribute("target")) {
                let target = node.getAttribute("target") ?? "_self";
                node.setAttribute(TEMPORARY_ATTRIBUTE, target);
            }
        }
    });

    DOMPurify.addHook("afterSanitizeAttributes", function (node: Element) {
        if (node.tagName === "A" && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
            let tempAttribute = node.getAttribute(TEMPORARY_ATTRIBUTE) ?? "_self";
            node.setAttribute("target", tempAttribute);
            node.removeAttribute(TEMPORARY_ATTRIBUTE);
            if (node.getAttribute("target") === "_blank") {
                node.setAttribute("rel", "noopener");
            }
        }
    });
    let sanitizedHtml = html ? DOMPurify.sanitize(html) : undefined;
    return (
        <div style={extraStyle} className={`${globalStyle.elementcontent} ${style.richtext}`}
            dangerouslySetInnerHTML={{ __html: sanitizedHtml || "<div></div>" }} />
    );
}

export default Richtext;