/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Link, LinkProps, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { ColoredRightArrowIcon } from "../Icons/Icons";
import styles from "./MessageDiv.module.scss";
type MessageProps = { msg?: string, type: MessageType }
export enum MessageType {
    success,
    error
}

export const MessageDiv: React.FC<MessageProps> = (props) => {
    const messageClassName=useMemo(()=>{
        switch (props.type){
            case MessageType.success :
                return styles.success;

            case MessageType.error :
                return styles.error;
            default :
                return styles.error;
        }

    },[props.type]);
    return ( <div>{props.msg &&<span className={messageClassName}>{props.msg}</span>}</div> );

};