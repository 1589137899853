/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AttachmentFileName } from "../../api";
import { FileIcon, defaultStyles } from "react-file-icon";
import tablestyles from "../_GenericElements/DMTable/table.module.scss";
import { AttachmentIcon, DeleteFileIcon } from "../_GenericElements/Icons/Icons";
import style from "./incidents.module.scss";
export interface FileListViewProps{
    files: File[],
    deleteFile: (fileName:string ) => void
}

export const clickableFileList = (files: AttachmentFileName[], onclick: (f: Required<AttachmentFileName>) => Promise<void>) => {
    return <>
        <div className={tablestyles.showing}>
            {files
                .filter((f): f is Required<AttachmentFileName> => f.id != undefined)
                .map(f => {
                    let names = f.fileName.split(".");
                    let ext: string =  names.pop() || "ukendt";
                    return (
                
                        <div key={f.fileName} title={f.fileName} className={tablestyles.fileitem}>
                            <a href="#"key={f.fileName} onClick={() => onclick(f)}><FileIcon extension={ext} {...defaultStyles} /><span>{names[0]}</span></a>
                        </div>
                    );
                })
            }
        </div>{files.length > 0 ? <div className={tablestyles.hiding}>
            {AttachmentIcon}
        </div> : null}
    </>;
};



export const FileListView = (props: FileListViewProps) => {
    return <div className={style.filesdiv}>
        {props.files.map(f => {
            let names = f.name.split(".");
            let ext: string =  names.pop() || "ukendt";
            return <div key = {f.name} title={f.name} className={tablestyles.fileitem}>
                <FileIcon extension={ext} {...defaultStyles} /><span>{names[0]}</span>
                <span className={style.deletefileicon} onClick={() => props.deleteFile(f.name)}>{DeleteFileIcon}</span>
            </div>;
        }
        )}
    </div>;
};
