import style from "./loader.module.scss";

interface LoaderProps{
}

function Loader(props:LoaderProps) {
    return (
        <div className={style.loader} ></div>
    );
}

export default Loader;