import { Field, Form, Formik } from "formik";
import { Checkbox } from "formik-mui";
import { useEffect, useRef, useState } from "react";
import style from "./consent-form.module.scss";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { SantizedRichHtml } from "../../_GenericElements/Richtext/richtext";
import { isMobile } from "../../../helpers/MobileHelper";
import { Consent, ContactsService, MemberConsent } from "../../../api";
import { consentFormSchema } from "./consent-form-schema";

export type ConsentFormProps = {
  consent: Consent;
}

export const ConsentForm = (props: ConsentFormProps) => {
    const { consent } = props;
    const [defaultValues, setDefaultValues] = useState<MemberConsent>
    ({
        consentId: consent.id,
        giveConsent: consent.hasGivenConsent,
        memberConsentId: consent.memberConsentId,
        consentText: consent.consentText,
        consentVersion: consent.consentVersion,
    });
    const [expanded, setExpanded] = useState<boolean>(false);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const descriptionRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (descriptionRef.current) {
                const { scrollHeight, clientHeight } = descriptionRef.current;
                setIsOverflowing(scrollHeight > clientHeight);
            }
        };

        checkOverflow();
        window.addEventListener("resize", checkOverflow);

        return () => {
            window.removeEventListener("resize", checkOverflow);
        };
    }, []);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const createConsent = async (formData: MemberConsent) => {
        let memberConsentId: string = await ContactsService.createMemberConsent(formData);

        return memberConsentId;
    };

    const withdrawConsent = async (formData: MemberConsent) => {
        await ContactsService.withdrawConsent(formData);
    };

    return <div className={style.consentform}>
        <Formik
            enableReinitialize={true}
            initialValues={defaultValues}
            validationSchema={consentFormSchema}
            onSubmit={async (values) => {

                if (values.giveConsent) {
                    let memberConsentId = await createConsent(values);
                    setDefaultValues({...defaultValues, giveConsent: values.giveConsent, memberConsentId: memberConsentId});

                } else {
                    await withdrawConsent(values);
                    setDefaultValues({...defaultValues, giveConsent: values.giveConsent, memberConsentId: values.memberConsentId});
                }

            }}>
            {({ isSubmitting, values }) =>
                <Form>
                    <h2 className={style.title}>{consent.name}</h2>
                    <div className={isMobile ? style.consentrowmobile : style.consentrow}>
                        <div className={style.consentcolumn}>
                            <div className={`${style.consentrowcheckbox} ${isMobile ? style.isMobile : ""} ${expanded ? style.expanded : ""}`}>
                                <div className={isMobile ? style.mobiledescription : style.description}>
                                    <span ref={descriptionRef}>{SantizedRichHtml(consent.consentText)}</span>
                                    {isOverflowing && <h4 onClick={() => toggleExpand()}>{expanded ? "- Læs mindre " : "+ Læs mere"}</h4>}
                                </div>
                                <div className={style.consentcheckbox}>
                                    <Field
                                        component={Checkbox}
                                        type="checkbox"
                                        name="giveConsent"
                                    />
                                    <p>{"Giv Samtykke"}</p>
                                </div>

                            </div>

                            <div className={`${isMobile ? style.mobilesubmit : style.submit}`}>
                                <StandardButton type="submit" loading={isSubmitting}
                                    disabled={values.giveConsent === defaultValues.giveConsent}
                                    showArrow={false}>
                                    {"Opdatér"}
                                </StandardButton>

                            </div>


                        </div>
                    </div>
                </Form>
            }
        </Formik>
    </div>;
};