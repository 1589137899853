import { DanishDateFns } from "../../../helpers/DanishDateFns";
import { DMDialog } from "../../_GenericElements/DMDialog/dm-dialog";

type ConfirmMessageBoxProps = {
  fromDate: Date, onClose?: () => void
}
export const ConfirmMessageBox = (props: ConfirmMessageBoxProps) => {

    return <DMDialog
        title={"Kontingentstatus ændring"}
        content={"Din ændring træder i kraft " + DanishDateFns.format(props.fromDate, "dd.MM.yyyy") +
      " Sker der ændring i dit opkrævede kontingent, sker det for indeværende og foregående kvartal."} open={true}
        onclose={props.onClose}></DMDialog>;
};