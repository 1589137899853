/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PayTransaction = {
    id?: string;
    created?: string;
    modified?: string | null;
    memberId: string;
    purpose: PayTransaction.purpose;
    foreignKey: string;
    paymentRefGuid?: string | null;
    payReference?: string | null;
    orderReference?: string | null;
    landingPage?: string | null;
    state: PayTransaction.state;
    payTransactionId?: string | null;
    payAmount: number;
    payErrorMessage?: string | null;
}

export namespace PayTransaction {

    export enum purpose {
        NONE = 'None',
        EVENT = 'Event',
    }

    export enum state {
        NONE = 'None',
        PAYMENT_INITIATED = 'PaymentInitiated',
        PAYMENT_AWAITING_RESPONSE = 'PaymentAwaitingResponse',
        PAYMENT_SUCCESSFUL = 'PaymentSuccessful',
        PAYMENT_FAILED = 'PaymentFailed',
        POST_PROCESSED = 'PostProcessed',
        REFUNDED = 'Refunded',
    }


}